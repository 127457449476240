import {useCallback} from 'react'
import {useAppDispatch} from 'store'
import Map from "modules/Map";
import {useSelector} from "react-redux";



const useCity = () => {
    const cities = useSelector((state) => state.cities.data);
    const citySelected = useSelector((state) => {
        return state.cities?.data?.find(item => item.id === state.map.data.addressObj.cityId)
    });

    const dispatch = useAppDispatch();

    const onFetchCities = useCallback(() => {
        dispatch(Map.store.cities.actions.fetchCities({}))
    }, [])

    const onGetCityById = useCallback((id) => {
        const city = cities?.find(item => item.id === id)
        return city || {}
    }, [])


    return {
        onFetchCities,
        cities,
        onGetCityById,
        citySelected
    }
}

export default useCity