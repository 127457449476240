import * as t from './types';
import { Dispatch } from 'redux';
import {bannerService} from "modules/Promotion/services";

export const bannersSync = (config) => async (dispatch: Dispatch) => {

    dispatch({ type: t.FETCH_BANNERS_START });

    try {
        const data  = await bannerService.fetchBanners(config);
        let payload = data;
        return dispatch({ type: t.FETCH_BANNERS_SUCCESS, payload });
    } catch (error) {
        dispatch({ type: t.FETCH_BANNERS_FAILURE, payload: error?.response });
        return  error?.response
    }
};



