import React, {useRef} from 'react';
import {WrapperStyle} from "./styles";
import ContentMediaView from "shared/ContentMediaView";
import Link from "shared/Link";
import PromotionItem from "shared/Promotion/components/PromotionItem";
import combineLink from "helper/combineLink";
import links from "helper/links";
import CaruselWrap from "shared/Carusel/components/CaruselWrap";

const PromotionList = ({ children, data, variant, mb, ...rest }) => {
    const scrollContainer = useRef()

    const getHref = (item) => {
        if (item.container) {
            return combineLink(links.abs.banners, "[bannerId]");
        }

        if (item.partner) {
            return combineLink(links.abs.partners, "[partnerId]");
        }

        if (item.link) {
            return item.link;
        }
    };

    const getLinkAs = (item) => {
        if (item.container) {
            return combineLink(
                links.abs.banners,
                item.link || item.container.id,
            );
        }

        if (item.partner) {
            return combineLink(links.abs.partners, item.partner.alias || item.partner.id);
        }

        if (item.link) {
            return item.link;
        }
    };

    return (
        <ContentMediaView data-cy={'promotion-list'}>
            <CaruselWrap wrapRef={scrollContainer}>
                <WrapperStyle
                    ref={scrollContainer}
                    mb={mb}
                    variant={variant}
                    data={data}
                    {...rest}
                >
                    {data?.map((item) => (
                        <Link
                            passHref
                            key={item.id}
                            href={getHref(item)}
                            as={getLinkAs(item)}
                        >
                            <PromotionItem
                                data-cy={'promotion-item'}
                                as={"a"}
                                variant={variant}
                                data={{
                                    img: item.src ? item.src : item.image,
                                    /*head: 'Кофе+пончик за 170руб.',
                                    desc: 'Капучино или чай + любой пончик на ' +
                                    'выбор кроме Шелла за 170 руб до 12.00 для, с бонусной картой'*/
                                }}
                            ></PromotionItem>
                        </Link>

                    ))
                    }

                    {children && children}

                </WrapperStyle>
            </CaruselWrap>
        </ContentMediaView>
    );
};

export default PromotionList;
