import React, {lazy, Suspense, useCallback, useEffect, useState} from 'react';
import  useMenu from '../../hooks/useMenu';
import  usePartnerOpen from '../../hooks/usePartnerOpen';
import  ShopCategoriesList from 'modules/Partner/components/ShopCategoriesList';
import  MenuList from 'modules/Partner/components/MenuList';
import  DirectionsList from 'modules/Partner/components/DirectionsList';
import CartSlide from 'modules/Cart/components/CartSlide';
import  { useSlidePanel } from 'shared/SlidePanel';
import  Card from 'shared/Card';
import {useRouter} from "next/router";
import { useMobileContextDetect} from "shared/DeviceDetect";



const Menu = ({onOpenBonusSlide, variant}) => {
    const {isShopPartner, menuSync, menuReset, productSync, productReset, onMenuShopSync, menu} = useMenu()
    const {partner} = usePartnerOpen()
    const [actions, setActions] = useSlidePanel()
    const router = useRouter()
    const isMobile = useMobileContextDetect();

    useEffect(() => {
        const partnerId = router.query.partnerId;
        if(partnerId && !menu.data && !partner.isLoading){
            if(isShopPartner){
                //onMenuShopSync(partnerId)
            }else{
                //menuSync(partnerId)
            }
        }

        return () => {
            //menuReset()
        }

    }, [router.query.partnerId, partner.isLoading])

    const handleOpenProduct = (isProductForPoint, product) => {
        //productSync(product, isProductForPoint)

        if(isMobile){
            actions.onOpen(
                () => setTimeout(() => productSync(product, isProductForPoint), 500),
            )
        } else {
            productSync(product, isProductForPoint)
            actions.onOpen()
        }

    }

    const handleCloseProduct = () => {
        actions.onClose(
            () => setTimeout(() => productReset(), 600),
        )
    }

    /*const MenuList = React.memo(function MenuList(props) {
        /!* повторный рендер пройдёт только при изменении пропсов *!/
    });*/






    return (
        <>
            {
                (isShopPartner ?
                        <ShopCategoriesList
                            partner={partner}
                        /> : (
                            <>

                                <DirectionsList />

                                <Card>
                                    <MenuList
                                        variant={variant}
                                        onOpenBonusSlide={onOpenBonusSlide}
                                        partner={partner}
                                        openSlide={actions.onOpen}
                                        onOpenProduct={handleOpenProduct}
                                    />
                                </Card>
                                <CartSlide
                                    form={'cart'}
                                    setActions={setActions}
                                    onDismiss={handleCloseProduct}
                                    slideClose={handleCloseProduct}
                                />

                                {/*<SlidePanel onDismiss={onClose} open={isOpen} >
                                    <ProductFullContent slideClose={onClose}/>
                                </SlidePanel>*/}
                            </>
                        )
                )
            }
        </>

    )

}


export default React.memo(Menu)
