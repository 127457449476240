import  React, {useEffect} from 'react';
import  useMenu from '../../hooks/useMenu';
import  MenuGroup from 'modules/Partner/components/MenuGroup';
import { Element } from 'react-scroll'
import navRoute from "helper/navRoute";
import ProductPrimarySkeletonLoader from "shared/skeleton/ProductPrimarySkeletonLoader";


const MenuList = ({onOpenProduct, openSlide, partner, onOpenBonusSlide, variant}) => {
    const navigator = navRoute()
    const { menuItems, menu } = useMenu()

    useEffect(() => {
        const id = `item${navigator.query.item}`
        //const id = `item275448`

        const el = document.getElementById(id)

        if(el){
            setTimeout(() => {
                el.scrollIntoView({block: "center"});
            }, 500)
        }
    }, [menu.data])


    if(menu.isLoading || partner.isLoading) {
        return <ProductPrimarySkeletonLoader/>
    }


    return (
        <div data-cy={'menu-list'}>
            {
                menuItems?.map((item) => (
                    <Element key={item.id} name={`block${item.id}`}>
                        <MenuGroup
                            elementFoundId={parseInt(navigator.query.item)}
                            openSlide={openSlide}
                            onOpenProduct={onOpenProduct}
                            key={item.id}
                            menu={item}
                            partner={partner?.data}
                            zoneId={partner?.data.zoneId}
                            isProductForPoint={item.id === -1}
                            onProductsForPointsInfoPress={onOpenBonusSlide}
                        />
                    </Element>
                ))
            }
        </div>

    )

}


export default React.memo(MenuList)
