import styled, {css} from 'styled-components';
import { Fonts, Colors } from 'common/Theme';
import Helpers from 'common/Theme/Helpers';


export const ImageWrapStyle = styled.div`
  width: 100%;
  height: 177px;
  position: relative;
  margin-bottom: 10px;

  @media (min-width: 768px) {
    height: 725px;
  }
`;

export const ContentStyle = styled.div`
  @media (min-width: 768px) {
    max-width: 600px;
    margin-bottom: 30px;
    margin-top: 30px;
  }
`;
