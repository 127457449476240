import React from 'react';
import {WrapperStyle, Row, AlignCenter, SpaceBetween} from "./styles";

const Card = ({
    children,
    isBorderRadius,
    isBorder,
    isPadding = true,
    mb,
    variant,
    fullWidth,
    isBigBorder,
    layout,
    ...rest
}) => {
    return (
        <>
            <WrapperStyle
                data-cy={'card'}
                isBorder={isBorder}
                layout={layout}
                isPadding={isPadding}
                isBorderRadius={isBorderRadius}
                mb={mb}
                variant={variant}
                fullWidth={fullWidth}
                isBigBorder={isBigBorder}
                {...rest}
            >
                {children}
            </WrapperStyle>
        </>
    );
};

Card.Row = ({children}) => {

    return <Row>{children}</Row>
}

Card.AlignCenter = ({children}) => {

    return <AlignCenter>{children}</AlignCenter>
}

Card.SpaceBetween = ({children}) => {

    return <SpaceBetween>{children}</SpaceBetween>
}

export default Card;
