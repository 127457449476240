import styled, {css} from 'styled-components';
import { Fonts, Colors } from 'common/Theme';
import Helpers from 'common/Theme/Helpers';

export const WrapperStyle = styled.div`
  ${props => props.isPadding && css`
    padding: 16px;
  `}

  ${props => props.layout === 'row' && css`
    ${Helpers.css.flex}
    ${Helpers.css.flex_ac}
  `}

  ${props => props.layoutFlex && css`
    ${props.layoutFlex.map(item => Helpers.css[item])}
  `}
   
  
  background: ${Colors.white};

  ${props => props.isBorder && css`
    border-bottom: 1px solid rgba(0, 0, 0, 0.04);
    `}
  ${props => props.isBigBorder && css`
    border-top: 5px solid ${Colors.bg};
    `}
  //BorderRadius
  ${props => props.isBorderRadius && css`
    border-radius: 16px;
  `}
  //end BorderRadius

  //margin-bottom
  ${props => Boolean(props.mb) && css`
    margin-bottom: ${Helpers.css.mb[props.mb]};
  `}
  //end margin-bottom
  
  ${props => props.variant === 'primary' && css`
    background: ${Colors.white};
  `}

  ${props => props.variant === 'secondary' && css`
    background: ${Colors.bg};
  `}

  ${props => props.variant === 'transparent' && css`
    background: ${Colors.transparent};
  `}
  
  ${props => props.fullWidth && css`
    padding: 0px;
    margin: 0 -16px;
  `}
`;

export const Row = styled.div`
  display: flex
`;

export const AlignCenter = styled.div`
  ${Helpers.css.flex}
  ${Helpers.css.flex_ac}
`;

export const SpaceBetween = styled.div`
  ${Helpers.css.flex}
  ${Helpers.css.flex_ac}
  ${Helpers.css.flex_sb}
`;
