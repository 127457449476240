import React from 'react';
import { Discount } from '../../interfaces/Discount';
import * as S from './styles';
import Icon from 'shared/Icon';
import Typography from 'shared/Typography';

type Props = {
  discount: Discount[],
};


const DiscountPartnerShort = ({ discount }: Props) => {
  return (
    <S.Container>
      <S.ScrollWrap>
          {
              discount?.map(item => (
                  <S.DiscountElement key={item.id} >
                      <S.ImageView>
                        <Icon fontSize={'20'} name={'GiftIcon'}/>
                      </S.ImageView>
                      <S.DescriptionView>
                          <Typography variant={'h5'} mb={0.5}>{item.name}</Typography>
                          <Typography color={'gray'}  variant={'textSmall'} >{item.description}</Typography>
                      </S.DescriptionView>
                  </S.DiscountElement>
              ))
          }
    </S.ScrollWrap>
    </S.Container>
  );
};

export default DiscountPartnerShort;
