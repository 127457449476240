import { findСity } from './findСity';
import { getGeoObjectData } from './getGeoObjectData';

export const getMapData = (GeoObject, address, cities) => {
  const { lon, lat, title, subtitle } = getGeoObjectData(GeoObject);
  const { city, house, street } = address;
  const foundСity = findСity(cities, city);

  return {
    latitude: lat,
    longitude: lon,
    address: title + ' ' + subtitle,
    addressObj: {
      city,
      street,
      house,
      cityId: foundСity?.id || null,
    },
  };
};
