import styled, {css} from 'styled-components';
import { Fonts, Colors } from 'common/Theme';
import Helpers from 'common/Theme/Helpers';

export const WrapperStyle = styled.div`
  svg {
  display: block
  }
  
  ${props => props.color && css`
    path{
      stroke: ${Colors[props.color]};
      fill: ${Colors[props.color]};
    }
  `}
  
  ${props => props.rotate && css`
    transform: rotate(${props.rotate}deg)
  `}
`;

