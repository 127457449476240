import React, {useEffect} from 'react';
import {AddCartForm} from "modules/Cart/components";
import { destroy } from 'redux-form';
import {useAppDispatch} from "store";



const CartSlide = ({page,setActions, slideClose, form = 'cart'}) => {
    const dispatch = useAppDispatch()
    useEffect(() => {
        return () => {
            dispatch(destroy('cart'))
        };
    }, [])

    return (
        <AddCartForm form={form} setActions={setActions}  page={page} slideClose={slideClose} data-cy={'cart-slide'} />
    );
}




export default CartSlide
