export enum ReviewProperties {
    ratingProduct = 'Скорость доставки',
    ratingDelivery = 'Еда',
    ratingPricePerQuality = 'Цена / Качество',
    ratingService = 'Обслуживание',
}

export enum ReviewStatus {
    '0' = {label: 'Ожидает модерации', color: 'black'},
    '1' = {label: 'Опубликовано', color: 'green'},
}