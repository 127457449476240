import React from 'react';
import {ImageWrapStyle, ContentStyle} from "./styles";
import Typography from "shared/Typography";
import Card from "shared/Card";
import ImageUrl from "shared/ImageUrl";
import {useBanner} from "modules/Promotion/hooks";
import {useMobileContextDetect} from "shared/DeviceDetect";

let BannerContainerHead = () => {

    const {bannerContainer} = useBanner()
    const isMobile = useMobileContextDetect();

    return (
         <Card variant={isMobile ? 'secondary' : 'primary'} data-cy={'banner-container-head'}>
             <ImageWrapStyle>
                <ImageUrl src={bannerContainer?.data?.image}/>
             </ImageWrapStyle>
             <ContentStyle>
                 <Typography variant={isMobile ? 'text' : 'h3'}>
                     {bannerContainer?.data?.description}
                 </Typography>
             </ContentStyle>
         </Card>
    );
};



export default BannerContainerHead;
