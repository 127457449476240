import React from 'react';
import {
    WrapStyle,
    ImageWrap,
    TabsStyle,
    ContentStyle,
    TabItemStyle,
    StickersWrapStyle,
    StickerStyle,
    CloseBlockStyle,
    StickerLeftStyle,
    StickerCornerStyle,
} from "./styles";
import Typography from "shared/Typography";
import Button from "shared/Button";
import Link from "shared/Link";
import Sticker from "shared/Sticker";
import PartnerImage from "modules/Partner/components/PartnerImage";
import {DeliveryMethod} from "modules/Partner/enums/deliveryMethodEnum";
import combineLink from "helper/combineLink";
import links from "helper/links";
import {useMobileContextDetect} from "shared/DeviceDetect";
import Icon from "shared/Icon";

const PartnerItem = ({
                   data,
                   variant = 'primary',
                   isClose,
                   mb,
                 isHideCloseLabel,
                   ...rest
                }) => {
    const isMobile = useMobileContextDetect();
    const {
        discountFlag,
        useKassa,
        useBonus,
        isClosed,
        isHalal,
        promoGifts,
        name,
        rating,
        minSum,
        freeDeliverySum,
        deliveryDuration,
        reviewCount,
        deliveryCost,
        isNew,
        timeOut,
        deliveryMethod,
        id,
        alias,
    } = data;

    const hasAction = !!promoGifts && promoGifts.find((item) => item.type !== 2);
    const deliveryText =
        freeDeliverySum > 0
            ? `Доставка бесплатно от ${freeDeliverySum} ₽`
            : `Доставка ${deliveryCost} ₽`;
    const isNewPartner = isNew === 1;

    const renderDeliveryItem = () => {
        switch (deliveryMethod?.key) {
            case DeliveryMethod.Edostav:
                return (
                    <Button size="small" variant='secondary' icon='CourierIcon' textColor='green'>{deliveryDuration}</Button>
                );
            case DeliveryMethod.Taxi:
                return (
                    <Button size="small" variant='secondary' icon='TaxiIcon' textColor='green'>{deliveryDuration}</Button>
                );
            default:
                return (
                    <Button size="small" variant='secondary'  textColor='green'>{deliveryDuration}</Button>
                );
        }
    };



    return (
        <Link
            data-cy={'partner-item'}
            passHref
            key={id}
            href={combineLink(links.abs.partners, "[partnerId]")}
            as={combineLink(links.abs.partners, alias)}
        >
            <WrapStyle
                as={"a"}
                variant={variant}
                isClose={isClose && !isHideCloseLabel}
                mb={mb}
                data-cy="partner-item"
                {...rest}
            >
                <ImageWrap  variant={variant} isClosed={isClosed && !isHideCloseLabel} >
                    <PartnerImage partner={data}/>
                </ImageWrap>
                <ContentStyle variant={variant}>
                    <Typography
                        isBold
                        variant={variant === 'primary' ? isMobile ? 'h2' : 'textExtraBig' : 'h3'}
                        component="h2"
                        mb={0.5}>
                        {name}
                    </Typography>
                    <Typography variant={isMobile ? 'text' : 'textSmall'} color='gray' mb={0.5}>{deliveryText}</Typography>

                    {
                        !Boolean(isClosed && !isHideCloseLabel) && (
                            <TabsStyle >
                                {
                                    (isNewPartner || reviewCount < 10) &&
                                    <TabItemStyle>
                                        <Button
                                            component="button"
                                            icon="StarIcon"
                                            size="small"
                                            variant="tertiary"
                                        >
                                            Новый
                                        </Button>
                                    </TabItemStyle>
                                }

                                {
                                    variant === 'primary'
                                    && (<>
                                            {
                                                !(isNewPartner || reviewCount < 10) && (
                                                    <TabItemStyle>
                                                        <Button
                                                            size="small"
                                                            variant='secondary'
                                                            icon='StarGoldIcon'
                                                            isBold>
                                                            {rating}
                                                        </Button>
                                                    </TabItemStyle>
                                                )
                                            }

                                            <TabItemStyle>
                                                <Button size="small" variant='secondary'>Заказ от {minSum} ₽ </Button>
                                            </TabItemStyle>
                                        </>
                                    )
                                }
                                <TabItemStyle>
                                    {
                                        renderDeliveryItem()
                                    }
                                </TabItemStyle>

                            </TabsStyle>
                        )
                    }



                </ContentStyle>

                {
                    !Boolean(isClosed && !isHideCloseLabel) && (
                        <>

                            {
                                !!hasAction &&  variant === 'primary' &&
                                <StickerLeftStyle variant={variant}>
                                    <Sticker variantText={isMobile ? 'textBig' : 'text'} data={{text: 'Подарок'}} ></Sticker>
                                </StickerLeftStyle>
                            }
                            {
                                discountFlag && (
                                    <StickerCornerStyle>
                                        <Typography variant="text" isBold>{discountFlag}</Typography>
                                    </StickerCornerStyle>
                                )
                            }


                            {
                                variant === 'primary' && (

                                    <StickersWrapStyle variant={variant}>
                                        {Boolean(isHalal) && (
                                            <StickerStyle>
                                                <Icon name={'HalalIcon'} size={26}/>
                                            </StickerStyle>
                                        )}
                                        {
                                            Boolean(useKassa) && (
                                                <StickerStyle>
                                                    <Button size="small" variant='light' icon='MastercardIcon'></Button>
                                                </StickerStyle>
                                            )
                                        }

                                        {
                                            Boolean(useBonus) && (
                                                <StickerStyle>
                                                    <Icon name={'BonusFillIcon'} size={26}/>
                                                </StickerStyle>
                                            )
                                        }



                                    </StickersWrapStyle>
                                )
                            }
                        </>
                    )
                }


                {
                    Boolean(isClosed && !isHideCloseLabel) &&
                    <CloseBlockStyle variant={variant}>
                        <Typography variant="text" isBold>Откроется через {timeOut}</Typography>
                    </CloseBlockStyle>
                }
            </WrapStyle>
        </Link>
    );
};

export default React.memo(PartnerItem,
    (prevProps, nextProps) => {

    return (prevProps.data.id === nextProps.data.id)
});
