import React, {useCallback} from 'react';
import * as S from './styles';
import {useMobileContextDetect} from "shared/DeviceDetect";
import { Link as LinkScroll, Events } from 'react-scroll'

const dividerStyles = {marginVertical: 12};

type Props = {
    menuModalRef: any;
    handleCategoryClick: (item) => void;
    menu: any;
};

export default function MenuModal(props: Props) {
    const {handleCategoryClick, menu, onClose} = props;
    const isMobile = useMobileContextDetect();

    const onItemPressed = useCallback(
        (item: any) => {
            onClose && onClose()
        },
        [handleCategoryClick, onClose],
    );

    const renderItem = useCallback(
        (item: any) => {
            const productsCount = item.products.length;
            const subMenuCount = item.subMenu.length;
            const haveSubMenu = item.subMenu.length !== 0;

            return (
                <S.ItemContainer
                    onClick={() => onItemPressed(item)}
                    data-id={`block${item.uniqId}`}
                    to={`block${item.uniqId}`}
                    spy={true}
                    //smooth={true}
                    offset={isMobile ? -70 : -150}
                    as={LinkScroll}
                >
                    <S.LabelContainer >
                        <S.Label>{item.name}</S.Label>
                        {!haveSubMenu && <S.SubMenuNumber>{productsCount}</S.SubMenuNumber>}
                    </S.LabelContainer>
                    {haveSubMenu &&
                        item.subMenu.map((m: any) => (
                            <S.SubLabelContainer >
                                <S.SubLabel>{m.name}</S.SubLabel>
                                <S.SubMenuNumber>{subMenuCount}</S.SubMenuNumber>
                            </S.SubLabelContainer>
                        ))}
                    <S.Line style={dividerStyles}/>
                </S.ItemContainer>
            );
        },
        [onItemPressed],
    );

    return (
        <S.Container>{menu.map(renderItem)}</S.Container>
    );
}
