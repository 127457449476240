import React from 'react';
import ContentLoader from 'react-content-loader';
import {WrapStyle, ElementStyle} from "./styles";
import {useMobileContextDetect} from "shared/DeviceDetect";

const Element = () => {

    return (
        <ElementStyle>
            <ContentLoader
                data-cy={'promotion-primary-skeleton-loader'}
                backgroundColor="#d9d9d9"
                animate
                speed={0.8}
                //viewBox={`0 0 ${size} ${size}`}
                width={'100%'}
                height={'100%'}
            >
                <rect x="0" y="0" rx="20" ry="20" width={'100%'} height={'100%'} />
            </ContentLoader>
        </ElementStyle>
    );
};
const PromotionPrimarySkeletonLoader = () => {
    const isMobile = useMobileContextDetect();

    let length = isMobile ? 3 : 6

    return (
        <>
            <WrapStyle>
                {
                    Array.from({length: length}).map((val, index) => (
                        <Element key={index} />
                    ))
                }
            </WrapStyle>
        </>
    )
}


export default PromotionPrimarySkeletonLoader
