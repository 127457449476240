const addParamsToUrl = (obj) => {
    let currentUrl = window.location.href;
    let updatedUrl = new URL(currentUrl);

    for (let key in obj) {
        if(obj[key]){
            updatedUrl.searchParams.set(key, obj[key]);
        } else if(updatedUrl.searchParams.has(key)){
            updatedUrl.searchParams.delete(key);
        }
    }

    window.history.replaceState({}, "", updatedUrl);
}

export default addParamsToUrl
