import React from 'react';
import Image from 'shared/Image';
const BASE_URL = process.env.NEXT_PUBLIC_IMAGE_URL

const PartnerImage = ({partner, ...rest}) => {

    return (
        <Image src={BASE_URL + (partner?.wallpaper ? partner?.wallpaper : partner?.image)}
               layout='fill'
               //priority
                lazy="true"
               objectFit='cover'
               data-cy={'partner-image'}
               isHasPlaceholder
               decoding="async"
               {...rest}
               sizes="(min-width: 75em) 12vw,
              (min-width: 48em) 40vw,
              55vw"
        />
    )
}

export default PartnerImage;
