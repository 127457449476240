import styled, {css} from 'styled-components';
import { Fonts, Colors } from 'common/Theme';
import Helpers from 'common/Theme/Helpers';

export const WrapperStyle = styled.div`
  
  //margin-bottom
  ${props => props.mb && css`
    margin-bottom: ${Helpers.css.mb[props.mb]};
  `}
  //end margin-bottom
`;

export const TopStyle = styled.div`
  ${Helpers.css.flex};
  ${Helpers.css.flex_ac};
  margin-bottom: 4px;
`;

export const TitleStyle = styled.div`
  margin-right: 14px;
`;

export const TimeWrapStyle = styled.div`
  ${Helpers.css.flex};
  ${Helpers.css.flex_ac};
  margin-bottom: 14px;
`;

export const DayStyle = styled.div`
  margin-right: 8px;
`;

export const TimeStyle = styled.div`
  margin-left: 8px;
`;

export const StickerListStyle = styled.div`
  ${Helpers.css.flex};
  ${Helpers.css.flex_ac};
  ${Helpers.css.flex_wrap};
  max-width: 350px;
`;

export const StickerStyle = styled.div`
  margin-right: 8px;
  margin-bottom: 8px;
`;


