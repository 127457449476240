import React from 'react';
import {useCart} from 'modules/Cart/hooks';
import {WrapperStyle} from "./styles";
import ProductItem from "shared/Product/components/ProductItem";
import CartSlide from "modules/Cart/components/CartSlide";
import {useSlidePanel} from "shared/SlidePanel";
import useMenu from "modules/Partner/hooks/useMenu"; //TODO объединить в один компонент

/*type Props = {
    cartItems: CartItemIN[],
    onRemoveCartItem: (id: number) => void,
    onOpenProduct: (product: any, isProductForPoint: any, cartItemId: any) => void
}*/

const CartDrinksList = ({data}) => {
    const {onToCart, cart} = useCart()
    const [actions, setActions] = useSlidePanel()
    const {productSync} = useMenu()


    const handleToCart = async (product) => {

        if(product.modifiers.length) {
            await productSync(product, false)

            actions && actions.onOpen()
        } else {
            onToCart({
                productId: product.id,
                quantity: 1,
                zoneId: cart.data.partner?.zoneId,
                type: 0
            })
        }
    }

    //console.log('cart.data.partner?.zoneId', cart.data.partner?.zoneId)


    return (
        <WrapperStyle data-cy={'cart-drinks-list'}>
            {data?.map((item, i) => (
                <ProductItem
                    key={i}
                    onAddToCart={handleToCart}
                    variant={"secondary"} data={item}
                >
                </ProductItem>
            ))}

            <CartSlide
                setActions={setActions}
                form={'cart4'}
                onDismiss={actions.onClose}
                slideClose={actions.onClose}
            />
        </WrapperStyle>
    );
};

export default CartDrinksList;
