import React from 'react';
import {useAuth} from '../../hooks';
import {reduxForm} from 'redux-form';
import Button from 'shared/Button';
import Input from "shared/Input";
import Link from "shared/Link";
import {
    ContentStyle,
    InputRowStyle,
    RowStyle,
    ErrorStyle,
    BottomLinkStyle,
} from "./styles";
import Typography from "shared/Typography";
import Loader from "shared/Loader";
import {links} from "helper";


let AuthForm = () => {

    const {
        phone,
        error,
        getCode,
        onChangeText,
        isLoading
    } = useAuth()

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation()

        console.log('phone', phone)
        getCode()
    }

    return (
        <ContentStyle>
            <Typography align="left" component="h2" mb="2" variant="text">
                Введите номер телефона для авторизации и регистрации
            </Typography>
            <form onSubmit={handleSubmit}>
            <InputRowStyle>
                <RowStyle>
                        <Input
                            meta=''
                            error={error}
                            type="text"
                            placeholder="Номер телефона"
                            input={{
                                value: phone,
                                onChange: e => onChangeText(e.currentTarget.value)
                            }}
                        />
                        <Button as={'div'} icon="CloseIcon" size="small" variant="transparent"
                                onClick={() => onChangeText('')}></Button>
                </RowStyle>
                <ErrorStyle>{error}</ErrorStyle>
            </InputRowStyle>
            <Button mb={1} disabled={isLoading} type={'submit'} component="button" fullSize size="big" variant="primary">
                {
                    isLoading ? <Loader /> : 'Далее'
                }
            </Button>
            </form>

            <Typography align="center" component="p" mb="" variant="textSmall">
                    This site is protected by reCAPTCHA and the Google <a target='_blank' href="https://policies.google.com/privacy">Privacy Policy</a> and  <a target='_blank' href="https://policies.google.com/terms">Terms of Service</a> apply.
                </Typography>
            <BottomLinkStyle>
                <Typography align="center" component="p" mb="" variant="text">
                    При входе или регистрации вы прринмаете условия
                    <Link href={links.abs.agreement}>
                        <a target="_blank">
                            <Typography variant="text" align="center" isUnderline>
                                пользовательского соглашения
                            </Typography>
                        </a>
                    </Link>
                </Typography>
            </BottomLinkStyle>

        </ContentStyle>
    )
}

AuthForm = reduxForm({
    form: 'AuthForm',
    enableReinitialize: true,
})(AuthForm);

export default AuthForm
