import React from 'react';
import {PrefixStyle} from "./styles";

enum currencyList {
    RUB = {
        primary: '₽',
        secondary: '₽',
    }
}

const PointPrefix = ({
                        children,
                        mb,
                        prefix,
                        variant= 'primary',
                        currency = {
                            code: 'RUB',
                            sign: currencyList.RUB.primary
                        },
                        ...rest
                  }) => {

    const data = prefix ? prefix : currencyList[currency.code] ? currencyList[currency.code][variant] : currency.sign

    return (
        <>
            <PrefixStyle
                data-cy={'point-prefix'}
                prefix={prefix}
                mb={mb}
                {...rest}
            >
                {children}&nbsp;
                {data}
            </PrefixStyle>
        </>
    );
};

export default PointPrefix;
