import React from 'react';
import {
    WrapperStyle,
    TopStyle,
    TitleStyle,
    TimeWrapStyle,
    DayStyle,
    TimeStyle,
    StickerListStyle,
    StickerStyle,
} from "./styles";
import Typography from "shared/Typography";
import Icon from "shared/Icon";
import Button from "shared/Button";
import PartnerHeaderSkeletonLoader from "shared/skeleton/PartnerHeaderSkeletonLoader";

const PartnerHeader = ({
                        mb,
                        data,
                        onClickInfo,
                           isLoading,
                        ...rest
                  }) => {

    if(isLoading) {
        return  <PartnerHeaderSkeletonLoader/>
    }



    return (
        <>
            <WrapperStyle
                data-cy={'partner-header'}
                mb={mb}
                {...rest}
            >
                <TopStyle>
                    <TitleStyle><Typography component={'h1'} variant="h1">{data.name}</Typography></TitleStyle>
                    <Icon onClick={onClickInfo} name="InfoCircleIcon" height='20px' width="20px"/>
                </TopStyle>
                <TimeWrapStyle>
                    <DayStyle><Typography variant="textSmall" color="gray">{data.day}</Typography></DayStyle>
                    <Icon name="ArrowRightLongIcon"/>
                    <TimeStyle><Typography variant="textSmall" color="gray">{data.time}</Typography></TimeStyle>
                </TimeWrapStyle>
                <StickerListStyle>
                    {data.stickers.map((sticker, i) => (
                        <StickerStyle key={i}>
                            <Button
                                size="normal"
                                variant="light"
                                icon={sticker.icon}
                                textColor={sticker.textColor}
                                onClick={sticker.onClick}
                            >
                                {sticker.name}
                            </Button>
                        </StickerStyle>
                    ))}
                </StickerListStyle>
            </WrapperStyle>
        </>
    );
};

export default PartnerHeader;
