import styled, {css} from 'styled-components';
import {Fonts, Colors} from 'common/Theme/index';
import Helpers from 'common/Theme/Helpers';

export const TextStyle = styled.p`

  //variant
  ${props => props.variant === 'h1' && css`
    ${Fonts.h1};

    @media (min-width: 1500px) {
      ${Fonts.desktop.h1};
    }
    font-weight: bold;
  `}
  ${props => props.variant === 'h2' && css`
    ${Fonts.h2};
    @media (min-width: 1500px) {
      ${Fonts.desktop.h2};
    }

    font-weight: bold;
  `}
  ${props => props.variant === 'h3' && css`
    ${Fonts.h3};
    @media (min-width: 1500px) {
      ${Fonts.desktop.h3};
    }
    font-weight: bold;
  `}
  ${props => props.variant === 'h4' && css`
    ${Fonts.h4};
    @media (min-width: 1500px) {
      ${Fonts.desktop.h4};
    }
    font-weight: bold;
  `}
  ${props => props.variant === 'h5' && css`
    ${Fonts.h5};
    @media (min-width: 1500px) {
      ${Fonts.desktop.h5};
    }
    font-weight: bold;
  `}
  ${props => props.variant === 'text' && css`
    ${Fonts.text};
    @media (min-width: 1500px) {
      ${Fonts.desktop.text};
    }
  `}
  ${props => props.variant === 'textSmall' && css`
    ${Fonts.textSmall};
    @media (min-width: 1500px) {
      ${Fonts.desktop.textSmall};
    }
  `}
  ${props => props.variant === 'textBig' && css`
    ${Fonts.textBig};

    @media (min-width: 1500px) {
      ${Fonts.desktop.textBig};
    }
  `}

  ${props => props.variant === 'textExtraBig' && css`
    ${Fonts.textExtraBig};

    @media (min-width: 1500px) {
      ${Fonts.desktop.textExtraBig};
    }
  `} //end variant

    //isBold
  ${props => props.isBold && css`
    font-weight: bold;
  `} //end isBold

    //isBold
  ${props => props.isItalic && css`
    font-style: italic;
  `} //end isBold

    //isUnderline
  ${props => props.isUnderline && css`
    text-decoration-line: underline;
  `} //end isUnderline

    //color
  ${props => props.color && css`
    color: ${Colors[props.color]};
  `} //end color

    //align
  ${props => props.align === 'center' && css`
    ${Helpers.textCenter};
  `}
  ${props => props.align === 'left' && css`
    ${Helpers.textLeft};
  `}
  ${props => props.align === 'right' && css`
    ${Helpers.textRight};
  `}
  ${props => props.align === 'justify' && css`
    ${Helpers.textJustify};
    //text-align-last: justify;
  `}
  ${props => props.align === 'inherit' && css`
    text-align: inherit;
  `} //end align

    //margin-bottom
  ${props => props.mb && css`
    margin-bottom: ${Helpers.css.mb[props.mb]};
  `} 
  
  ${props => props.mt && css`
    margin-top: ${Helpers.css.mb[props.mt]};
  `} 

  ${props => props.isFullWidth && css`
    width: 100%;
  `}
`;
