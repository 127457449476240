import React from 'react';
import {BtnStyle, IconStyle} from "./styles";
import Icon from "shared/Icon/index";
import { Colors } from 'common/Theme/index';

const Button = ({
                    children,
                    variant = 'primary',
                    size = 'big',
                    isShadow,
                    icon,
                    iconColor,
                    iconPosition,
                    fullSize,
                    onClick,
                    href,
                    component = 'button',
                    disabled,
                    textColor,
                    isBorder,
                    isBold,
                    mb,
                    ...rest
                  }) => {

    return (
        <>
            <BtnStyle
                variant={variant}
                isShadow={isShadow}
                isBold={isBold}
                fullSize={fullSize}
                size={size}
                iconPosition={iconPosition}
                disabled={disabled}
                isBorder={isBorder}
                as={component}
                href={href}
                onClick={onClick}
                textColor={Colors[textColor]}
                isHasChildren={Boolean(children)}
                mb={mb}
                data-cy='button'
                {...rest}
            >

                { icon ?
                    <IconStyle isHasChildren={Boolean (children)} iconPosition={iconPosition}>
                        <Icon name={icon} color={iconColor}/>
                    </IconStyle> : null }
                {children}

            </BtnStyle>
        </>
    );
};

export default Button;
