import React from 'react';
import {
    WrapStyle,
    ButtonWrapStyle,
    ImageWrapStyle,
    TextWrapStyle,
    WrapMiniStyle,
    TextWrapMiniStyle,
    ImageWrapMiniStyle,
    ButtonMiniStyle,
    WrapSecondStyle,
    TitleSecondWrapStyle,
    ImageSecondWrapStyle,
    ButtonSecondWrapStyle,
} from "./styles";
import Typography from "shared/Typography";
import Image from 'shared/Image'
import ImageUrl from 'shared/ImageUrl'
import Button from "shared/Button";
import PointPrefix from "shared/PointPrefix";

const ProductItem = ({
                         data,
                         mb,
                         variant = 'primary',
                         onAddToCart,
                         ...rest
                     }) => {


    const handleAddToCart = (data) => {
        onAddToCart(data)
    }




    const primary = (
        <>
            <WrapStyle
                mb={mb}
                {...rest}
            >
                <ImageWrapStyle><Image src={'/img/' + data.img}></Image></ImageWrapStyle>
                <TextWrapStyle>
                    <Typography maxLength={50} variant="h3" component="h3">{data.title}</Typography>
                    <Typography variant="text" color="gray" align="center"><PointPrefix prefix="г">{data.weight}</PointPrefix></Typography>
                </TextWrapStyle>
                <ButtonWrapStyle>
                    <Button size="big" variant='secondary' fullSize><Typography variant="h2"><PointPrefix>{data.price}</PointPrefix></Typography></Button>
                </ButtonWrapStyle>
            </WrapStyle>
        </>
    )

    const secondary = (
        <>
            <WrapMiniStyle
                mb={mb}
                {...rest}
            >
                <TextWrapMiniStyle>
                    <Typography maxLength={12} variant="h4" component="h3" isItalic>{data.name}</Typography>
                    <Typography variant="textSmall" color="gray" maxLength={15}>{data.description}</Typography>
                    <Typography variant="text"><PointPrefix>{data.price}</PointPrefix></Typography>
                </TextWrapMiniStyle>
                <ImageWrapMiniStyle><ImageUrl src={data.image}></ImageUrl></ImageWrapMiniStyle>
                <ButtonMiniStyle><Button  onClick={() => handleAddToCart(data)} size="small" variant='light'  isShadow icon="CartIcon"></Button>
                </ButtonMiniStyle>
            </WrapMiniStyle>
        </>
    )

    const tertiary = (
        <>
            <WrapSecondStyle
                mb={mb}
                {...rest}
            >
                <ImageSecondWrapStyle><Image src={'/img/' + data.img}></Image></ImageSecondWrapStyle>
                <TextWrapStyle>
                    <TitleSecondWrapStyle>
                        <Typography variant="textSmall" component="h3" isBold>{data.title}</Typography>
                    </TitleSecondWrapStyle>

                    {
                        data.price &&
                        <Typography variant="textSmall" isBold><PointPrefix>{data.price}</PointPrefix></Typography>
                    }
                </TextWrapStyle>
                <ButtonSecondWrapStyle>
                    <Button size="normal" variant='secondary' fullSize><Typography variant="text"><PointPrefix>{data.price}</PointPrefix></Typography></Button>
                </ButtonSecondWrapStyle>
            </WrapSecondStyle>
        </>
    )

    return (
        <>
            { variant === 'primary' && primary }
            { variant === 'secondary' && secondary }
            { variant === 'tertiary' && tertiary }
        </>
    );
};

export default ProductItem;
