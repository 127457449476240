import styled from 'styled-components';

export const Container = styled.div`
    margin-right: -10px;
  padding-right: 0px;
  background: #fff;
  z-index: 2;
`;

export const RowStyle = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
`;
