import React from "react";
import { WrapStyle, ImageWrapStyle, TextStyle } from "./styles";
import Typography from "shared/Typography";
import ImageUrl from "shared/ImageUrl";
import Link from "shared/Link";
import combineLink from "helper/combineLink";
import links from "helper/links";

const ShopProductItem = ({ data = {}, partnerId, mb, ...rest }) => {
    const { image, name, id } = data;

    return (
        <WrapStyle mb={mb} {...rest} data-cy={"shop-product-item"}>
            <Link href={combineLink("/menu", id)}>
                <a>
                    <ImageWrapStyle>
                        <ImageUrl
                            isWithoutBaseUrl={true}
                            src={image}
                        ></ImageUrl>
                    </ImageWrapStyle>
                    <TextStyle>
                        <Typography variant="bigText">{name}</Typography>
                    </TextStyle>
                </a>
            </Link>
        </WrapStyle>
    );
};

export default ShopProductItem;
