import { createSelector } from 'reselect'
import { cartState } from 'modules/Cart/store/cart/reducer';

export const cartSelector = (state: { cart: cartState }) => state.cart;


export const cartDataSelector = createSelector(
    cartSelector, (state) => state.data
);

export const isAddLoadingSelector = createSelector(
    cartSelector, (state) => state.isAddLoading
);

export const productQuantityInCartByIdSelector = (id: number, type: number) =>
    createSelector(cartDataSelector, (data) => {

        const similarProducts: [] = data?.cartItems?.filter((cartItem) =>
            cartItem.product.id === id && cartItem.type === type);

        const quantityObj = !!similarProducts?.length ?
            similarProducts.reduce((accumulator, currentValue) => {
                return { quantity: accumulator.quantity + currentValue.quantity };
            })
            : { quantity: 0 };

        return quantityObj.quantity;
    })

export const productInCartByIdSelector = (id: number, type: number) =>
    createSelector(cartDataSelector, (data) => {
        const items = !!data.cartItems && data?.cartItems?.filter(cartItem =>
            cartItem.product.id === id && cartItem.type === type) || []
        return !!items.length ? items.reduce((prev, current) => (prev.id > current.id) ? prev : current) : null
    });

export const productsForPointSelector = createSelector(
    cartDataSelector, (data) =>
        !!data.cartItems && data.cartItems.find(
        (product) => product.type === 1
        ));
