import React from 'react';
import {ContentWrapStyle, BandStyle, TitleStyle, ButtonWrapStyle, ImgWrapStyle, ChildStyle, NoteStyle} from "./styles";
import Typography from "shared/Typography";
import Button from "shared/Button";
import Icon from "shared/Icon";

const BottomSlideContent = ({
                        children,
                        icon,
                        head,
                        note,
                        isBorder,
                        buttonClick,
                        buttonText,
                        buttonVariant = 'secondary',
                        buttonSecondClick,
                        buttonSecondText,
                        buttonSecondVariant = 'secondary',
                        isChildrenPadding,
                        mb,
                        ...rest
                  }) => {

    return (
        <>
            <ContentWrapStyle
                data-cy={'bottom-slide-content'}
                mb={mb}
                {...rest}
            >
                <BandStyle/>
                {
                    icon
                    && <ImgWrapStyle>
                        <Icon name={icon} height='48' width='48'/>
                    </ImgWrapStyle>
                }

                { head ? <TitleStyle><Typography variant="h3" component="h3" align="center">{head}</Typography></TitleStyle> : null }
                { note ? <NoteStyle><Typography variant="text" align="center">{note}</Typography></NoteStyle> : null }
                { children ? <ChildStyle isBorder={isBorder} isChildrenPadding={isChildrenPadding}>{children}</ChildStyle> : null }
                {
                    Boolean(buttonText || buttonClick) && (
                        <ButtonWrapStyle>
                            <Button size="big" variant={buttonVariant} onClick={buttonClick} fullSize>{buttonText}</Button>

                            {
                                Boolean(buttonSecondClick || buttonSecondText) && (
                                    <Button
                                        size="big"
                                        variant={buttonSecondVariant} onClick={buttonSecondClick} fullSize>{buttonSecondText}</Button>
                                )
                            }
                        </ButtonWrapStyle>
                    )
                }
            </ContentWrapStyle>
        </>
    );
};

export default BottomSlideContent;
