import styled, {css} from 'styled-components';
import { Fonts, Colors } from 'common/Theme';
import Helpers from 'common/Theme/Helpers';

export const WrapStyle = styled.div`
  ${Helpers.css.flex_no_shrink};
  height: 165px;
  width: 48%;
  margin-bottom: 16px;
  border-radius: 15px;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.12);
  position: relative;

  ${props => props.mb && css`
      margin-bottom: ${Helpers.css.mb[props.mb]};
  `}

  @media (min-width: 768px) {
    width: 24%;
  }
  
`;

export const ImageWrapStyle = styled.div`
  ${Helpers.fullSize};
  border-radius: 15px;
  overflow: hidden;
  position: relative;

  img{
    border-radius:15px;
    transition: all 0.3s ease 0s;
    &:hover {
      transform: scale(1.05);
    }

    &:active {
      transform: scale(1);
      transition: all 0.3s ease 0s;
    }
  }
`;

export const TextStyle = styled.div`
  top: 0px;
  left: 0;
  padding: 10px;
  position: absolute;
`;
