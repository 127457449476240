import styled, {css} from 'styled-components';
import { Fonts, Colors } from 'common/Theme';
import Helpers from 'common/Theme/Helpers';

export const WrapperStyle = styled.div`
  //width: 100%;

  //margin-bottom
  ${props => props.mb && css`
      margin-bottom: ${Helpers.css.mb[props.mb]};
  `}
  margin-bottom: 20px;
  //end margin-bottom
  
  ${Helpers.css.flex};
  ${Helpers.css.flex_ac};
  overflow: auto;
`;
