import styled, {css} from 'styled-components';
import { Fonts, Colors } from 'common/Theme';
import Helpers from 'common/Theme/Helpers';

export const ItemWrapStyle = styled.label`
  position: relative;
  cursor: pointer;
  ${Helpers.fullWidth};
  ${Helpers.css.flex};
  ${Helpers.css.flex_ac};
  
  //margin-bottom
  ${props => props.mb && css`
    margin-bottom: ${Helpers.css.mb[props.mb]};
    `}
  //end margin-bottom
`;

export const TabCheckBoxStyle = styled.div`
  width: 22px;
  height: 22px;
  border: 1px solid ${Colors.gray};
  border-radius: 50%;
  margin-right: 10px;
  position: relative;
`;

export const LabelStyle = styled.div`
  ${Fonts.textBig};
`;

export const InputStyle = styled.input`
  display: none;
  &:checked, &:not(:checked) {
    display: none;
  }
  
  &:checked + ${TabCheckBoxStyle}{
    border: 2px solid ${Colors.green};
    :after {
      content: '';
      display: block;
      position: absolute;
      top: 4px;
      left: 4px;
      width: 10px;
      height: 10px;
      opacity: 100%;
      border-radius: 50%;
      background: ${Colors.green};
    }
  }
`;



export const ErrorStyle = styled.div`
  color: ${Colors.red};
   ${Fonts.textSmall};
   margin-top: 5px;
`;