import React from 'react';
import * as S from './styles';

type Props = {
  suggestedAddress: any,
  selectAddress: (address: string) => void,
  lengthBold: number,
};

const AddressElementComponent = (props: Props) => {
  const { suggestedAddress, selectAddress, lengthBold } = props;
  const boldSubTitle = suggestedAddress.address?.slice(0, lengthBold);
  const regularSubTitle = suggestedAddress.address?.slice(
    lengthBold,
    suggestedAddress.address?.length
  );

  return (
    <div onClick={() => selectAddress(suggestedAddress.address)} data-cy={'address-element-component'}>
      {suggestedAddress.region ? (
        <S.Container>
          <S.Street>
            <div >
              {suggestedAddress.address}
            </div>
          </S.Street>
          <S.City>
            <div >{suggestedAddress.region}</div>
          </S.City>
        </S.Container>
      ) : (
        <S.SingleCity>
          <S.BoldSubTitle>{lengthBold > 0 && boldSubTitle}</S.BoldSubTitle>
          <S.RegulatSubTitle>
            {lengthBold > 0 ? regularSubTitle : suggestedAddress.address}
          </S.RegulatSubTitle>
        </S.SingleCity>
      )}
    </div>
  );
};

export default AddressElementComponent;
