import request from "helper/request";
import combineLink from "helper/combineLink";


class BannerService {
    fetchBanners = async (config) => {
        let options = {
            setLoading: false,
            withLoader: true,
            ...config
        }

        //return request.get(combineLink(api.banner), options).then(data => data)
        return request.get(combineLink(process.env.NEXT_PUBLIC_API_URL_V_3_1.slice(0, -1)
            , 'banner'), options).then(data => data)
    };

    fetchBannerContainerById = async (id, config) => {
        let options = {
            setLoading: false,
            withLoader: true,
            ...config
        }

        //return request.get(combineLink(api.banner), options).then(data => data)
        return request.get(combineLink(process.env.NEXT_PUBLIC_API_URL_V_3_1.slice(0, -1)
            , 'container', id), options).then(data => data)
    };
}

const bannerService = new BannerService();
export default bannerService