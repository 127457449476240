import {removeCookies, setCookies} from "cookies-next";
import {getDomain} from "store";

const savedActionFormData = () => {
    const onRemoveDataOrderFromCookies = () => {
        const domain = getDomain()

        const options = {
            domain
        }


        removeCookies('form.order.values.Address.number',options)
        removeCookies('form.order.values.Order.text',options)
        removeCookies('form.order.values.deliveryMethod',options)
        removeCookies('form.order.values.payType',options)
    }

    const onAddDataOrderFromCookies = (state) => {
        const domain = getDomain()

        const options = {
            domain
        }

        const {number, podezd, storey, domofon} = state?.Address
        const {text, name, phone} = state?.Order
        const deliveryMethod = state?.deliveryMethod
        const payType = state?.payType
        payType && setCookies('form.order.values.payType', payType, options)
        number && setCookies('form.order.values.Address.number', number, options)
        text && setCookies('form.order.values.Order.text', text, options)
        name && setCookies('form.order.values.Order.name', name, options)
        phone && setCookies('form.order.values.Order.phone', phone, options)

        podezd && setCookies('form.order.values.Address.podezd', podezd, options)
        storey && setCookies('form.order.values.Address.storey', storey, options)
        domofon && setCookies('form.order.values.Address.domofon', domofon, options)

        deliveryMethod !== undefined  && setCookies('form.order.values.deliveryMethod', deliveryMethod, options)
    }


    return {
        onRemoveDataOrderFromCookies,
        onAddDataOrderFromCookies
    }

}

export default savedActionFormData
