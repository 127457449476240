import React, {useEffect, useState, useRef} from 'react'
import { BottomSheet } from 'react-spring-bottom-sheet'
import "react-spring-bottom-sheet/dist/style.css";
import {WrapDesktopStyle, WrapStyle, CloseWrapStyle, ContentDesktopStyle} from './styles'
import Popup from 'reactjs-popup';
import {useMobileContextDetect} from 'shared/DeviceDetect';
import Button from 'shared/Button';




const SlidePanel = ({ children, initSheet,isPadding, state = false, ...rest }, ref) => {
    const isMobile = useMobileContextDetect();
    const [open, setOpen] = useState(state)
    const [actions, setActions] = useState()
    //const sheetRef = React.useRef()
    //console.log('sheetRef2', sheetRef)

    useEffect( () => {
        //await setOpen(false)
        /*ref.current.actions = {
            onClose,
            onOpen
        }*/

        const act = initSheet({
            onClose,
            onOpen
        })

        setActions(act)


    }, [])

    /*ref.current.actions = {
        onClose,
        onOpen
    }*/

    const beforeClose = useRef()
    const afterClose = useRef()

    const onClose = (beforeCallback, afterCallback) => {
        beforeClose.current = beforeCallback;
        afterClose.current = afterCallback;
        setOpen(false)
    }

    const beforeOpen = useRef()
    const afterOpen = useRef()

    const onOpen = (beforeCallback, afterCallback) => {

        beforeOpen.current = beforeCallback;
        afterOpen.current = afterCallback;
        setOpen(true)
    }


    if(!isMobile){
        return (
            <Popup
                onOpen={() => {
                    typeof afterOpen.current === "function" && afterOpen.current()
                    typeof beforeOpen.current === "function" && beforeOpen.current()
                }}

                onClose={() => {
                    typeof afterClose.current === "function" && afterClose.current()
                    typeof beforeClose.current === "function" && beforeClose.current()
                    onClose()
                }}

                open={open}
                closeOnDocumentClick
                {...rest}
            >
                <WrapDesktopStyle>
                    <ContentDesktopStyle>
                        <CloseWrapStyle>
                            <Button onClick={onClose} variant={'transperit'} icon={'CloseIcon'}/>
                        </CloseWrapStyle>
                        {children}
                    </ContentDesktopStyle>
                </WrapDesktopStyle>
            </Popup>
        )
    }


    return (
        <BottomSheet
            on="click"
            expandOnContentDrag={true}
            initialFocusRef={true}
            snapPoints={({ minHeight, maxHeight }) => [
                minHeight,
                maxHeight - 30,
            ]}
            defaultSnap={({ lastSnap, snapPoints }) => {

                return snapPoints[0]
            }

            }
            blocking={false}
            open={open}

            //ref={sheetRef}
            ref={ref}
            onSpringEnd={ (event) => {
                if (event.type === 'OPEN') {

                    typeof afterOpen.current === "function" && afterOpen.current()
                }
                if (event.type === 'CLOSE') {
                    typeof afterClose.current === "function" && afterClose.current()
                }
            }}
            onSpringStart={ (event) => {
                if (event.type === 'OPEN') {
                    typeof beforeOpen.current === "function" && beforeOpen.current()
                }
                if (event.type === 'CLOSE') {
                    typeof beforeClose.current === "function" && beforeClose.current()
                }
            }}
            {...rest}
        >
            <WrapStyle isPadding={isPadding}>
                {children}
            </WrapStyle>
        </BottomSheet>
    )
}

export default React.forwardRef(SlidePanel)
