import Colors  from './Colors'

export default {
    backgroundReset: {
        backgroundColor: Colors.transparent,
    },
    center: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    colCenter: {
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    colCross: {
        alignItems: 'center',
        flexDirection: 'column',
    },
    colMain: {
        flexDirection: 'column',
        justifyContent: 'center',
    },
    column: {
        flexDirection: 'column',
    },
    columnReverse: {
        flexDirection: 'column-reverse',
    },
    crossCenter: {
        alignItems: 'center',
    },
    crossEnd: {
        alignItems: 'flex-end',
    },
    crossStart: {
        alignItems: 'flex-start',
    },
    crossStretch: {
        alignItems: 'stretch',
    },
    fill: {
        flex: 1,
    },
    fillCenter: {
        alignItems: 'center',
        flex: 1,
        justifyContent: 'center',
    },
    fillCol: {
        flex: 1,
        flexDirection: 'column',
    },
    fillColCenter: {
        alignItems: 'center',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
    },
    fillColCross: {
        alignItems: 'center',
        flex: 1,
        flexDirection: 'column',
    },
    fillColMain: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
    },
    fillColReverse: {
        flex: 1,
        flexDirection: 'column-reverse',
    },
    fillRow: {
        flex: 1,
        flexDirection: 'row',
    },
    fillRowCenter: {
        alignItems: 'center',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
    },
    fillRowCross: {
        alignItems: 'center',
        flex: 1,
        flexDirection: 'row',
    },
    fillRowMain: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
    },
    fillRowReverse: {
        flex: 1,
        flexDirection: 'row-reverse',
    },
    fullSize: {
        height: '100%',
        width: '100%',
    },
    fullWidth: {
        width: '100%',
    },
    mainCenter: {
        justifyContent: 'center',
    },
    mainEnd: {
        justifyContent: 'flex-end',
    },
    mainSpaceAround: {
        justifyContent: 'space-around',
    },
    mainSpaceBetween: {
        justifyContent: 'space-between',
    },
    mainStart: {
        justifyContent: 'flex-start',
    },
    mirror: {
        transform: [{ scaleX: -1 }],
    },
    rotate90: {
        transform: [{ rotate: '90deg' }],
    },
    rotate90Inverse: {
        transform: [{ rotate: '-90deg' }],
    },
    row: {
        flexDirection: 'row',
    },
    rowCenter: {
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    rowCross: {
        alignItems: 'center',
        flexDirection: 'row',
    },
    rowMain: {
        flexDirection: 'row',
        justifyContent: 'center',
    },
    rowReverse: {
        flexDirection: 'row-reverse',
    },
    scrollSpaceAround: {
        flexGrow: 1,
        justifyContent: 'space-around',
    },
    scrollSpaceBetween: {
        flexGrow: 1,
        justifyContent: 'space-between',
    },
    selfStretch: {
        alignSelf: 'stretch',
    },
    textCenter: {
        textAlign: 'center',
    },
    textJustify: {
        textAlign: 'justify',
    },
    textLeft: {
        textAlign: 'left',
    },
    textRight: {
        textAlign: 'right',
    },
    fillGrow: {
        flexGrow: 1,
    },
    css: {
        flex: {
            display: 'flex'
        },
        flex_inline: {
            display: 'inline-flex'
        },
        flex_sb: {
            'justify-content': 'space-between'
        },
        flex_sw: {
            'flex-basis': '100%'
        },
        flex_ac: {
            'align-items': 'center'
        },
        flex_ab: {
            'align-items': 'flex-end'
        },
        flex_at: {
            'align-items': 'flex-start'
        },


        flex_hc: {
            'justify-content': 'center'

        },
        flex_hr: {
            'justify-content': 'flex-end'

        },

        flex_hl: {
            'justify-content': 'flex-start'

        },
        flex_center: {
            'align-items': 'center',
            'justify-content': 'center',
        },
        flex_vertical: {
            'flex-direction': 'column'
        },
        flex_wrap: {
            'flex-wrap': 'wrap'
        },
        flex_no_wrap: {
            'flex-wrap': 'nowrap'
        },
        flex_grow: {
            'flex-grow': '1'
        },
        flex_no_grow: {
            'flex-grow': '0'
        },
        flex_no_shrink: {
            'flex-shrink': '0'
        },
        flex_reverse: {
            'flex-direction': 'row-reverse'
        },
        flex_reverse_column: {
            'flex-direction': 'column-reverse'
        },
        cursor_pointer: {
            'cursor': 'pointer'
        },
        item_center :{
            'top': '50%',
            'left': '50%',
            '-ms-transform': 'translate(-50%,-50%)',
            '-webkit-transform': 'translate(-50%,-50%)',
            'transform': 'translate(-50%,-50%)',
            'position': 'absolute',
        },
        item_horizontal :{
            'left': '50%',
            '-ms-transform': 'translate(-50%,0)',
            '-webkit-transform': 'translate(-50%,0)',
            'transform':' translate(-50%,0)',
            'position': 'absolute',
        },
        item_vertical :{
            'top': '50%',
            '-ms-transform': 'translate(0,-50%)',
            '-webkit-transform': 'translate(0,-50%)',
            'transform': 'translate(0,-50%)',
            'position': 'absolute',
        },
        mb: {
            '0': '0px',
            '0.5': '5px',
            '1': '10px',
            '1.5': '15px',
            '2': '20px',
            '2.5': '25px',
            '3': '30px',
            '3.5': '35px',
            '4': '40px',
            '4.5': '45px',
            '5': '50px',
        }
    }
    //screenWidth: Dimensions.get("screen").width,
    //screenHeight: Dimensions.get("screen").height,
}

