import styled, {css} from 'styled-components';
import { Fonts, Colors } from 'common/Theme';
import Helpers from 'common/Theme/Helpers';

export const WrapStyle = styled.div`
  ${Helpers.css.flex};  
  ${Helpers.css.flex_ac};
  gap: 25px;
  overflow: auto;

  @media (max-width: 768px) {
    gap: 13px;
  }
`;

export const ElementStyle = styled.div`
  width: 180px;
  height: 180px;
  ${Helpers.css.flex_no_shrink};
  svg {
    display: block;
  }

  @media (min-width: 768px) {
    width: 230px;
    height: 230px;
  }
`;