import React, {useEffect} from 'react';
import {useBonus} from 'modules/Bonus/hooks';
import BonusList from 'shared/Bonus/components/BonusList';
import Typography from 'shared/Typography';


const PartnersList = ({}) => {
    const { onBonusUserSync, bonus } = useBonus()
    //const {map} = useMap(null)
    //const map = useSelector((state: RootState) => state.map);
    //const isHasCoords = (map.data?.latitude && map.data?.longitude)
    //const navigation = navRoute()
    //console.log('partners', partners)

    useEffect(() => {
        onBonusUserSync()
    },[])

    return (
        <>
            <br/>
            <br/>
            <Typography variant={'h3'} align={'center'} mb={1}>Информация о начисленных баллах</Typography>
            <BonusList data={bonus.data} />
        </>
    )

}


export default PartnersList