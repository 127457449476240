import styled from 'styled-components';
import Helpers from 'common/Theme/Helpers';


export const TitleWrap = styled.div`
   ${Helpers.css.flex};
    ${Helpers.css.flex_ac};
    margin-bottom: 20px;
`;

export const Wrap = styled.div`
  @media (min-width: 768px) {
    margin-bottom: 40px;
  }
`;
