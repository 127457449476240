import React from 'react';
import * as S from './styles';
import Typography from 'shared/Typography';
import PropTypes from 'prop-types';

const Checkbox = ({
                      rowlabel,
                      stylesCheckbox,
                      label,
                      input,
                      meta,
                      htmlProp,
                      id
                  }) => {


    return (
        <>
            {label && <S.Label htmlFor={input.name}>{label}</S.Label>}
            <S.RowView is100Width={!!rowlabel} data-cy={'check-box'}>
                {rowlabel &&  <Typography as={'label'} variant="text" htmlFor={id} >{rowlabel}</Typography> }

                <S.CheckboxWrap>
                    <S.Checkbox
                        {...input}
                        type={'checkbox'}
                        //onValueChange={(value) => input.onChange(value)}
                        checked={input.value}
                        {...htmlProp}
                        style={stylesCheckbox}
                        id={id}
                    />
                    <S.CheckboxCustom/>
                </S.CheckboxWrap>

                {meta.error && meta.touched && <S.Error>{meta.error}</S.Error>}
            </S.RowView>
        </>
    );
};

Checkbox.defaultProps = {
    input: {},
    meta: {},
};

Checkbox.propTypes = {
    valid: PropTypes.array,
    input: PropTypes.object,
};

export default Checkbox;
