import React from 'react';
import {WrapStyle, IconWrapStyle, TextStyle} from "./styles";
import Typography from "shared/Typography";
import Icon from "shared/Icon";

const Sticker = ({
                   data,
                   mb,
                    variantText = "textBig",
                   ...rest
                }) => {


    return (
        <>
            <WrapStyle
                data-cy={'sticker'}
                mb={mb}
                {...rest}
            >
                <IconWrapStyle>
                    <Icon name="GiftIcon"></Icon>
                </IconWrapStyle>
                <TextStyle><Typography variant={variantText}>{data.text}</Typography></TextStyle>
            </WrapStyle>
        </>
    );
};

export default Sticker;
