import React, {useEffect, useState} from 'react';
import  usePartner from '../../hooks/usePartner';
import  useFilter from '../../hooks/useFilter';
import  navRoute from 'helper/navRoute';
import  PartnerItem from '../PartnerItem';
import {WrapperStyle} from "./styles";
import PartnersSkeletonLoader from "modules/Partner/components/PartnersSkeletonLoader";
import PartnersListEmptyContent from "modules/Partner/components/PartnersListEmptyContent";
import usePrevious from 'shared/hooks/usePrevious'
import InfiniteScroll from 'react-infinite-scroller';
import {parseLocationSearch} from "helper";


const PartnersList = ({}) => {
    const [limit, setLimit] = useState(20)
    const [hasMore, setHasMore] = useState(true)

    const {partners, partnersSync} = usePartner()
    const {getActiveFilter} = useFilter()
    //const {map} = useMap(null)
    //const map = useSelector((state: RootState) => state.map);
    //const isHasCoords = (map.data?.latitude && map.data?.longitude)
    const navigation = navRoute()
    //console.log('partners', partners)


    const alias = typeof window !== 'undefined' ? window.location.pathname.slice(1) : null

    const prevAlias = usePrevious(alias)
    //const router = useRouter()
    useEffect(() => {

        //const alias = window.location.pathname
        const filterQuery = parseLocationSearch(location.search)
        const isHasFilter = Object.keys(filterQuery).length
        console.log('filterQuery', filterQuery, isHasFilter)
        let config = {}
        const directionOrSpecialization = alias === 'restorany' ? '' : alias;

        /*if(alias && (prevAlias !== alias)){
            //partnersSync({...filterQuery, limit: 10}, config)
        }*/

        setLimit(20)
        setHasMore(true)


        if (
            (navigation.helpers.checkTemplateHasCoords()) &&
            (
                !partners?.data?.list?.length ||
                ((alias && prevAlias) && (prevAlias !== alias))
            )
        ) {
            partnersSync(filterQuery, directionOrSpecialization, config)
        } else if (isHasFilter) {
            partnersSync(filterQuery, directionOrSpecialization, config)
        }

        /*if(partners?.data?.list?.length <= 10){
            partnersLoadMore({...filterQuery, offset: 10}, config)
        }*/
    },[alias])

    const loadFunc = () => {
        console.log(partners?.data?.list?.length / limit)

        if(partners?.data?.list?.length / limit < 1){
            setHasMore(false)
        }else {
            const data = limit + 20

            setLimit(data)
        }
    }


    if(partners?.isFetching){
        return (
                <PartnersSkeletonLoader />
        )
    }

    //console.log('limit', limit)

    if(!partners?.data?.list.length){

        return <PartnersListEmptyContent />
    }



    return (
        <InfiniteScroll
            pageStart={0}
            loadMore={loadFunc}
            hasMore={hasMore}
            loader={<div className="loader" key={0}>Loading ...</div>}
        >
            <WrapperStyle data-cy={'partner-list'}>
                {
                    partners?.data?.list.filter((item, i) => i < limit).map((item, i) => (
                        <PartnerItem  key={i} data={item}/>
                    ))
                }
            </WrapperStyle>
        </InfiniteScroll>

    )

}


export default PartnersList
