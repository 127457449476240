import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  display: flex;
`;

export const PriceContainer = styled.div`
  flex: 1;
  display: flex;
  margin-left: 10%;
  align-items: flex-end;
  flex-direction: row;
  justify-content: ${props =>
    props.onlyOneItem ? 'flex-end' : 'space-between'};
  padding-bottom: 4px;
`;

export const Line = styled.div`
  top:0;
  position: absolute;
  height: 100%;
  width: 1.5px;
  left: ${props => `${props.left}%`};
  background-color: ${props => props.color};
`;

type TitleContainerProps = {
  onlyOneItem: boolean;
};

export const TitleContainer = styled.div`
  display: flex;
  flex: 1;
  padding-top: 4px;
  margin-left: 10%;
  flex-direction: row;
  justify-content: ${(props: TitleContainerProps) =>
    props.onlyOneItem ? 'flex-end' : 'space-between'};
`;

export const LabelContainer = styled.div`
  display: flex;
  flex: 1;
  max-width: 30%;
`;

export const Wrap = styled.div`
  margin-bottom: 30px;
  position: relative;
`;

export const WrapProgressBar = styled.div`
  position: relative;
`;

type Props = {
  textAlign: string;
  textColor: string;
  fontSize?: number;
};

export const Label = styled.div`
  width: 100%;
  text-align: ${(props: Props) => props.textAlign};
  font-size: ${(props: Props) => (props.fontSize ? props.fontSize : 12)}px;
  color: ${(props: Props) => props.textColor};
`;
