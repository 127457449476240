import React from "react";
import Typography from "shared/Typography";
import Card from "shared/Card";

const EmptyContent = ({
    children,
    variant = "default",
    head = "",
    headVariant = "text",
    cardVariant = "transparent",
    icon,
    isEmpty,
}) => {

    if(!isEmpty) {
        return <>{children}</>
    }


    if(isEmpty && head) {
        return (
            <Card variant={cardVariant} data-cy={'empty-content'}>
                <Typography variant={headVariant}>{head}</Typography>
            </Card>
        )
    }

    return <></>


};

export default EmptyContent;
