import styled, {css} from 'styled-components';
import { Fonts, Colors } from 'common/Theme';
import Helpers from 'common/Theme/Helpers';

export const InputWrapStyle = styled.div`
  position: relative;
  ${Helpers.fullWidth};
  padding-top: 18px;
  
  //margin-bottom
  ${props => props.mb && css`
    margin-bottom: ${Helpers.css.mb[props.mb]};
    `}
  //end margin-bottom
`;

export const InputStyle = styled.input`
  outline: none;
  border: none;
  height: 25px;
  ${Fonts.text};
  color: ${Colors.black};
  ${Helpers.fullWidth};
  padding-bottom: 8px;
  ${(props) =>
      props.isBorder &&
      css`
          border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  `}
`;

export const LabelStyle = styled.label`
  position: absolute;
  left: 0;
  top: 18px;
  pointer-events: none;
  ${Fonts.text};
  color: ${Colors.gray};
  transition: 0.2s ease all;
  ${InputStyle}:focus ~ & {
    top: 0px;
    ${Fonts.textSmall};
  }

  ${(props) =>
      props.active &&
      `
        top:0px;
        ${Fonts.textSmall};
  `}
`;

export const ErrorStyle = styled.div`
  color: ${Colors.red};
   ${Fonts.textSmall};
   margin-top: 5px;
`;