import * as t from './types';
import { Dispatch } from 'redux';
import { promotionService } from "modules/Promotion/services";

export const promoGiftsSync = (config) => async (dispatch: Dispatch) => {

    dispatch({ type: t.FETCH_PROMO_GIFT_START });

    try {
        const data  = await promotionService.fetchPromoGifts(config);
        let payload = data;
        return dispatch({ type: t.FETCH_PROMO_GIFT_SUCCESS, payload });
    } catch (error) {
        dispatch({ type: t.FETCH_PROMO_GIFT_FAILURE, payload: error?.response });
        return  error?.response
    }
};



