import styled from 'styled-components';
import { Helpers } from 'common/Theme';
import { Colors, Fonts } from 'common/Theme';

export const ContainerStyle = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;


export const ButtonContainerStyle = styled.div`
  position: absolute;
  bottom: 0;
  padding: 20px;
  left: 0;
  width: 100%;
`;

export const EmptyDeliveryContainerStyle = styled.div`
  position: absolute;
  bottom: 0;
  padding: 20px;
  left: 0;
  width: 100%;
  background: #fff;
`;

export const MapButtonContainerStyle = styled.div`
  position: absolute;
  bottom: 90px;
  right: 10px;
  z-index: 4
`;

export const MapAddressContainerStyle = styled.div`
  position: absolute;
  top: 20%;
  z-index: 3;
  left:0;
  width: 100%;
  padding: 0 30px;
`;

export const MapCloseContainerStyle = styled.div`
  position: absolute;
  top: 27px;
  z-index: 4;
  left:17px;
`;
export const SuggestContainerStyle= styled.div`
height: 90vh;
padding: 0 15px;
`;

export const CityContainerStyle= styled.div`
  padding: 15px;
  @media (min-width: 768px) {
    columns: 3 auto;
  }
`;

export const CityElementStyle = styled.div`
  cursor: pointer;
  @media (min-width: 768px) {
    &:hover {
      >div {
        color: #6C9F39;
      }
    }
  }
`;

export const MapCenterWrapStyle= styled.div`
position: absolute;
left:50%;
top:50%;
margin-left: -11px;
    margin-top: -25px;
z-index: 2;
`;

export const MapButtonRowStyle= styled.div`
margin-bottom: 10px;
`;

