import styled, {css} from 'styled-components';
import { Fonts, Colors } from 'common/Theme';
import Helpers from 'common/Theme/Helpers';

export const ItemStyle = styled.div`
  ${Helpers.css.flex_no_shrink};
  ${Helpers.css.flex};
  ${Helpers.css.flex_ac};
  ${Helpers.css.cursor_pointer};
  white-space: nowrap;
  margin-right: 8px;
  padding: 0 14px;
  transition: all 0.3s ease 0s;

  //color
  ${props => props.variant === 'primary' && css`
    background: ${Colors.white};
  `}

  ${props => props.variant === 'secondary' && css`
    background: ${Colors.bg};
  `}

  ${props => props.variant === 'tertiary' && css`
    background: ${Colors.green};
    color: #FFFFFF;
  `}
  
  //end color

  //active color
  ${props => props.variant === 'primary' && props.isActive  && css`
    background: ${Colors.grayMid};
    
  `}

  ${props => props.variant === 'secondary' && props.isActive  && css`
    background: ${Colors.grayMid};
    
  `}
  //end active color
  
  //size
  ${props => props.size === 'normal' && css`
    height: 33px;
    border-radius: 15px;
    img{
      border-radius: 15px;
    }
  `}
  ${props => props.size === 'normal' && props.icon && css`
    height: 33px;
    width: 33px;
    ${Helpers.css.flex_hc};
    padding: unset;
    border-radius: 24px;
    img{
      border-radius: 24px;
    }
  `}
  ${props => props.size === 'big'  && css`
    height: 40px;
    border-radius: 24px;
    img{
      border-radius: 24px;
    }
  `}
  ${props => props.size === 'big' && props.icon && css`
    height: 40px;
    width: 40px;
    ${Helpers.css.flex_hc};
    padding: unset;
  `}
  //end size

  //margin-bottom
  ${props => props.mb && css`
      margin-bottom: ${Helpers.css.mb[props.mb]};
  `}
  //end margin-bottom
  
  &.active{
    background: #bfbfbf;
  }
  

  
  &:last-child{
    margin-right: unset;
  }

  &:hover {
    background: #EBEBEB;
  }
  
`;
