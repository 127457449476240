import styled, {css} from 'styled-components';
import { Fonts, Colors } from 'common/Theme';
import Helpers from 'common/Theme/Helpers';
import Link from 'shared/Link';

export const WrapStyle = styled.div`
  position: relative;
  ${props => props.isPadding && css`
    padding: 0 15px;
  `}
`;

export const WrapDesktopStyle = styled.div`
  width: 800px;
  padding: 50px;
  background: #fff;
  position: relative;
`;

export const ContentDesktopStyle = styled.div`
  //max-height: calc(100vh - 130px);
  //position: relative;
  //overflow: auto;
`;

export const CloseWrapStyle = styled.div`
  position: absolute;
  top: -20px;
  right: -20px;
  z-index: 2;
`;
