import {useCallback} from 'react'
import moment from 'moment';



const useDate = () => {
    const getTime = useCallback((date: number) => moment.unix(date).format('DD.MM.YYYY HH:mm'), [])

    const getDate = useCallback((date: number) => moment.unix(date).format('DD.MM.YYYY'), [])

    return {
        getTime,
        getDate
    }
}

export default useDate