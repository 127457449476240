import styled, {css} from 'styled-components';
import { Fonts, Colors } from 'common/Theme';
import Helpers from 'common/Theme/Helpers';

export const WrapperStyle = styled.div`
    background: #fff;
    ${Helpers.css.flex};
    ${Helpers.css.flex_ac};
    padding-left: 16px;
    overflow: auto;

    @media (min-width: 768px) {
      scroll-behavior: smooth;
        margin: auto;
      padding-left: 0;
      ${(props) =>
          props.mb &&
          css`
            margin-bottom: ${Helpers.css.mb[props.mb]};
        `}
    }
    ${(props) =>
        props.mb &&
        css`
            margin-bottom: ${Helpers.css.mb[props.mb]};
        `}
`;
