import React from 'react';
import Icon from "shared/Icon";
import Typography from "shared/Typography";
import PointPrefix from "shared/PointPrefix";
import combineLink from "helper/combineLink";
import Cart from "modules/Cart";
import { declensions } from 'helper/declension';
import {links} from "helper";
import { useRouter } from 'next/router'
import {
    ContainerStyle,
    CenterStyle,
    LabelWrapStyle,
    CartIconWrapStyle,
    InfoStyle,
    IconWrapStyle,
    TotalPriceStyle,
} from "./styles";
import {useCurrecny} from "modules/Partner/hooks/useCurrency";


const CartButton = ({to, partnerOpenId, place}) => {
    const {cart} = Cart.hooks.useCart()
    const currency = useCurrecny('cart', cart)

    const router = useRouter()
    const navigation = {
        navigate: (path, query) => router.push({
            pathname: path,
            query: {...query}
        })
    }

    const handlePress = () => {
        const partner = cart.data.partner
        if (to && partner && partner.id) {
            const url = combineLink(to, partner.alias)
            navigation.navigate(url)
        } else {
            navigation.navigate(links.abs.cart)
        }
    }

    let count = 0
    cart.data.cartItems && cart.data.cartItems.filter(item => item.type !== 2).forEach(item => count += item.quantity)

    if (!cart.data.cartItems || !cart.data.cartItems.length || (place !== 'restorany' && partnerOpenId !== cart.data.partner?.alias)) {
        return <></>
    }

    return (
        <ContainerStyle onClick={handlePress} data-cy='cart-button'>
            <div>
                <CenterStyle>
                    <LabelWrapStyle>
                        <CartIconWrapStyle>
                            <Icon name={'CartWhiteIcon'} color={'white'} />
                        </CartIconWrapStyle>
                        <Typography align="left" color="white" component="p" mb="" variant="text" >
                            {to && cart.data.partner ?
                                cart.data.partner?.name
                                : `${count} ${declensions(count, ['товар', 'товара', 'товаров'])}`}
                        </Typography>
                    </LabelWrapStyle>
                    <InfoStyle>
                        <TotalPriceStyle>
                            <Typography align="left" color="white" component="p" isBold mb="" variant="text" >
                                <PointPrefix mb=""  currency={currency} >
                                    {cart.data.total}
                                </PointPrefix>
                            </Typography>
                        </TotalPriceStyle>
                        <IconWrapStyle>
                            <Icon name={'ArrowLeftIcon'} color={'white'} />
                        </IconWrapStyle>
                    </InfoStyle>
                </CenterStyle>
            </div>
        </ContainerStyle>
    );
}



export default CartButton