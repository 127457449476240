import styled from 'styled-components';
import Colors from 'common/Theme/Colors';
import Helpers from 'common/Theme/Helpers';

export const ContainerStyle = styled.div`
  width: 100%;
  height: 90px;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 3;
  background: ${Colors.green};
  padding: 25px 15px 0;
`;

export const CenterStyle = styled.div`
  width: 100%;
  ${Helpers.css.flex}
  ${Helpers.css.flex_ac}
  border-radius: 14px;
`;

export const LabelWrapStyle = styled.div`
  ${Helpers.css.flex}
  ${Helpers.css.flex_ac}
`;

export const CartIconWrapStyle = styled.div`
  margin-right: 7px;
`;

export const InfoStyle = styled.div`
  margin-left: auto;
  ${Helpers.css.flex}
  ${Helpers.css.flex_ab}
`;

export const IconWrapStyle = styled.div`
  transform: rotate(180deg);
`;

export const TotalPriceStyle = styled.div`
  margin-right: 7px;
`;