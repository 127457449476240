import React from 'react';
import {TextStyle} from "./styles";
import truncate from 'lodash/truncate'

const getTruncateText = (text, length = 30, omission = "...") => {
    const res: string = truncate(text, {
        length: length,
        omission: omission,
    });

    return res
}

const Typography = ({
                        children,
                        variant = 'text',
                        color = 'black',
                        component = 'div',
                        isBold,
                        isFullWidth,
                        isItalic,
                        isUnderline,
                        align = 'left',
                        mb,
                        mt,
                        maxLength,
                        ...rest
                  }) => {



    return (
        <>
            <TextStyle
                color={color}
                isFullWidth={isFullWidth}
                variant={variant}
                as={component}
                align={align}
                isBold={isBold}
                isItalic={isItalic}
                isUnderline={isUnderline}
                mb={mb}
                mt={mt}
                data-cy={'typography'}
                {...rest}
            >
                {
                    (maxLength && typeof children === 'string') ? getTruncateText(children, maxLength) : children
                }
            </TextStyle>
        </>
    );
};

export default Typography;
