import React from 'react';
import {ItemStyle, ItemBigStyle, ItemWrapStyle, ImageWrap, IconWrap, OfferWrap} from "./styles";
import ImageUrl from "shared/ImageUrl";
import Icon from "shared/Icon";
import Typography from "shared/Typography";

const PromotionItem = ({
                       data,
                       variant,
                       mb,
                        ...rest
                  }) => {

    const primary = (
        <>
            <ItemStyle
                mb={mb}
                {...rest}
            >
                <ImageWrap variant={variant}><ImageUrl isHasPlaceholder src={data.img}></ImageUrl></ImageWrap>
            </ItemStyle>
        </>
    )

    const secondary = (
        <>
            <ItemBigStyle
                mb={mb}
                {...rest}
            >
                <ImageWrap variant={variant}><ImageUrl isHasPlaceholder src={data.img}></ImageUrl></ImageWrap>
            </ItemBigStyle>
        </>
    )

    const tertiary = (
        <>
            <ItemWrapStyle
                mb={mb}
                {...rest}
            >
                <IconWrap><Icon name='MenuIcon'></Icon></IconWrap>
                <OfferWrap>
                    <Typography variant="text" isBold mb="0.5">{data.head}</Typography>
                    <Typography variant="text" color='gray'>{data.desc}</Typography>
                </OfferWrap>
            </ItemWrapStyle>
        </>
    )

    return (
        <>
            {variant === 'primary' && primary}
            {variant === 'secondary' && secondary}
            {variant === 'tertiary' && tertiary}
        </>
    );
};

export default PromotionItem;
