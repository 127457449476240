import React from 'react';
import {ItemStyle } from "./styles";
import Typography from "shared/Typography";
import Icon from "shared/Icon";
import Link from "shared/Link";

const CategoryItem = ({
                         name,
                         isActive,
                         onClick,
                         href,
                         icon,
                         iconSize,
                         mb,
                         size = "normal",
                         variant = 'primary',
                         ...rest
                     }) => {

    const iconItem = (
        <>
            <Icon size={iconSize} color={variant === 'tertiary' ? 'white' : 'black'} name={icon} ></Icon>
        </>
    )

    const textItem = (
        <>
            <Typography variant="textBig" >{name}</Typography>
        </>
    )


    if(href) {
        return (
            <Link  href={href} data-cy={'category-item'} scroll={false}>
                <ItemStyle
                    as={'a'}
                    mb={mb}
                    href={href}
                    size={size}
                    variant={variant}
                    isActive={isActive}
                    icon={icon}
                    onClick={onClick}
                    data-cy='category-item'
                    {...rest}
                >
                    { icon && iconItem }
                    { name && textItem }
                </ItemStyle>
            </Link>
        );
    }


    return (
        <>
            <ItemStyle
                mb={mb}
                href={href}
                size={size}
                variant={variant}
                isActive={isActive}
                icon={icon}
                onClick={onClick}
                data-cy='category-item'
                {...rest}
            >
                { icon && iconItem }
                { name && textItem }
            </ItemStyle>
        </>
    );
};

export default CategoryItem;
