import React from 'react';
import {SearchWrapStyle, IconLeftWrapStyle, InputWrapStyle, CloseIconStyle, CancelWrapStyle, AlertWrapStyle} from "./styles";
import Input from "shared/Input/";
import Button from "shared/Button";
import AlertBlock from "shared/AlertBlock";

const AddressSearchForm = ({
                   input,
                   meta,
                   placeholder = "Поиск",
                   id,
                   mb,
                   forwardRef,
                   rightButtonClick = () => {},
                   ...rest
                }) => {


    const handleClearField = () => {
        input.onChange('')
    }



    return (
        <>

            <SearchWrapStyle
                data-cy={'address-search-form'}
                mb={mb}
                {...rest}
            >

                <IconLeftWrapStyle>
                    <Button icon="SearchIcon" size="big" variant="transparent"/>
                </IconLeftWrapStyle>
                <InputWrapStyle>
                    <Input forwardRef={forwardRef} placeholder={placeholder} isBorder={false} input={input} meta={meta} id={id}/>
                </InputWrapStyle>
                <CloseIconStyle>
                    <Button as={'div'} onClick={handleClearField}  icon="CloseCircleIcon" size="normal" variant="transparent"/>
                </CloseIconStyle>
                <CancelWrapStyle>
                    <Button onClick={rightButtonClick} size="normal" variant="transparent">Отменить</Button>
                </CancelWrapStyle>
            </SearchWrapStyle>
        </>
    );
};

export default AddressSearchForm;
