import React from 'react';
import {ItemWrapStyle, LabelStyle, TabCheckBoxStyle, ErrorStyle, InputStyle} from "./styles";

const RadioItem = ({
                   input = {},
                   meta = {},
                   label,
                   id,
                   mb,
                   ...rest
                }) => {


    return (
        <>
            <ItemWrapStyle
                data-cy={'radio-item'}
                mb={mb}
                {...rest}
            >
                <InputStyle
                    type='radio'
                    name={id}
                    id={id}
                    {...input}
                    {...rest}
                />

                <TabCheckBoxStyle/>
                {
                    !!label &&  <LabelStyle active={meta.focus || input.value} for={id}>{label}</LabelStyle>
                }

                {meta.error &&
                meta.touched &&
                <ErrorStyle>
                    {meta.error}
                </ErrorStyle>}
            </ItemWrapStyle>
        </>
    );
};

export default RadioItem;
