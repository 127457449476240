import styled, {css} from 'styled-components';
import { Fonts, Colors } from 'common/Theme';
import Helpers from 'common/Theme/Helpers';

export const ContentWrapStyle = styled.div`
  margin-bottom: -20px;
  ${Helpers.fullWidth};
  ${Helpers.css.flex};
  ${Helpers.css.flex_vertical};
  background: ${Colors.white};
  border-radius: 16px 16px 0 0;
  padding: 12px 0 24px;

  //margin-bottom
  ${props => props.mb && css`
      margin-bottom: ${Helpers.css.mb[props.mb]};
  `}
  //end margin-bottom
`;

export const TitleStyle = styled.div`
  ${Helpers.css.flex};
  ${Helpers.css.flex_hc};
  ${Helpers.fullWidth};

  padding-bottom: 16px;
`;

export const BandStyle = styled.div`
  background: rgba(0, 0, 0, 0.12);
  border-radius: 24px;
  width: 40px;
  height: 4px;
  margin: auto;
  margin-bottom: 10px;
  opacity: 0;
`;

export const ButtonWrapStyle = styled.div`
  //padding: 0 16px;
  margin-top: 20px;
  display: flex;
  gap: 10px;
`;

export const ImgWrapStyle = styled.div`
  margin: auto;
  margin-bottom: 23px;
`;

export const ChildStyle = styled.div`
  margin-bottom: 8px;

  //isChildrenPadding
  ${props => props.isChildrenPadding && css`
    padding: 16px;
  `}
  //end isChildrenPadding

  //isBorder
  ${props => props.isBorder && css`
    border-top: 1px solid rgba(0, 0, 0, 0.04);
  `}
  //end isBorder

`;

export const NoteStyle = styled.div`
  padding: 0 30px;
  margin-bottom: 24px;
`;