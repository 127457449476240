import styled, {css} from 'styled-components';
import { Fonts, Colors } from 'common/Theme';
import Helpers from 'common/Theme/Helpers';

export const WrapStyle = styled.div`
  ${Helpers.css.flex};  
  ${Helpers.css.flex_ac};  
  overflow: auto;
  

  @media (max-width: 768px) {
    margin-bottom: 15px;
  }
`;

export const ElementStyle = styled.div`
  ${Helpers.css.flex_no_shrink};
  svg {
    display: block;
  }
`;