import React from 'react';
import * as S from './styles';
import useFilter from 'modules/Partner/hooks/useFilter'
import useMap from 'modules/Map/hooks/useMap'

const PartnersListEmptyContent = () => {
    const {onResetFilter, isHasFilter} = useFilter()
    const {map} = useMap()
    const isFiltered = isHasFilter()
    console.log('mapData', map)


    if (isFiltered) {
        return (
            <S.EmptyListContentContainer>
                <S.EmptyListText>
                    По выбранному фильтру ничего не найдено
                </S.EmptyListText>

                <S.EmptyListButton onClick={onResetFilter}>
                    <S.EmptyListButtonBG>
                        <S.EmptyListButtonText>Сбросить фильтры</S.EmptyListButtonText>
                    </S.EmptyListButtonBG>
                </S.EmptyListButton>
            </S.EmptyListContentContainer>
        );
    } else {
        return (
            <S.EmptyListContentContainer>
                <S.EmptyListText>
                    По адресу {map.data.addressObj.city}, {map.data.address} ничего не найдено
                </S.EmptyListText>
                <S.EmptyListButtonText>
                    Попробуйте указать другой адрес или перейти в другой раздел
                </S.EmptyListButtonText>
            </S.EmptyListContentContainer>
        );
    }
}

export default PartnersListEmptyContent;
