import styled from 'styled-components';

export const Container = styled.div`
   width: 100%;
   display: flex;
   margin-bottom: 15px;
  @media (min-width: 768px) {
  }
`;

export const Row = styled.div`
    display: flex;
    gap: 5px;
`;

export const Title = styled.span`
   font-size: 14px;
   font-weight: bold;
   width: 60%;
   flex-shrink: 0;
`;

export const ImgWrap = styled.div`
   width: 50px;
   margin-right: 20px;
   flex-shrink: 0;
`;
export const Img = styled.img`
   width: 100%;
`;

export const Description = styled.span`
   font-size: 12px;
   color: grey;
   font-weight: normal;
   margin-top: 10px;
`;
export const RightBlock = styled.div`
   margin-left: auto;
`;

export const Price = styled.div`
`;

