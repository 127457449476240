import styled, {css} from 'styled-components';
import { Fonts, Colors } from 'common/Theme/index';
import Helpers from 'common/Theme/Helpers';

export const WrapperStyle = styled.div`
    padding: 20px 0;
    margin: -20px 0;

  @media (min-width: 768px) {
    gap: 1.7%;
    ${Helpers.css.flex}
    ${Helpers.css.flex_wrap}
  }
`;