import React from 'react';
import {WrapperStyle} from './styles';


const ContentMediaView = ({children, view='desktop'}) => {
    return <WrapperStyle view={view} data-cy={'content-media-view'}>
        {children}
    </WrapperStyle>
};




export default ContentMediaView;
