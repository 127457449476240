import styled, {css} from 'styled-components';
import { Fonts, Colors } from 'common/Theme';
import Helpers from 'common/Theme/Helpers';

export const WrapStyle = styled.div`
  //height: 247px;
  //filter: drop-shadow(2px 0px 10px rgba(0, 0, 0, 0.15));
  box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  display: block;
  ${Helpers.css.flex_no_shrink};

  //margin-bottom
  ${props => props.mb && css`
      margin-bottom: ${Helpers.css.mb[props.mb]};
  `}
  //end margin-bottom

  ${props => props.variant === 'primary' && css`
    ${Helpers.fullWidth};
    margin-bottom: 16px;
    
    &:last-child{
    }

    @media (min-width: 768px) {
      width: 23.7%;
      margin-right: 0;
    }
  `}

  ${props => props.variant === 'secondary' && css`
    width: 252px;
    margin-right: 12px;

    @media (min-width: 768px) {
      width: 18.6%;
      margin-right: 0;
    }

    &:last-child{
      margin-right: unset;
    }
  `}

  ${props => props.isClose && css`
    &:after{
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 5;
    }
  `}

    &:hover {
    img{
      transform: scale(1.05);
      }
    }

  &:active {
    img{
      transform: scale(1);
    }
  }
`;

export const ContentStyle = styled.div`
  background: #FFFFFF;
  border-radius: 16px;
  ${Helpers.css.flex};
  ${Helpers.css.flex_vertical};
  ${Helpers.css.flex_at};
  ${Helpers.css.flex_grow};
  position: relative;
  z-index: 2;
  padding: 12px;
  margin-top: -33px;


  ${props => props.variant === 'primary' && css`
    min-height: 103px;
  `}

  ${props => props.variant === 'secondary' && css`
    min-height: 98px;
  `}
`;

export const ImageWrap = styled.div`
  position: relative;
  ${Helpers.fullWidth};
  z-index: 1;

  ${props => props.variant === 'primary' && css`
    height: 177px;
  `}

  ${props => props.variant === 'secondary' && css`
    height: 140px;

    @media (min-width: 768px) {
      height: 185px;
    }
  `}

  ${props => props.isClosed && css`
    img {
      -webkit-filter: grayscale(100%);
      -moz-filter: grayscale(100%);
      -ms-filter: grayscale(100%);
      -o-filter: grayscale(100%);
      filter: grayscale(100%);
    };
  `};

  img {
    border-radius: 16px;
    transition: all 0.3s ease 0s;
  }

`;

export const TabsStyle = styled.div`
  ${Helpers.css.flex};
  ${Helpers.css.flex_ac};
  margin-top: auto;
  overflow: hidden;
  &::-webkit-scrollbar {
      width: 24px; /* ширина для вертикального скролла */
      height: 4px; /* высота для горизонтального скролла */
  }
  
  /* ползунок скроллбара */
  &::-webkit-scrollbar-thumb {
      background-color: #eeeeee;
      border-radius: 9em;
  }
  
  &::-webkit-scrollbar-thumb:hover {
      background-color: #eeeeee;;
  }
`;

export const TabItemStyle = styled.div`
  ${Helpers.css.flex_no_shrink};
  margin-right: 8px;
`;

export const StickersWrapStyle = styled.div`
  ${Helpers.css.flex};
  ${Helpers.css.flex_reverse};
  ${Helpers.css.flex_ac};
  position: absolute;
  z-index: 3;
  right: 12px;
  ${props => props.variant === 'primary' && css`
    top: 107px;
  `}

  ${props => props.variant === 'secondary' && css`
    top: 69px;
  `}
`;

export const StickerStyle = styled.div`
  margin-left: 8px;
  ${Helpers.css.flex_no_shrink};

`;

export const CloseBlockStyle = styled.div`
  background: #FFFFFF;
  border-radius: 24px;
  height: 33px;
  padding: 0 16px;
  white-space: nowrap;
  ${Helpers.css.flex};
  ${Helpers.css.flex_ac};
  ${Helpers.css.item_horizontal};
  
  z-index: 5;
  ${Helpers.css.flex_no_shrink};

  ${props => props.variant === 'primary' && css`
    top: 56px;
  `}

  ${props => props.variant === 'secondary' && css`
    top: 33px;
  `}
`;

export const StickerLeftStyle = styled.div`
  position: absolute;
  z-index: 3;
  left: 12px;
  ${props => props.variant === 'primary' && css`
    top: 105px;
  `}

  ${props => props.variant === 'secondary' && css`
    top: 67px;
  `}
`;

export const StickerCornerStyle = styled.div`
  position: absolute;
  z-index: 3;
  left: 0;
  top: 0;
  background: ${Colors.yellow};
  height: 38px;
  width: 50px;
  border-bottom-right-radius: 50px;
  padding: 10px 0 0 7px;
`;


export const WrapSmallStyle = styled.div`
  //height: 247px;
  border-radius: 16px;
  display: block;
  overflow: hidden;
  position: relative;
  ${Helpers.css.flex_no_shrink};

  //margin-bottom
  ${props => props.mb && css`
      margin-bottom: ${Helpers.css.mb[props.mb]};
  `}
  //end margin-bottom

  ${props => props.isClose && css`
    &:after{
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 5;
    }
  `}
  
`;

export const ImageWrapSmall = styled.div`
  position: relative;
  ${Helpers.fullWidth};
  z-index: 1;
    height: 150px;
  ${props => props.isClose && css`
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%);
  `}

`;

export const ContentSmallStyle = styled.div`
  background: #FFFFFF;
  ${Helpers.css.flex};
  ${Helpers.css.flex_vertical};
  ${Helpers.css.flex_at};
  ${Helpers.css.flex_grow};
  position: relative;
  z-index: 2;
  padding: 12px;
`;

export const RowtSmallStyle = styled.div`
  ${Helpers.css.flex};
  ${Helpers.css.flex_ac};
  width: 100%;
  margin-bottom: 10px;
`;

export const RowtSmallLeftStyle = styled.div`
`;

export const RowtSmallRightStyle = styled.div`
  margin-left: auto;
`;

export const StickersSmallWrapStyle = styled.div`
  ${Helpers.css.flex};
  ${Helpers.css.flex_reverse};
  ${Helpers.css.flex_ac};
  margin-top: -3px;
`;

export const BorderStyle = styled.div`
  height: 1px;
  background: #DFDFDF;
  width: 100%;
  margin-bottom: 7px;
`;
