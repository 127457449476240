/**
 * This file defines the base application styles.
 *
 * Use it to define generic component styles (e.g. the default text styles, default button styles...).
 */
import Colors from './Colors'

export default {
    button: {
        backgroundColor: Colors.primary,
    },
}
