export const NAME = "map";

export const GEOCODE_BASE_URL = "https://geocode-maps.yandex.ru/1.x/?";

export const API_KEY_GEO_ANDROID = "53ba3fde-19da-433b-8d8b-fab250f26e04";
export const API_KEY_GEO_IOS = "915b7c57-f64d-4d99-910a-1ba079df3f2b";

export const API_KEY_GEO_WEB_MOBILE = "e15f75e5-e4ae-41c8-a789-ed986ba73c09";
export const API_KEY_GEO_WEB_DESKTOP = "efc471a5-0740-4c98-8a8d-57fcf981d898";

export const API_KEY_SUGGEST_WEB_MOBILE =
    "9a17228c-ad83-4ad6-93e0-f8bcc14abab3";
export const API_KEY_SUGGEST_WEB_DESKTOP =
    "9a17228c-ad83-4ad6-93e0-f8bcc14abab3";

const ONE_METER_IN_RAD = 0.00003919625725;
const INITIAL_METERS_SCALE = 50;

export const COORDS_DELTA = ONE_METER_IN_RAD * INITIAL_METERS_SCALE;
export const ZOOM = 19;

export const DEFAULT_MAKHACHKALA_COORDS = {
    latitude: 42.9778,
    longitude: 47.5147,
    latitudeDelta: COORDS_DELTA,
    longitudeDelta: COORDS_DELTA,
};
