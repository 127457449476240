import {useState, useCallback} from "react";
import {useSelector} from "react-redux";
import {useAppDispatch} from "store";
import User from "modules/User";
import Partner from "modules/Partner";
import Cart from "modules/Cart";
import Alert from "shared/Alert";
import {RootState} from "store";
import { userPointsSelector } from 'modules/User/selectors';
import { productInCartByIdSelector, productQuantityInCartByIdSelector, productsForPointSelector } from 'modules/Cart/selectors';
import clone from 'lodash/clone';
import navRoute from "helper/navRoute";
import links from "helper/links";
import savedActionFormData from "helper/savedFormData";
import {useMiniSiteDetect} from "common/context/MiniSiteDetect";
//import YandexMetrika from "modules/YandexMetrika";
import {combineLink} from "helper";



const useCart = (productQuery={}, partner={}, isProductForPoint, page = '', openSlide) => {

    const navigation = navRoute()

    const { id, modifiers, price, pointsPrice } = productQuery;

    const { zoneId } = partner;
    //const myOrders = useSelector((state:RootState) => state.myOrders);
    const dispatch = useAppDispatch();
    //const {partner} = Partner.hooks.usePartnerOpen();
    const {addToCart, removeCartItem, updateItemInCart, clearCart} = Cart.store.cart.actions;
    const {onRemoveDataOrderFromCookies} = savedActionFormData()
    const map = useSelector((state: RootState) => state.map);
    const {getModifiers, getQuantity, menu, productOpen} = Partner.hooks.useMenu();
    const {
        showDeliveryNotAvailableMessage,
        isChangedPartnerId,
        showChoiceOptionsMessage,
        showAuthorizationMessage,
        showNotEnoughPointsMessage,
        useReplacePointsDishMesssage,
        showMinSumForPoint,
        showNotAdress,
        showNotCanDelivered
    } = Partner.hooks.useMessage();
    /*const {
        partner as partnerOpen
    } = Partner.hooks.usePartnerOpen();*/
    const [isLoading, setLoading] = useState(false);
    const userPoints = useSelector(userPointsSelector);
    const {isAuth} = User.hooks.useAuth();
    const productType = isProductForPoint ? 1 : 0;
    const productsForPoint = useSelector(productsForPointSelector);
    const cart = useSelector((state:RootState) => state.cart)
    const productInCart = useSelector(productInCartByIdSelector(id, productType));
    const quantityInCart = useSelector(productQuantityInCartByIdSelector(id, productType));
    const priceProduct = isProductForPoint ? `${pointsPrice} Б` : `${price} ₽`;
    const address = map.data.address
    const isMinisite = useMiniSiteDetect()


    //console.log('cartcart', cart)

    const cartSync = () => {
        dispatch(Cart.store.cart.actions.sync())
    }

    const onFetchCart = () => {
        return Cart.services.cartService.fetchCart();
    }

    const valuesToSendToCart = {
        productId: id,
        type: productType,
        modifiers: modifiers &&(!!modifiers.length ? modifiers : ""),
        zoneId
    }

    const onAddToCart = async() => {

        if(!map.data.addressObj.street || !map.data.addressObj.house) {
            showNotAdress(partner)
            return
        }

        if(!partner.canDelivered) {
            showNotCanDelivered(
                partner,
                map.data.address,
                () => {
                    window.location.href = links.abs.partners;
                },
                () => {
                    navigation.navigate(links.abs.map, {
                        return_url: combineLink(
                            links.abs.partners,
                            partner.alias,
                        ),
                    });
                },
            );
            return;
        }

        const values = {
            ...valuesToSendToCart,
            quantity: 1
        };
        showDeliveryNotAvailableMessage(address, partner);

        const isChangePartner = await isChangedPartnerId(cart, partner);
        if (!isChangePartner) {
            return
        } else {
            onRemoveDataOrderFromCookies()
        }

        if (page !== 'cart') {
            const haveRequiredModifiers = showChoiceOptionsMessage(productQuery, modifiers);
            if (haveRequiredModifiers) return;
        }

        if (isProductForPoint) {
            if (!isAuth) {
                showAuthorizationMessage(partner.id)
                return;
            }
            else if (userPoints < pointsPrice) {
                showNotEnoughPointsMessage(pointsPrice, userPoints)
                return;
            }
        }


        if (isProductForPoint && !!productsForPoint) {
            setLoading(true);
            useReplacePointsDishMesssage(zoneId, productsForPoint.id, values, dispatch, () => setLoading(false));
        } else {
            setLoading(true);
            const data = await dispatch(addToCart(values))
            setLoading(false)
            if(data.error){
                Alert.alert(
                    data.message,
                    '',
                    [
                        { text: 'Закрыть', onPress: () => { }, style: 'cancel' },
                    ],
                    { cancelable: false }
                )
            }
;

        }

    }



    const onReducePressed = useCallback((event) => {
        event.preventDefault();
        event.stopPropagation()

        setLoading(true);
        quantityInCart === 1 ?
            dispatch(removeCartItem(productInCart?.id, zoneId)).finally(() => setLoading(false)) :
            dispatch(
                updateItemInCart(productInCart?.id,
                    {
                        zoneId,
                        quantity: - 1
                    })).finally(() => setLoading(false))
    }, [quantityInCart]);



    /*const onAddCartPressed = useCallback((event) =>{
        event.preventDefault();
        event.stopPropagation()
        console.log('cart+, partner', cart, partner)

        modifiers.length ?
            openSlide && openSlide()
            : onAddToCart()

    }, [modifiers, quantityInCart, productQuery])*/

    const onAddCartPressed = (event) =>{
        event.preventDefault();
        event.stopPropagation()
        //console.log('cart+, partner', cart, partner)

        modifiers.length ?
            openSlide && openSlide()
            : onAddToCart()

    }


    const onRemoveCartItem = async(id, callback) => {


        await dispatch(removeCartItem(id))
        callback && callback()
    }


    const onClearCart = useCallback(() => {
        Alert.alert(
            'Вы уверены, что хотите очистить корзину?',
            '',
            [
                { text: 'Да', onPress: () => {
                    onRemoveDataOrderFromCookies()
                    dispatch(clearCart())
                }},
                { text: 'Отмена', onPress: () => { }, style: 'cancel' },
            ],
            { cancelable: false }
        );
    },[dispatch, clearCart]);

    const onSubmitCartForm = async (val, slideClose, cartItemId) => {
        const values = clone(val);
        if(!map.data.addressObj.street || !map.data.addressObj.house) {
            showNotAdress(partner)
            return
        }

        if(!partner.canDelivered) {
            showNotCanDelivered(
                partner,
                map.data.address,
                () => {
                    window.location.href = links.abs.partners;
                },
                () => {
                    navigation.navigate(links.abs.map, {
                        return_url: combineLink(
                            links.abs.partners,
                            partner.alias,
                        ),
                    });
                },
            );
            return;
        }


        showDeliveryNotAvailableMessage(address, partner);

        //console.log('cart, productOpen.partner', cart, productOpen.partner)

        const isChangePartner = await isChangedPartnerId(cart, productQuery.partner);
        if (!isChangePartner) return;

        const modifiers = getModifiers(values);
        const quantity = getQuantity(page, cart, values);

        if (page !== 'cart') {
            const haveRequiredModifiers = showChoiceOptionsMessage(productOpen, modifiers);
            if (haveRequiredModifiers) return;
        }


        const productForPointFromCart = cart.data.cartItems?.find((item) => item.type === 1);
        const productForPoint = values.type === 1;
        const isPoint =
            !!productForPointFromCart &&
            !!productForPoint &&
            menu.data.goodsForPoints.find((item) => item.id === values.productId && values.type === 1);

        if (productForPoint) {
            if (!isAuth) {
                showAuthorizationMessage(partner.id)
                return;
            }
            else if (userPoints < pointsPrice) {
                showNotEnoughPointsMessage(pointsPrice, userPoints)
                return;
            }
        }

        const product = {
            ...values,
            modifiers,
            quantity,
        };

        if (isPoint) {
            const zoneId = partner.data.zoneId;
            const productIdInCart = productForPointFromCart.id;
            useReplacePointsDishMesssage(zoneId, productIdInCart, product, dispatch, slideClose);
        } else {
            setLoading(true);

            page === 'cart' ?
                dispatch(updateItemInCart(productOpen.cartItemId, product)).then(() => {slideClose(); setLoading(false)}) :
                dispatch(addToCart(product)).then(() => {slideClose(); setLoading(false)});

        }
    };


    const onGoOrder = useCallback(() => {
        const { minSum, minSumFoodPoints, alias } = cart.data.partner
        const { total, cartItems } = cart.data

        const isProductForPoint = cartItems?.some((product) => product.type === 1);
        const checkMinSum = isProductForPoint ? minSumFoodPoints : minSum;

        if (isAuth || isMinisite) {
            if (total && checkMinSum <= total) {
                navigation.navigate(links.abs.order);
            } else {
                showMinSumForPoint(isProductForPoint, checkMinSum, alias)
            }
        } else if(total && checkMinSum > total){
            showMinSumForPoint(isProductForPoint, checkMinSum, alias)
        } else {
            navigation.navigate(links.abs.authRequest, { place: 'cart' });
        }
    },[partner, cart, isAuth, navigation]);

    const checkIfGiftAddToCart = useCallback((cartItems, gift) => {


        if (!!gift?.length) {
            //console.log('cartItems', cart)

            /*const hasGiftInCart = cartItems.find(({ product }) => {
                return product?.id === Number(gift[0].goods[0].id);
            });

         /!*if(!hasGiftInCart){
            dispatch(addToCart({
                productId: Number(gift[0].goods[0].id),
                quantity: 1,
                type: 3,
                modifiers: "",
                zoneId: cart.data.partner?.zoneId,
            }));
         }*!/*/


        gift.map((item) => {
            const hasGiftInCart = cartItems.find(({ product }) => {
                return product?.id === Number(item.goods[0].id);
            });
            !!!hasGiftInCart &&
            dispatch(addToCart({
                productId: Number(item.goods[0].id),
                quantity: 1,
                type: 3,
                modifiers: "",
                zoneId: cart.data.partner?.zoneId,
            }));
        });
        }
    },[cart, addToCart, dispatch]);

    const isHasCart = Boolean(cart.data.cartItems?.length)

    const onToCart = useCallback(async (values) => {
        values.modifiers = values.modifiers ? values.modifiers.join(',') : '';


        const data = await dispatch(addToCart(values));
        //setLoading(false)
        if(data.error){
            Alert.alert(
                data.message,
                '',
                [
                    { text: 'Закрыть', onPress: () => { }, style: 'cancel' },
                ],
                { cancelable: false }
            )
        }

    }, [dispatch, addToCart]); //TODO объединить в одну функцию


    return {
        onReducePressed,
        onAddCartPressed,
        isLoading,
        priceProduct,
        quantityInCart,
        cart,
        onSubmitCartForm,
        cartSync,
        onRemoveCartItem,
        onClearCart,
        onGoOrder,
        checkIfGiftAddToCart,
        isHasCart,
        onToCart
    }
}

export default useCart
