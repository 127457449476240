import React from 'react';
import {WrapStyle} from "./styles";
import Button from "shared/Button";

const ButtonLeft = (props) => {

    return (
        <WrapStyle data-cy={'button-left'}>
            <Button variant={'secondary'} {...props} icon={'ArrowLeftIcon'}/>
        </WrapStyle>
    );
};

export default ButtonLeft;
