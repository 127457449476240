import styled, {css} from 'styled-components';
import { Fonts, Colors } from 'common/Theme';
import Helpers from 'common/Theme/Helpers';

export const WrapperStyle = styled.div`
  //margin-bottom
  ${props => props.mb && css`
      margin-bottom: ${Helpers.css.mb[props.mb]};
  `}
  //end margin-bottom
  
`;