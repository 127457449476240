export default {
    h1: {
        fontSize: 32,
        lineHeight: '38px'
    },
    h2: {
        fontSize: 20,
        lineHeight: '21px'
    },
    h3: {
        fontSize: 16,
        lineHeight: '19px'
    },
    h4: {
        fontSize: 14,
        lineHeight: '17px'
    },
    h5: {
        fontSize: 12,
        lineHeight: '14px'
    },
    text: {
        fontSize: 14,
        lineHeight: '17px'
    },
    textSmall: {
        fontSize: 12,
        lineHeight: '14px'
    },
    textBig: {
        fontSize: 16,
        lineHeight: '19px'
    },
    textExtraBig: {
        fontSize: 18,
        lineHeight: '22px'
    },
    input: {
        fontSize: 16,
        lineHeight: '19px'
    },
    regular: 17,
    medium: 14,
    small: 12,

    desktop: {
        h1: {
            fontSize: 42,
            lineHeight: '48px'
        },
        h2: {
            fontSize: 28,
            lineHeight: '30px'
        },
        h3: {
            fontSize: 20,
            lineHeight: '25px'
        },
        h4: {
            fontSize: 16,
            lineHeight: '20px'
        },
        h5: {
            fontSize: 14,
            lineHeight: '16px'
        },
        text: {
            fontSize: 16,
            lineHeight: '19px'
        },
        textSmall: {
            fontSize: 14,
            lineHeight: '16px'
        },
        textBig: {
            fontSize: 18,
            lineHeight: '21px'
        },
        textExtraBig: {
            fontSize: 22,
            lineHeight: '28px'
        },
        input: {
            fontSize: 16,
            lineHeight: '19px'
        },
        regular: 16,
        medium: 16,
        small: 14,
    }
}
