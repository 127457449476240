import React, {lazy, Suspense} from 'react';
import Icon from "shared/Icon";
import Typography from "shared/Typography";
import PointPrefix from "shared/PointPrefix";
import Cart from "modules/Cart";
import {
    ContainerStyle,
    CenterStyle,
    LabelWrapStyle,
    CartIconWrapStyle,
    InfoStyle,
    IconWrapStyle,
    TotalPriceStyle,
} from "./styles";
import SlidePanel, {useSlidePanel} from "shared/SlidePanel";
import {useCurrecny} from "modules/Partner/hooks/useCurrency";

const CartContent = lazy(() => import("../CartContent"))

const CartDesktopButton = ({to, partnerOpenId, place}) => {
    const {cart, isHasCart} = Cart.hooks.useCart()
    const [actions, setActions] = useSlidePanel()
    const currecny = useCurrecny('cart', cart)

    /*const router = useRouter()
    const navigation = {
        navigate: (path, query) => router.push({
            pathname: path,
            query: {...query}
        })
    }*/

    const handlePress = () => {
        actions.onOpen()

        /*const partner = cart.data.partner
        if (to && partner && partner.id) {
            navigation.navigate(combineLink(to, partner.id))
        } else {
            navigation.navigate(links.abs.cart)
        }*/
    }

    const handleClose = () => {
        actions.onClose()

        /*const partner = cart.data.partner
        if (to && partner && partner.id) {
            navigation.navigate(combineLink(to, partner.id))
        } else {
            navigation.navigate(links.abs.cart)
        }*/
    }

    let count = 0
    cart.data.cartItems && cart.data.cartItems.filter(item => item.type !== 2).forEach(item => count += item.quantity)



    return (
        <ContainerStyle isHasCart={isHasCart} onClick={handlePress} data-cy='cart-button'>
                <CenterStyle>

                    <LabelWrapStyle>
                        <CartIconWrapStyle>
                            <Icon
                                width={20}
                                height={20}
                                name={'CartWhiteIcon'}
                                color= {isHasCart ? "white" : "black"} />
                        </CartIconWrapStyle>
                    </LabelWrapStyle>
                    <InfoStyle>
                        <TotalPriceStyle>
                            <Typography
                                align="left"
                                color= {isHasCart ? "white" : "black"}
                                isBold
                                mb=""
                                variant="textBig"
                            >
                                {
                                    isHasCart ? (
                                        <PointPrefix mb="" currency={currecny}>
                                            {cart.data.total}
                                        </PointPrefix>
                                    ) : (
                                        <>
                                            Корзина
                                        </>
                                    )
                                }
                            </Typography>
                        </TotalPriceStyle>
                    </InfoStyle>
                </CenterStyle>

            <SlidePanel initSheet={setActions} closeOnDocumentClick={false}>
                <Suspense fallback={<div>Loading</div>}>
                    <CartContent onCloseCartPop={handleClose}/>
                </Suspense>
            </SlidePanel>
        </ContainerStyle>
    );
}


export default CartDesktopButton