import Icon from 'shared/Icon';
import React, { useEffect, useRef } from 'react';
import * as S from './styles';

type Props = {
  onCancelPressed: () => void,
  onChange: (text: string) => void,
  value: string,
  clearInput: () => void,
};

const AddressFieldComponent = (props: Props) => {
  const { onCancelPressed, onChange, value, clearInput } = props;

  const inputRef = useRef(null);

  //  из-за проблем с появлением клавиатуры в модалке на андроиде autofocus реализован так
  useEffect(() => {
      setTimeout(() => inputRef.current?.focus(), 500);
  },[])

  return (
    <S.Container
        data-cy={'address-field-component'}>
      <Icon name="search" />
      <S.InputStyle
          id="suggest"
          ref={inputRef}
          placeholder="Поиск"
          value={value}
          onChange={e => onChange(e.currentTarget.value)}
      />
      <S.Right>
        <div onClick={clearInput}>
          <S.BtnClear>
            <Icon name="clear" />
          </S.BtnClear>
        </div>
      </S.Right>
    </S.Container>
  );
};

export default AddressFieldComponent;
