import {useCallback, useState} from 'react'
import {useAppDispatch} from 'store'
import Bonus from "modules/Bonus";
import {useSelector} from "react-redux";



const useBonus = () => {
    const bonus = useSelector((state) => state.bonus);
    const dispatch = useAppDispatch();

    const onBonusUserSync = useCallback(() => {
        dispatch(Bonus.store.bonus.actions.bonusUserSync())
    }, [])

    return {
        bonus,
        onBonusUserSync
    }
}

export default useBonus