import styled, {css} from 'styled-components';
import { Fonts, Colors } from 'common/Theme';
import Helpers from 'common/Theme/Helpers';

export const CounterStyle = styled.div`
  ${Helpers.css.flex_inline};
  ${Helpers.css.flex_ac};
  ${Helpers.fullWidth};
  background: ${Colors.white};
  border: 0.5px solid rgba(118, 118, 118, 0.3);
  border-radius: 14px;
  padding: 5px;
  //size
  ${props => props.type === 'primary' && css`
    height: 40px;
  `}
  ${props => props.type === 'secondary' && css`
    height: 33px;
  `}
  //end size
  
  ${props => props.variant === 'secondary' && css`
    border: 0;
    padding: 0;
    height: auto;
  `}

  //margin-bottom
  ${props => props.mb && css`
      margin-bottom: ${Helpers.css.mb[props.mb]};
  `}
  //end margin-bottom
`;

export const BtnStyle = styled.div`

  color: ${Colors.black};
  ${Helpers.css.flex};
  ${Helpers.css.flex_center};
  ${Helpers.css.cursor_pointer};

  border-radius: 13px;

  //size
  ${props => props.type === 'primary' && css`
    width: 40px;
    height: 40px;
    ${Fonts.textBig};
  `}
  ${props => props.type === 'secondary' && css`
    width: 35px;
    height: 35px;
    font-size: 20px;
    
     ${props => props.size === 'small' && css`
      width: 30px;
      height: 30px;
      font-size: 16px;
    `}
    
  `}
  ${props => props.variant === 'primary' && css`
    color: ${Colors.black};
  `}
  
  ${props => props.variant === 'secondary' && css`
    background: ${Colors.green};
    color: ${Colors.white};
  `}
  //end color
`;

export const ValueStyle = styled.div`
  ${Fonts.text};
  color: ${Colors.black};
  ${Helpers.css.flex_inline};
  ${Helpers.css.flex_center};
  ${Helpers.css.flex_grow};
  min-width: 57px;
  height: 100%;

 ${props => props.size === 'small' && css`
    min-width: 40px;
  `}

  ${props => props.variant === 'primary' && css`
    border-right: 0.5px solid #DFDFDF;
    border-left: 0.5px solid #DFDFDF;
  `}
  ${props => props.variant === 'secondary' && css`
  `}
`;
