import { createSelector } from 'reselect';

const mapSelector = (state) => state.map;

export const mapDataSelector = createSelector(
    mapSelector,
    (map) => map.data
);

export const mapDataCitiesSelector = createSelector(
    mapDataSelector,
    ({ cities }) => cities
);

export const addressSelector = createSelector(
    mapDataSelector,
    (data) => data.address
);

export const coordsSelector = createSelector(
    mapDataSelector,
    (data) => [data.latitude, data.longitude]
);

export const hasDeliverySelector = createSelector(
    mapSelector,
    ({ hasDelivery }) => hasDelivery
);
export const getDeepLink = createSelector(
    mapSelector,
    ({ deeplink }) => deeplink
);

export const addressObjSelector = createSelector(
    mapDataSelector,
    (data) => data.addressObj
);

export const cityIdSelector = createSelector(
    addressObjSelector,
    (addressObj) => addressObj.cityId
);

export const addressesListSelector = createSelector(
    mapSelector,
    (data) => data.addresses
);

export const selectedAddressIdSelector = createSelector(
    mapSelector,
    (data) => data.selectedAddressId
);
