import React from 'react';
import ContentLoader from 'react-content-loader';
import {WrapStyle, ElementStyle} from "./styles";
import {useMobileContextDetect} from "shared/DeviceDetect";

const Element = () => {
    const isMobile = useMobileContextDetect();
    const height = isMobile ? 40 : 42
    let itemW = Math.random() * (80 - 110) + 110
    return (
        <ElementStyle>
            <ContentLoader
                data-cy={'tabs-skeleton-loader'}
                backgroundColor="#d9d9d9"
                animate
                speed={0.8}
                //viewBox={`0 0 ${itemW + 6} + 13} `}
                height={height}
                width={itemW + 6}
            >
                <rect x="0" y="0" rx="20" ry="20" width={itemW} height="100%" />
            </ContentLoader>
        </ElementStyle>
    );
};
const TabsSkeletonLoader = () => (
    <>
        <WrapStyle>
            {
              Array.from({length: 10}).map((val, index) => (
                  <Element key={index} />
              ))
            }
        </WrapStyle>
    </>
);

export default React.memo(TabsSkeletonLoader)
