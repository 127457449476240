import styled, {css} from 'styled-components';
import { Fonts, Colors } from 'common/Theme';
import Helpers from 'common/Theme/Helpers';

export const WrapStyle = styled.div`
  ${Helpers.css.flex};
  ${Helpers.css.flex_ac};
  ${Helpers.css.flex_wrap};
  ${Helpers.css.flex_sb};
  gap: 16px
`;

export const ElementStyle = styled.div`
  width: 24%;
  
  ${props => props.isMobile && `
    width: 47%;
  `}
`;