import React, {useEffect, useRef} from 'react';
import useMenu from '../../hooks/useMenu';
import { Link as LinkScroll, Events } from 'react-scroll'
import CategoryItem from "shared/Category/components/СategorytItem";
import { useMobileContextDetect} from "shared/DeviceDetect";
import {BurgerWrap, Container, ContainerStick, Wrap} from "./styles";
import CaruselWrap from "shared/Carusel/components/CaruselWrap";
import SlidePanel from "shared/SlidePanel/SlidePanel";
import {useSlidePanel} from "shared/SlidePanel";
import Button from "shared/Button";
import MenuModal from "modules/Partner/components/MenuModal";

let isClick = false

const DirectionsList = ({}) => {
    const {options, menu, resolveMenuItems} = useMenu()
    const scrollContainer = useRef()
    const isMobile = useMobileContextDetect();
    const [actions, setActions] = useSlidePanel()
    const menuFormated = resolveMenuItems(menu.data)
    const optionsWithSub = menuFormated?.map(({ name, id: uniqId, subMenu, products }: any, index: any) => ({
        id: index,
        name,
        uniqId,
        subMenu,
        products,
    })) || [];



    const handleSetActive = (id) => {
        if(!isClick){
            const el = document.querySelector(`[data-id = ${id}]`)
            el.scrollIntoView({behavior: "auto",block: 'nearest', inline: 'start'});
        }
    }

    const handleSetInActive = (id) => {
        isClick = false
    }

    useEffect(() => {
        Events.scrollEvent.register('begin', function(to, element) {
            isClick = true
            //actions?.onClose()
        });
        Events.scrollEvent.register('end', function(to, element) {
            isClick = false
        });
    }, [])


    return (
        <ContainerStick data-cy={'direction-list'}>
            <Wrap>
                {
                    isMobile && (
                        <BurgerWrap>
                            <Button onClick={actions.onOpen} variant='transparent' size='normal' icon='MenuIcon'></Button>
                        </BurgerWrap>
                    )
                }


                <CaruselWrap wrapRef={scrollContainer}>
                    <Container ref={scrollContainer} className={'eferferferf'}>
                        {
                            options?.map((item) =>
                                <CategoryItem
                                    key={item.id}
                                    data-id={`block${item.uniqId}`}
                                    activeClass="active"
                                    to={`block${item.uniqId}`}
                                    spy={true}
                                    //smooth={true}
                                    offset={isMobile ? -70 : -150}
                                    //duration={500}
                                    as={LinkScroll}
                                    size={'big'}
                                    variant={'secondary'}
                                    name={item.name}
                                    onSetActive={handleSetActive}
                                    onSetInActive={handleSetInActive}
                                    //isActive={getActive(item)}
                                />
                            )
                        }

                        {
                            isMobile && (
                                <SlidePanel
                                    onDismiss={actions.onClose}
                                    initSheet={setActions}
                                    isPadding={true}
                                >
                                    <MenuModal menu={optionsWithSub} onClose={actions?.onClose}/>
                                </SlidePanel>
                            )
                        }

                    </Container>
                </CaruselWrap>
            </Wrap>


        </ContainerStick>

    )

}


export default DirectionsList
