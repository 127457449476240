import qs from 'query-string';
import { API_KEY_GEO_ANDROID, API_KEY_GEO_IOS, GEOCODE_BASE_URL } from '../constans';

/**
 * @example requestGeocoding('44.8,44.7') - lat, lon
 * @param geocode
 * @returns any
 * https://yandex.ru/dev/maps/geocoder/doc/desc/reference/response_structure.html
 */

//const API_KEY_GEO = isIosPlatform() ? API_KEY_GEO_IOS : API_KEY_GEO_ANDROID;
const API_KEY_GEO = API_KEY_GEO_ANDROID


export const requestGeocoding = (geocode: string): any => {
  const query = { geocode, format: 'json', apikey: API_KEY_GEO };
  const url = GEOCODE_BASE_URL + qs.stringify(query);
  return fetch(url).then((response) => response.json());
};
