import React, {useEffect, useRef} from 'react';
import  usePartnerNew from '../../hooks/usePartnerNew';
import  links from 'helper/links';
import  PartnerItem from '../PartnerItem';
import {WrapperStyle, RowStyle, WrapperSkeletonStyle} from "./styles";
import Card from "shared/Card";
import Typography from "shared/Typography";
import EmptyContent from "shared/EmptyContent";
import ContentMediaView from "shared/ContentMediaView";
import DeviceDetect, {useMobileContextDetect} from "shared/DeviceDetect";
import Button from "shared/Button";
import Link from "shared/Link";
import CaruselWrap from "shared/Carusel/components/CaruselWrap";
import PartnersSkeletonLoader from "modules/Partner/components/PartnersSkeletonLoader";
import HeadSkeletonLoader from "shared/skeleton/HeadSkeletonLoader";


const PartnersNewList = ({data,
                             mb,
                             variant = 'secondary',
                             ...rest}) => {
    const {partnersNew, partnersNewSync} = usePartnerNew()
    const scrollContainer = useRef()
    const isMobile = useMobileContextDetect();

    useEffect(() => {
        if(!partnersNew?.data?.list?.length){
            partnersNewSync()
        }
    },[])


    if(partnersNew.isLoading){
        return (
            <Card variant="secondary"  mb={isMobile ? 1 : 5}>
                <WrapperSkeletonStyle>
                    <ContentMediaView>
                        <HeadSkeletonLoader mb={2} />
                        <PartnersSkeletonLoader isNewsPartner />
                    </ContentMediaView>
                </WrapperSkeletonStyle>
            </Card>

        )
    }

    return (
        <EmptyContent isEmpty={!partnersNew.data?.list?.length} data-cy={'partners-new-list'}>
            <Card variant="secondary">
                <ContentMediaView>
                    <RowStyle>
                        <Typography
                            color="black"
                            variant={isMobile ? "h2" : "h1"}
                            mb={isMobile ? 0.5 : 1}
                            style={{
                                marginTop: '5px'
                            }}
                        >
                            Новые рестораны
                        </Typography>

                        <DeviceDetect device={'desktop'}>
                            <Link href={links.abs.partners} passHref>
                                <Button
                                    as={'a'}
                                    variant={'more'}
                                    icon={"ArrowRightLongIcon"}
                                    iconPosition={"right"}
                                >
                                    <Typography variant='textSmall' >Все рестораны</Typography>
                                </Button>
                            </Link>
                        </DeviceDetect>
                    </RowStyle>
                </ContentMediaView>
            </Card>
            <Card variant="secondary" isPadding={false}>
                <ContentMediaView>
                    <CaruselWrap data-cy={'new-partners'} wrapRef={scrollContainer}>
                        <WrapperStyle ref={scrollContainer} variant={variant} mb={mb} {...rest}>
                            {partnersNew.data?.list?.map((item, i) => (
                                <PartnerItem  variant={'secondary'} key={i} data={item}/>
                            ))}
                        </WrapperStyle>
                    </CaruselWrap>
                </ContentMediaView>
            </Card>
        </EmptyContent>
    );
}


export default PartnersNewList
