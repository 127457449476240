import styled from 'styled-components';
import { Helpers } from 'common/Theme';

export const Container = styled.div`
  width: 100%;
`;

export const Bottom = styled.div`
  display: flex;
  margin-vertical: 2%;
`;

export const BottomLeft = styled.div`
  margin-right: 16px;
`;

export const BottomRight = styled.div`
  min-width: 130px;
  width: 100%;
  margin-left: auto;
  flex-shrink: 1;
`;

export const BottomFlex = styled.div`
  margin-bottom: 16px;
  ${Helpers.rowCross}
`;

export const OptionWrap = styled.div`
  margin-bottom: 4px;
`;

export const WrapStyle = styled.div`
  margin: auto;
  max-width: 400px;

  @media (min-width: 768px) {
    ${Helpers.css.flex}
    max-width: none;
  }
`;

export const ImgWrapStyle = styled.div`
  width: 100%;
  height: 300px;
  position: relative;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;

  @media (min-width: 768px) {
    width: 300px;
    ${Helpers.css.flex_no_shrink};
    border-radius: 0;
    margin-right: 20px;
    position: sticky;
    top: 0;
  }
`;

export const BtnWrapStyle = styled.div`
   ${Helpers.css.flex};
   ${Helpers.css.flex_ac};
   margin-bottom: 12px;
   position: relative;
`;
export const BtnCenterStyle = styled.div`
   max-width: 400px;
   margin: auto;
   padding: 0 16px;
   
   @media (min-width: 768px) {
       position: sticky;
    bottom: -20px;
      padding: 10px;
      background: #fff;
  }
`;
export const BtnLeftStyle = styled.div`
  margin-right: 16px;
  
`;
export const BtnRightStyle = styled.div`
  ${Helpers.css.flex_grow};
`;

export const TitleWrapStyle = styled.div`
   ${Helpers.css.flex};
   ${Helpers.css.flex_ac};
   margin-bottom: 16px;
`;

export const TitleLeftStyle = styled.div`
`;

export const TitleRightStyle = styled.div`
  margin-left: auto;
`;

export const ContentStyle = styled.div`
  padding: 16px;
`;

export const OptionWrapStyle = styled.div`
  padding: 0 16px;
`;

export const PriceWrapStyle = styled.div`
  white-space: nowrap;
  padding-left: 5px;
`;

export const BlockStyle = styled.div`
  width: 100%;
`;
