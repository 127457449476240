import styled, {css} from 'styled-components';
import { Fonts, Colors } from 'common/Theme';
import Helpers from 'common/Theme/Helpers';

export const WrapStyle = styled.div`
  min-height: 70px;
  padding: 19px 16px 19px;
  ${Helpers.css.flex};
  ${Helpers.css.flex_at};
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
  cursor: pointer;
  -moz-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  -webkit-transition: all 0.5s linear;
  &:hover {
    background: #eeeeee;
  }
  ${props => props.mb && css`
      margin-bottom: ${Helpers.css.mb[props.mb]};
  `}
`;

export const CountStyle = styled.div`
  width: 35px;
`;

export const ContentStyle = styled.div`
  ${Helpers.css.flex};
  ${Helpers.css.flex_vertical};
  ${Helpers.css.flex_at};
`;

export const PriceStyle = styled.div`
  margin-left: auto;
  width: 80px;
`;

export const GiftIconStyle = styled.div`
  margin-left: auto;
`;