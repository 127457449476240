import React, {useEffect, useCallback} from 'react';
import {useAddress, useCity} from 'modules/Map/hooks';
import List from "shared/List";
import RadioItem from "shared/RadioItem";
import Typography from "shared/Typography";
import Button from "shared/Button";


const AddressList = ({onSelectAddress}) => {
    const {
        address,
        generateAddress,
        goCreateAddress,
        goUpdateAddress,
    } = useAddress();

    const { onGetCityById } = useCity();

    const data = useCallback(address.data.map(item => (
        {
            label:
                <RadioItem name={'address-user'} input={{onChange: () => onSelectAddress(item.id)}} id={item.id} label=
                    {
                        <>
                            <Typography variant='textBig'>{generateAddress(item)}</Typography>
                            <Typography color='gray' variant='textSmall'>{onGetCityById(item.cityId).name}</Typography>
                        </>
                    }
                />,
            value: <Button onClick={() => goUpdateAddress(item.id)} variant={'transparent'} icon='ClipboardIcon' />,
        }
    )), [address])


    useEffect(() => {
        //onSyncAddress()
    }, [])

    return (
        <>
            <List
                isItemBorder
                items={data}
            />
            <br/>
            <Button onClick={goCreateAddress} variant={'transparent'} iconColor={'black'} icon={'PlusClearIcon'}>Добавить новый</Button>
        </>
    )
};

export default AddressList;
