import { findСity } from './findСity';

const getAddressComponents = (geoObjectData) =>
  geoObjectData.response.GeoObjectCollection.featureMember[0].GeoObject.metaDataProperty
    .GeocoderMetaData.Address.Components;

export const getAddress = (geoObjectData, cities) => {
  const addressComponents = getAddressComponents(geoObjectData);

  const getNameByKind = (searchKind) =>
    addressComponents.find(({ kind }) => kind === searchKind)?.name;

  const country = getNameByKind('country');
  const street = getNameByKind('street');
  const house = getNameByKind('house');
  let city = getNameByKind('locality');
  let cityFromArea = getNameByKind('area') ? getNameByKind('area').split(' ') : '';

  //console.log('cityFromArea', cityFromArea)

  cityFromArea = cityFromArea[cityFromArea.length - 1];
  city = findСity(cities, city) ? city : cityFromArea;

  return { country, street, house, city };
};
