import { useState} from "react";
import {
    activateUserPromo,
    activationСode,
} from "modules/Promotion/store/promoCode/actions";
import useCart from "modules/Cart/hooks/useCart";
import Alert from "shared/Alert";
import {useBonus} from "modules/Bonus/hooks";


const usePromoCode = (variant, query = {}) => {
    const [isLoading, setLoading] = useState(false);
    const {cartSync} = useCart();
    const {onBonusUserSync} = useBonus();

    const onSubmit = (values: any) => {
        setLoading(true);
        if (variant === 'profilePromo') {
            activateUserPromo({ ...values, ...query }).then((data) => {
                onBonusUserSync();
                setLoading(false);
                const message = !data
                    ? "Что то пошло не так"
                    : data.code
                        ? data.message
                        : data.status === 204
                            ? "Вы успешно активировали промокод"
                            : data.response.data.message;

                Alert.alert(
                    "",
                    message,
                    [
                        {
                            text: "Закрыть",
                            onPress: () => {},
                            style: "cancel",
                        },
                    ],
                    {
                        cancelable: false,
                    },
                );
            });
        } else {
            activationСode({ ...values, ...query }).then((data) => {
                cartSync();
                setLoading(false);
                const message = !data
                    ? "Что то пошло не так"
                    : data.code
                    ? data.message
                    : data.status === 204
                    ? "Вы успешно активировали промокод"
                    : data.response.data.message;

                Alert.alert(
                    "",
                    message,
                    [
                        {
                            text: "Закрыть",
                            onPress: () => {},
                            style: "cancel",
                        },
                    ],
                    {
                        cancelable: false,
                    },
                );
            });
        }
    };

    /*const activeProfilePromo = (values: any) => {
        setTimeout(() => {
            dispatch(activateUserPromo(values));
            setLoading(false);
            //refreshPage();
        }, 2000);
    };*/

    return {
        isLoading,
        onSubmit,
        //activeProfilePromo,
    };
};

export default usePromoCode;
