import React from 'react';
import {WrapperStyle, ButtonWrapStyle} from "./styles";
import Typography from "shared/Typography";
import Button from "shared/Button";

const MapAddress = ({
                         children,
                         mb,
                         ...rest
                     }) => {


    return (
        <>
            <WrapperStyle
                data-cy={'map-address'}
                mb={mb}
                {...rest}
            >
                <Typography variant="h2" align="center" mb='2.5'>{children}</Typography>
                <ButtonWrapStyle><Button size="normal" href="11" variant='primary' >Изменить адрес доставки</Button></ButtonWrapStyle>

            </WrapperStyle>
        </>
    );
};

export default MapAddress;
