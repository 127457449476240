import styled from 'styled-components';
import { Fonts, Colors } from 'common/Theme';
import Helpers from 'common/Theme/Helpers';

export const Checkbox = styled.input`
  background: #ffffff;
  opacity: 0;
  position:absolute;
  top:0;
  left:0;
  z-index:2;
  &:checked + div:after{
    content: '';
    background: #6C9F39;
    border-radius: 2px;
    width: 7px;
    height: 7px;
  }
  
`;

export const Error = styled.span`
  font-size: 14px;
  color: #b15454;
  margin-bottom: 10px;
`;

export const Label = styled.span`
  color: ${Colors.primary};
  ${Fonts.secondary}
`;

export const RowView = styled.div`
  ${Helpers.css.flex};
  ${Helpers.css.flex_ac};
  ${Helpers.mainSpaceBetween};
  ${({ is100Width }) => is100Width && 'width: 100%'};
  margin-bottom: 10px;
`;

export const RowLabel = styled.span`
  ${Fonts.secondarySmall}
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
`;

export const CheckboxWrap = styled.div`
    position: relative;
`;

export const CheckboxCustom = styled.div`
    width: 13px;
    height: 13px;
    border: 1px solid #6C9F39;
    border-radius: 5px;
    position: relative;
     ${Helpers.css.flex};
     ${Helpers.css.flex_center};
`;

export const LabelWrap = styled.label`
  display: block;
  width: 100%;
`;