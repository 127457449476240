import styled, {css} from 'styled-components';
import { Fonts, Colors } from 'common/Theme';
import Helpers from 'common/Theme/Helpers';

export const WrapperStyle = styled.div`
  ${Helpers.css.flex};
  ${Helpers.css.flex_ac};
  gap: 0 10px;

  ${props => props.variant === 'primary' && css`
    ${Helpers.css.flex_wrap};
  `}

  ${props => props.variant === 'secondary' && css`
    overflow: auto;
    ${Helpers.css.flex_no_shrink};

  `}

  ${props => props.mb && css`
      margin-bottom: ${Helpers.css.mb[props.mb]};
  `}

  @media (min-width: 768px) {
    gap: 2.6%;
  }
`;