import FastImage from 'shared/Image';
import styled from 'styled-components';


export const Container = styled.div`
  padding-vertical: 3%;
`;

export const DiscountElement = styled.div`
  margin-right: 20px;
  display: flex;
  align-items: center;
  overflow: auto;
  width: 250px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #fff;
  padding: 10px;
`;

export const ImageView = styled.div`
  width: 50px;
  height: 50px;
  position: relative;
  background: #6C9F39;
  flex-shrink: 0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Image = styled(FastImage)`
`;

export const DescriptionView = styled.div`
  padding-left: 5%;
  margin-vertical: 2%;
`;

export const Title = styled.span`
  text-align: left;
`;

export const Description = styled.span`
  color: #aaaaaa;
`;

export const ScrollWrap = styled.div`
  overflow: auto;
  display: flex;
`;
