import request from "helper/request";
import combineLink from "helper/combineLink";
import api from "helper/api";

class PromoCodeService {
    activateUserPromo = async (data, config = {}) => {
        const options = {
            setLoading: false,
            withLoader: true,
            body: data,
            ...config,
        };

        return request
            .post(combineLink(api.userPromoCode), options)
            .then((data) => data);
    };
    activationСode = async (data, config = {}) => {
        const options = {
            setLoading: false,
            withLoader: true,
            body: data,
            ...config,
        };


        return request
            .post(combineLink(api.promoCode), options)
            .finally((data) => data);
    };
}

const promoCodeService = new PromoCodeService();
export default promoCodeService;
