import React from 'react';
import Link from 'shared/Link';
import links from 'helper/links';
import combineLink from 'helper/combineLink';
import PartnerImage from 'modules/Partner/components/PartnerImage';

const PartnersElement = ({item}) => {

    return
    <Link
        data-cy={'partners-element'}
        key={item.id}
        href={combineLink(links.abs.partners, "[partnerId]")}
        as={combineLink(links.abs.partners, item.alias)}
    >
        <a className="restaurant-item">
            <div className="restaurant-item__img-wrap">
                <PartnerImage partner={item}/>
            </div>
            <div className="restaurant-item__content">
                <div className="restaurant-item__title">{item.name}</div>
                <div className="rating">
                    <div className="rating-icon"><img src="img/star.svg" alt=""
                                                      className="rating-icon-img"/></div>
                    <div className="rating-value">4.3 Хорошо</div>
                    <div className="rating-quantity">(250+)</div>
                </div>
                <div className="restaurant-item__delivery">Доставка бесплатно от 1500₽</div>
                <div className="restaurant-item__info">
                    <div className="info-item circle"><img src="img/Union.svg" alt="" className="icon"/>
                    </div>
                    <div className="info-item circle"><img src="img/cart.svg" alt="" className="icon"/></div>
                    <div className="info-item">Заказ от {item.minSum}₽</div>
                    <div className="info-item">{item.deliveryDuration}</div>
                </div>
            </div>
            <div className="restaurant-item__gift">
                <div className="icon"><img src="img/gift.svg" alt="" className=""/></div>
                <span>Блюдо в подарок</span></div>
            <div className="restaurant-item__21"></div>
        </a>

    </Link>

}

export default PartnersElement;
