import React, {useState, useCallback} from 'react';
import CategoryItem from "shared/Category/components/СategorytItem";
import useFilter from "modules/Partner/hooks/useFilter";
import usePartner from "modules/Partner/hooks/usePartner";
import {Container, RowStyle} from "./styles";
import BottomSlideContent from "shared/BottomSlideContent";
import Typography from "shared/Typography";
import SlidePanel from "shared/SlidePanel/SlidePanel";
import {useSlidePanel} from "shared/SlidePanel";
import Checkbox from "shared/Checkbox";
import addParamsToUrl from "helper/addParamsToUrl";
import {initialState} from "modules/Partner/store/filters/reducer";

const FilterPartners = ({variant = 'secondary', size='big'}) => {
    const {onChangeFilter, filters, isHasFilter, onResetFilter} = useFilter()
    const {partnersSync} = usePartner()
    const [actions, setActions] = useSlidePanel()
    const [checkbox, setCheckbox] = useState(filters)
    const handleOpen = () => {
        actions.onOpen()
    }

    const handleChange = (e) => {
        const name = e.target.name
        const value = e.target.checked
        setCheckbox({
            ...checkbox,
            [name]: value ? 1 : null
        })
    }


    const checkHasStateFilter = useCallback(() => {
        return Boolean(Object.keys(checkbox).filter(item => Boolean(checkbox[item])).length)
    }, [checkbox])

    const hansleResetFilter = useCallback(() => {
        console.log('buttonSecondClick')
        onResetFilter()
        partnersSync()
        setCheckbox({})
        actions.onClose()
        addParamsToUrl(initialState)
    }, [checkbox, actions])



    const handleSubmit = async () => {
        await onChangeFilter(checkbox)
        partnersSync(checkbox)
        actions.onClose()
        addParamsToUrl(checkbox)
    }


    //console.log('isHasFilter', isHasFilter)

    return (
        <Container>
            <CategoryItem
                size={size}
                variant={isHasFilter() ? 'tertiary' : variant}
                onClick={handleOpen}
                icon={'SettingsIcon'}
                iconSize={10}
                //isActive={getActive(item)}
                //onClick={}
                //href={'/'+combineLink(item.alias) + '#partner-head'}
            />

            <SlidePanel
                onDismiss={actions.onClose}
                initSheet={setActions}
                isPadding={true}
            >
                <BottomSlideContent
                    head='Фильтр'
                    buttonText={checkHasStateFilter() ? 'Показать' : 'Готово'}
                    buttonVariant='primary'
                    buttonClick={handleSubmit}

                    buttonSecondText={checkHasStateFilter() ? 'Сбросить' : false}
                    buttonSecondVariant='secondary'
                    buttonSecondClick={checkHasStateFilter() ? hansleResetFilter : false}

                    isBorder
                >
                    <RowStyle as={'label'}>
                        <Typography>Оплата картой</Typography>
                        <Checkbox
                            type="checkbox"
                            //rowlabel={label}
                            //id={id}
                            input={{
                                name: 'useKassa',
                                onChange: handleChange,
                                value: checkbox.useKassa,
                            }}
                            /*stylesCheckbox={{
                                marginLeft: 'auto',
                            }}*/
                        />
                    </RowStyle>

                    <RowStyle as={'label'}>
                        <Typography>Оплата баллами</Typography>
                        <Checkbox
                            type="checkbox"
                            //rowlabel={label}
                            //id={id}
                            input={{
                                name: 'useBonus',
                                onChange: handleChange,
                                value: checkbox.useBonus,
                            }}
                            /*stylesCheckbox={{
                                marginLeft: 'auto',
                            }}*/
                        />
                    </RowStyle>

                    <RowStyle as={'label'}>
                        <Typography>Акции и скидки</Typography>
                        <Checkbox
                            type="checkbox"
                            //rowlabel={label}
                            //id={id}
                            input={{
                                name: 'haveDiscount',
                                onChange: handleChange,
                                value: checkbox.haveDiscount,
                            }}
                            /*stylesCheckbox={{
                                marginLeft: 'auto',
                            }}*/
                        />
                    </RowStyle>

                </BottomSlideContent>
            </SlidePanel>
        </Container>
    )
}

export default FilterPartners;
