import React from 'react';
import {useAuth} from "../../hooks";
import Typography from "shared/Typography";
import Button from 'shared/Button';
import Input from "shared/Input";
import {
    ContentStyle,
    InputRowStyle,
    RowStyle,
    ErrorStyle,
    BottomLinkStyle,
    RepeatCntWithoutPressStyle,
    RepeatCntStyle
} from "./styles";
import { useTimer } from 'react-timer-hook';
import {links} from "helper";
import Link from "shared/Link";

type Props = {
    //navigation: any,
    //requestAuth: (phone: string, code: string) => void,
    //requestCode: (phone: string) => void,
    //phone: string,
    //auth: any
}



const CodeForm = (props: Props) => {



    const {
        phone,
        error,
        getCode,
        onAuth,
        setCode,
        code,
        isLoading,
    } = useAuth()

    const getTimerSecond = () => {
        const time = new Date();
        time.setSeconds(time.getSeconds() + 60); // 1 minutes timer

        return time
    }

    const {
        seconds,
        restart,
    } = useTimer({ expiryTimestamp: getTimerSecond(), onExpire: () => console.warn('onExpire called') });


    const handleGetCode = () => {
        restart(getTimerSecond())
        getCode()
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation()

        onAuth()
    }

    return (
        <ContentStyle data-cy={'code-form'}>
            <Typography align="left" component="h2" mb="2" variant="text">
                На ваш номер {phone} выслан СМС с кодом подтверждения
            </Typography>
            <form onSubmit={handleSubmit}>
            <InputRowStyle>
                <RowStyle>
                    <Input
                        meta=''
                        error={error}
                        type="text"
                        placeholder="Код из СМС"
                        input={{
                            value: code,
                            onChange: (e) => setCode(e.currentTarget.value),
                        }}
                    />
                </RowStyle>
                <ErrorStyle>
                    {error}
                </ErrorStyle>
            </InputRowStyle>
            <Button disabled={isLoading} onClick={onAuth} component="button" fullSize size="big" variant="primary" mb="2">
                Войти
            </Button>
            </form>
            {seconds !== 0 ? (
                <RepeatCntWithoutPressStyle>
                    <Typography align="center" component="p" mb="2" variant="text" color="gray">
                        Отправить заново через {seconds} сек
                    </Typography>
                </RepeatCntWithoutPressStyle>
            ) : (
                <RepeatCntStyle onClick={handleGetCode}>
                    <Typography align="center" component="p" mb="2" variant="text" color="green">
                        Отправить заново
                    </Typography>
                </RepeatCntStyle>
            )}
            <BottomLinkStyle>
                <Typography align="center" component="p" mb="" variant="text">
                    При входе или регистрации вы прринмаете условия
                    <Link href={links.abs.agreement}>
                        <a target="_blank">
                            <Typography variant="text" align="center" isUnderline>
                                пользовательского соглашения
                            </Typography>
                        </a>
                    </Link>
                </Typography>
            </BottomLinkStyle>
        </ContentStyle>
    );
};

export default CodeForm;
