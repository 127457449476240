import React from 'react';
import {WrapperStyle} from "./styles";
import ShopProductItem from "shared/ShopProduct/components/ShopProductItem";

const ShopProductList = ({
                       data,
                       mb,
                             partner,
                        ...rest
                  }) => {


    return (
        <>
            <WrapperStyle
                data-cy={'shop-product-list'}
                mb={mb}
                {...rest}
            >
                {data?.map((item, i) => (
                    <ShopProductItem key={i} partnerId={partner?.data?.alias} data={item}/>
                ))}

            </WrapperStyle>
        </>
    );
};

export default ShopProductList;
