import styled, {css} from 'styled-components';
import { Fonts, Colors } from 'common/Theme';
import Helpers from 'common/Theme/Helpers';

export const SearchWrapStyle = styled.div`
  ${Helpers.css.flex};
  ${Helpers.css.flex_ac};
  padding: 0 5px;
  
  //margin-bottom
  ${props => props.mb && css`
    margin-bottom: ${Helpers.css.mb[props.mb]};
    `}
  //end margin-bottom
  
  div > ymaps{
    width: 100vw !important;
    left: -60px !important;

    @media (min-width: 768px) {
      width: 704px !important;
    }
   
   >ymaps >ymaps{
     box-shadow: none !important;
     padding: 0px 20px;
     display: block;
     border: 0 !important;
   }
}
  
  ymaps[class*="search__suggest-item"]{
    padding: 10px 0px;
    border-bottom: 1px solid ${Colors.grayButton}
    
}

    ymaps[class*="search__suggest-item"]:hover{
         background: #F4F4F4;
    }
`;

export const IconLeftWrapStyle = styled.div`
  ${Helpers.css.flex};
  ${Helpers.css.flex_center};
  //margin-right: 15px;
`;

export const InputWrapStyle = styled.div`
  ${Helpers.fullWidth};
  margin-bottom: 10px;
`;

export const CloseIconStyle = styled.div`
  ${Helpers.css.flex};
  ${Helpers.css.flex_center};
  //margin-right: 25px;
`;

export const CancelWrapStyle = styled.div`
`;

export const AlertWrapStyle = styled.div`
  padding-top: 15px
`;