import React from 'react';
import ContentLoader from 'react-content-loader';



const Element = ({isHasImage}) => {
    let width = (typeof window !== 'undefined') ? window.innerWidth - 16 : 100;
    width = width > 400 ? 400 : width

    //console.log('isHasImage', isHasImage)


    if(isHasImage){
        return (
            <ContentLoader
                backgroundColor="#d9d9d9"
                animate
                speed={0.8}
                viewBox={`0 0 ${width} 380`}
                height={380}
                width={width}
            >
                <rect x="0" y="3" rx="16" ry="16" width={width} height="290" />
                <rect x="16" y="300" rx="10" ry="10" width={width - 250} height="20" />
                <rect x="16" y="325" rx="10" ry="10" width={width - 150} height="20" />
            </ContentLoader>
        );
    } else {
        return (
            <ContentLoader
                backgroundColor="#d9d9d9"
                animate
                speed={0.8}
                viewBox={`0 0 ${width} 60`}
                height={60}
                width={width}
            >
                <rect x="16" y="10" rx="10" ry="10" width={width - 250} height="20" />
                <rect x="16" y="40" rx="10" ry="10" width={width - 150} height="20" />
            </ContentLoader>
        );
    }

};

const ProductOpenSkeleton = ({isHasImage}) => (
    <>
      {
        Array.from({length: 1}).map((val, index) => (
            <Element isHasImage={isHasImage} key={index} />
        ))
      }
    </>
);

export default ProductOpenSkeleton
