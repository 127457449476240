import Link from 'next/link'
import {useMobileContextDetect} from "shared/DeviceDetect";

const LinkCustom = (props) => {
    const isMobile = useMobileContextDetect();

    return <Link {...props} data-cy={'link'} prefetch={!!isMobile} />
}

export default LinkCustom
