import styled, {css} from 'styled-components';



export const WrapStyle = styled.div`
  position: relative;
  width: 100%;
  >div {
    scroll-behavior: smooth;
    &::-webkit-scrollbar {
      display: none;
    }
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
  }
`;
