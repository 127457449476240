import styled, { css } from "styled-components";
import { Fonts, Colors } from "common/Theme";
import Helpers from "common/Theme/Helpers";

export const WrapperStyle = styled.div`
    ${Helpers.css.flex};
    ${Helpers.css.flex_ac};
    ${Helpers.css.flex_sb};
    ${Helpers.css.flex_wrap};
    ${(props) =>
        props.mb &&
        css`
            margin-bottom: ${Helpers.css.mb[props.mb]};
        `}
    padding: 0 10px;
    padding-top: 20px;

    @media (min-width: 768px) {
        gap: 1.2%;
    }
`;
