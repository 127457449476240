import styled, {css} from 'styled-components';
import { Fonts, Colors } from 'common/Theme';
import Helpers from 'common/Theme/Helpers';

export const WrapStyle = styled.div`
  ${Helpers.css.flex_no_shrink};
  ${Helpers.css.flex};
  ${Helpers.css.flex_vertical};
  background: #FFFFFF;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  height: 275px;
  width: 48%;
  margin-bottom: 13px;
  cursor: pointer;
  //margin-bottom
  ${props => props.mb && css`
      margin-bottom: ${Helpers.css.mb[props.mb]};
  `}
  ${props => props.isElementFound && css`
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);

    animation-name: stretch;
    animation-duration: 1.5s;
    animation-timing-function: ease-out;
    animation-direction: alternate;
    //animation-iteration-count: revert;
    animation-play-state: running; 
  `}

  @media (min-width: 768px) {
    width: 23%;
    margin-bottom: 2.6%;
  }
  
  @keyframes stretch {
    0% {
      transform: scale(.96);
    }

    50% {
      transform: scale(1.02);
    }

    100% {
      transform: scale(1);
    }
  }
  
`;

export const ButtonWrapStyle = styled.div`
  padding: 8px 6px 6px;
`;

export const ImageWrapStyle = styled.div`
  ${Helpers.fullWidth};
  height: 165px;
  border-radius: 15px;
  position: relative;
  overflow: hidden;

  img{
    transition: all 0.3s ease 0s;
    border-radius: 15px;
    &:hover {
      transform: scale(1.05);
    }

    &:active {
      transform: scale(1);
      transition: all 0.3s ease 0s;
    }
  }
`;

export const TextWrapStyle = styled.div`
  ${Helpers.css.flex};
  ${Helpers.css.flex_vertical};
  ${Helpers.css.flex_sb};
  ${Helpers.css.flex_grow};
  padding: 8px 10px 0;
`;

export const WrapMiniStyle = styled.div`
  ${Helpers.css.flex_no_shrink};
  ${Helpers.css.flex_ac};
  ${Helpers.css.flex_sb};
  ${Helpers.css.flex};
  background: #FFFFFF;
  height: 170px;
  width: 200px;
  border: 1px solid rgba(0, 0, 0, 0.04);
  border-radius: 15px;
  padding: 8px 10px 6px;
  margin-right: 28px;
  position: relative;

  
`;

export const TextWrapMiniStyle = styled.div`
  ${Helpers.css.flex};
  ${Helpers.css.flex_vertical};
  ${Helpers.css.flex_sb};
  ${Helpers.css.flex_grow};
  height: 100%;
`;

export const ImageWrapMiniStyle = styled.div`
  height: 50px;
  width: 50px;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
`;

export const ButtonMiniStyle = styled.div`
  ${Helpers.css.item_vertical};
  right: -14px;   
`;
