import styled, {css} from 'styled-components';
import { Fonts, Colors } from 'common/Theme';
import Helpers from 'common/Theme/Helpers';

export const WrapperStyle = styled.div`
  border-radius: 10px;
  ${Helpers.fullWidth};
  ${Helpers.css.flex};
  padding: 8px 12px 8px 10px;
  
  //variant
  ${props => props.variant === 'info' && css`
    background-color: ${Colors.grayLight};
    `}
  ${props => props.variant === 'danger' && css`
    background-color: ${Colors.red};
    `}
  ${props => props.variant === 'success' && css`
    background-color: ${Colors.green};
    `}
  ${props => props.variant === 'note' && css`
    background-color: ${Colors.yellow};
    `}
  //end variant

  //margin-bottom
  ${props => props.mb && css`
  margin-bottom: ${Helpers.css.mb[props.mb]};
  `}
  //end margin-bottom
`;

export const InfoStyle = styled.div`
  
  ${Fonts.textSmall}

  //variant
  ${props => props.variant === 'info' && css`
    color: ${Colors.gray};
    `}
  ${props => props.variant === 'danger' && css`
    color: ${Colors.white};
    `}
  ${props => props.variant === 'success' && css`
    color: ${Colors.white};
    `}
  ${props => props.variant === 'note' && css`
    color: ${Colors.white};
    `}
  //end variant
  
  ${props => props.color && css`
    color: ${props.color};
    `}
`;

export const IconStyle = styled.div`
  margin-right: 8px;
`;
