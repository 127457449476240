import styled from 'styled-components';

export const Container = styled.div`
   display: flex;
`;

export const Row = styled.div`
    display: flex;
    gap: 5px;
`;

export const OldPrice = styled.span`
    font-size: 0.8em;
    text-decoration-line: line-through;
    color: #767676;
    margin-left: 8px;
`;
