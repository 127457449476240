import React from 'react';
import * as S from './styles'

import OptionsItem from '../OptionsItem'
import Typography from 'shared/Typography'


const OptionsGroupComponent = ({optionGroup, onChange, value, isVisible}) => {
    const {name, modifiers, min_amount, max_amount} = optionGroup;



    return (
        <S.Container data-cy={'options-group'}>
            <Typography variant="text" isBold  mb={2}>{name}</Typography>

            <S.OptionContainer>
                {
                    modifiers.map(item => <OptionsItem
                        onChange={onChange}
                        value={value}
                        optionGroup={optionGroup}
                        isVisible={isVisible}
                        minAmount={min_amount}
                        maxAmount={max_amount}
                        key={item.id}
                        id={item.id}
                        modifier={item}/>)
                }
            </S.OptionContainer>

        </S.Container>
    )
}

export default OptionsGroupComponent;