import styled from 'styled-components';

export const MenuElement = styled.div<{ isFirstItem?: boolean, isActive?: boolean, itemTheme?: string }>`
  background: ${({ isActive, itemTheme }) => isActive ? '#DEDEDE' :
    itemTheme ? itemTheme : '#FFFFFF'};
  border-radius: 24px;
  padding: ${({ isSettings }) => isSettings ? '16px 14px' : '11px 17px'};
  align-items: center;
  justify-content: center;
  margin-left: ${({ isFirstItem }) => (isFirstItem ? 0 : 4)}px;
  margin-right: 5px;
`;


export const DirectionTitle = styled.span<{isActive : boolean}>`
  font-style: normal;
  font-weight: ${props => props.isActive ? 'bold' : 'normal'};
  font-size: 16px;
  line-height: 19px;
`;