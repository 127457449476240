import React from 'react';
import {CustomListStyle, HeadStyle, RowStyle, RowLabelStyle, RowValueStyle} from "./styles";
import Typography from "shared/Typography";

const List = ({
                        head,
                        isBorder,
                        isPadding,
                        items,
                        mb,
                        isItemBorder,
                        halfWidth,
                        variant = 'primary',
                        ...rest
                  }) => {



    return (
        <>
            <CustomListStyle
                data-cy={'custom-list-style'}
                isBorder={isBorder}
                isPadding={isPadding}
                mb={mb}
                data-cy={'list-component'}
                {...rest}
            >
                {
                    head && <HeadStyle><Typography variant="h4" component="h4">{head}</Typography></HeadStyle>
                }

                {
                    variant === 'primary' &&
                        items?.map((item, i) => (
                                <RowStyle key={i} data-cy={'list-component'}>
                                    {
                                        item.label && (
                                            <RowLabelStyle isItemBorder={isItemBorder} halfWidth={halfWidth}>
                                                <Typography variant={item.labelVariant} color={item.colorLabel} >
                                                    {item.label}
                                                </Typography>
                                            </RowLabelStyle>
                                        )
                                    }
                                    <RowValueStyle halfWidth={halfWidth}>
                                        <Typography variant={item.valueVariant} color={item.colorValue} >
                                            {item.value}
                                        </Typography>
                                    </RowValueStyle>
                                </RowStyle>
                            ))}



                {
                    variant === 'secondary' &&
                        items?.map((item, i) => (
                                <RowStyle key={i} data-cy={'list-component'}>
                                    <RowLabelStyle variant={variant} isItemBorder={isItemBorder}>
                                        <Typography variant={item.labelVariant} color={item.colorLabel} component={item.labelVariant}>
                                            {item.label}
                                        </Typography>
                                    </RowLabelStyle>
                                </RowStyle>
                            ))}





            </CustomListStyle>
        </>
    );
};

export default List;
