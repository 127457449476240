import {Product} from 'modules/Partner/interfaces/Product';
import React from 'react';
import {WrapStyle, CountStyle, ContentStyle, PriceStyle, GiftIconStyle} from "./styles";
import Typography from "shared/Typography";
import Icon from "shared/Icon";

type Props = {
    product: Product,
    quantity: number,
    cartItemId: number,
    onOpenProduct: (product: any, isProductForPoint: any, cartItemId: any) => void,
    cartProductOptions: [],
    isGiftItems: boolean,
    price: number,
    isProductForPoint: boolean,
};

const CartItem = (props: Props) => {
    const {
        product,
        quantity,
        cartItemId,
        cartProductOptions,
        onOpenProduct,
        isGiftItems,
        price,
        isProductForPoint,
        mb,
        ...rest
    } = props;
    const {id, name, pointsPrice, modifiers = []} = product;

    const renderOptions = () =>
        modifiers.map((option, i) => {
            const mods = option.modifiers.filter((modifier) => cartProductOptions.includes(modifier.id));

            return mods.map((mod, index) => (
                <Typography variant="textSmall" color='gray' key={mod.id}>
                    {mod.name}
                    {mod.price !== 0 && '+ ' + mod.price}
                </Typography>
            ));
        });


    return (
        <div
            data-cy={'cart-item'}
            onClick={() => {
                /*if(!isGiftItems){
                    onOpenProduct(isProductForPoint,product, cartItemId)}
                }*/
                onOpenProduct(isProductForPoint,product, cartItemId, isGiftItems)}
            }
            key={id}
        >

            <WrapStyle
                mb={mb}
                {...rest}
            >
                {
                    Boolean(quantity || isGiftItems) && (
                        <CountStyle><Typography variant="bigText">{isGiftItems ? 1 : quantity}x</Typography></CountStyle>
                    )
                }


                <ContentStyle>
                    <Typography variant="bigText" mb="0.5">{name}</Typography>
                    {
                        renderOptions()
                    }

                </ContentStyle>
                {
                    isGiftItems ? (
                        <GiftIconStyle> <Icon color={'green'} name={'GiftIcon'}/> </GiftIconStyle>
                    ) : (
                        <PriceStyle>
                            <Typography variant="bigText" align='right'>
                                {isProductForPoint ? pointsPrice : price}
                                {
                                    isProductForPoint ? <span>балл.</span> : <span>₽</span>
                                }
                            </Typography>
                        </PriceStyle>
                    )
                }

            </WrapStyle>
        </div>
    );
};

CartItem.defaultProps = {
    productOpen: () => {
    },
    product: {},
};

export default CartItem;
