import * as t from './types';
import { Dispatch } from 'redux';
import {bannerService} from "modules/Promotion/services";

export const bannerContainerSync = (id, config) => async (dispatch: Dispatch) => {

    dispatch({ type: t.FETCH_BANNER_CONTAINER_START });

    try {
        const data = await bannerService.fetchBannerContainerById(id, config);
        let payload = data;
        return dispatch({ type: t.FETCH_BANNER_CONTAINER_SUCCESS, payload });
    } catch (error) {
        console.log(error)
        dispatch({ type: t.FETCH_BANNER_CONTAINER_FAILURE, payload: error?.response });
        return  error?.response
    }
};



