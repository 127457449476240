import { promoCodeService } from "modules/Promotion/services";

export const activateUserPromo = async (data) => {
    try {
        return await promoCodeService.activateUserPromo(data);
    } catch (error) {
        return error;
    }
};

export const activationСode = async (data) => {
    try {
        return await promoCodeService.activationСode(data);
    } catch (error) {
        return error;
    }
};