import React from 'react';
import {ContainerStyle} from './styles';
import List from 'shared/List';
import PointPrefix from 'shared/PointPrefix';
import {useCurrecny} from "modules/Partner/hooks/useCurrency";
import {DeliveryMethod} from "modules/Partner/enums/deliveryMethodEnum";


const CartItogComponent = ({ cart: { deliveryCost, total, promo, partner } }) => {
    const currency = useCurrecny('partner', partner);
    const deliveryMethod = partner?.deliveryMethod

    console.log('ede', deliveryMethod, DeliveryMethod.Taxi)

    return (
        <ContainerStyle data-cy={'cart-itog-component'}>
            <List
                items={[
                    {
                        colorLabel: 'gray',
                        label: 'Сумма заказа:',
                        value: <PointPrefix currency={currency}>{total}</PointPrefix>,
                        labelVariant: 'bigText',
                        valueVariant: 'bigText'
                    },
                    {
                        colorLabel: 'gray',
                        label: 'Доставка:',
                        value: <PointPrefix currency={currency}>
                            {deliveryMethod?.key === DeliveryMethod.Taxi ? 'от' : ""} {deliveryCost}</PointPrefix>,
                        labelVariant: 'bigText',
                        valueVariant: 'bigText'
                    },
                    ...(Boolean(promo && promo.discount) ?
                        [{
                            colorLabel: 'gray',
                            label: 'Промокод:',
                            value: <PointPrefix currency={currency}>{-promo.discount}</PointPrefix>,
                            labelVariant: 'bigText',
                            valueVariant: 'bigText'
                        }] :
                        []),
                    {
                        colorLabel: 'gray',
                        label: 'Итого:',
                        value: <PointPrefix currency={currency}>{deliveryCost + total - (promo && promo.discount ? promo.discount : 0)}</PointPrefix>,
                        labelVariant: 'bigText',
                        valueVariant: 'bigText'
                    }
                ]}
            />
        </ContainerStyle>
    )
}

export default CartItogComponent;
