import { Dispatch } from 'redux';
import * as t from './types';
import { FETCH_PARTNERS } from './types';

export const setHasDelivery = (hasDelivery) => ({ type: FETCH_PARTNERS, payload: hasDelivery });

export const addMapInfo = (data) => (dispatch: Dispatch) => {
    dispatch({ type: t.ADD_MAP_INFO_SUCCESS, payload: data });
};

export const addSelectedAddressId = (id: number) => (dispatch: Dispatch) => {
    dispatch({ type: t.SET_SELECTED_ADDRESS_ID, payload: id });
};

