import React, { useEffect} from 'react';
import {useBanner} from 'modules/Promotion/hooks'
import BannerListCarusel from 'shared/Promotion/components/PromotionList'
import EmptyContent from "shared/EmptyContent";
import PromotionSecondarySkeletonLoader from "shared/skeleton/PromotionSecondarySkeletonLoader";
import ContentMediaView from "shared/ContentMediaView";

let BannerList = () => {

    const {
        banner,
        onBannerSync
    } = useBanner()

    useEffect(() => {
        if(!banner?.data?.length){
            onBannerSync()
        }
    },[])

    if(banner.isLoading) {
        return (
            <ContentMediaView>
                <PromotionSecondarySkeletonLoader/>
            </ContentMediaView>
        )
    }

    return (
        <EmptyContent isEmpty={!banner.data.length}>
            <BannerListCarusel
                data={banner.data}
                mb={2}
                variant="secondary"
                data-cy={'banner-list'}
            />
        </EmptyContent>
    )
}



export default BannerList
