import React, { useCallback, useMemo } from "react";
//import { addToCart, removeCartItem, updateItemInCart } from '../../../Cart/actions';
//import { cartSelector, productInCartByIdSelector, productQuantityInCartByIdSelector, productsForPointSelector } from '../../../Cart/selectors';
import { addressSelector } from "modules/Map/store/selectors";
import { Product } from "modules/Partner/interfaces/Product";
import { useCart } from "modules/Cart/hooks";
/*import { isChangedPartnerId, showAuthorizationMessage, showChoiceOptionsMessage, showDeliveryNotAvailableMessage, showNotEnoughPointsMessage, useReplacePointsDishMesssage } from '../../../ProductOpen/helpers';*/
import { Partner } from "modules/Partner/interfaces/Partner";
import ProductsItemSmallComponent from "./small/small";
import ProductsItemDefaultComponent from "./default/default";
import PriceNewOld from "modules/Partner/components/PriceNewOld";

/*const inAnimation = {
    toValue: 1,
    easing: Easing.linear,
    duration: 200,
    useNativeDriver: true
};

const outAnimation = {
    toValue: 0,
    easing: Easing.linear,
    duration: 200,
    useNativeDriver: true
};
const defaultIconWidth = Helpers.screenWidth * 0.0035;*/

type Props = {
    product: Product;
    onOpenProduct: (product: Product, isProductForPoint: boolean) => void;
    isProductForPoint: boolean;
    zoneId: number;
    navigation: any;
    partner: Partner;
    small?: boolean;
    imageWithoutBaseUrl?: boolean;
};

const ProductsItem = (props: Props) => {
    const {
        product,
        partner,
        onOpenProduct,
        isProductForPoint,
        small,
        variant,
        elementFoundId,
        imageWithoutBaseUrl,
    } = props;
    const handleOpenProduct = useCallback(
        () => onOpenProduct(isProductForPoint, product),
        [onOpenProduct],
    );
    const {
        onAddCartPressed,
        onReducePressed,
        //priceProduct,
        isLoading,
        quantityInCart,
    } = useCart(product, partner, isProductForPoint, null, handleOpenProduct);




    if (small) {
        return (
            <ProductsItemSmallComponent
                product={product}
                onAddCartPressed={onAddCartPressed}
                onReducePressed={onReducePressed}
                onOpenProduct={handleOpenProduct}
                //width={width}
                priceProduct={<PriceNewOld product={product} isProductForPoint={isProductForPoint} pointUnit={{ pointUnit: 'Б' }}/>}
                isLoading={isLoading}
                isProductForPoint={isProductForPoint}
                quantityInCart={quantityInCart}
                partner={partner}
                variant={variant}
                isElementFound={elementFoundId === product.id}
                data-cy="products-item"
            />
        );
    }
    return (
        <ProductsItemDefaultComponent
            imageWithoutBaseUrl={imageWithoutBaseUrl}
            product={product}
            onAddCartPressed={onAddCartPressed}
            onReducePressed={onReducePressed}
            onOpenProduct={handleOpenProduct}
            //width={width}
            priceProduct={<PriceNewOld product={product} isProductForPoint={isProductForPoint} pointUnit={{ pointUnit: 'Б' }}/>}
            isLoading={isLoading}
            isProductForPoint={isProductForPoint}
            quantityInCart={quantityInCart}
            partner={partner}
            variant={variant}
            data-cy="products-item"
            isElementFound={elementFoundId === product.id}
        />
    );
};

export default React.memo(ProductsItem);
