import styled, {css} from 'styled-components';
import { Fonts, Colors } from 'common/Theme';
import Helpers from 'common/Theme/Helpers';

export const WrapStyle = styled.div`
  height: 29px;
  ${Helpers.css.flex_inline};
  ${Helpers.css.flex_ac};
  background: ${Colors.white};
  border-radius: 10px;
  //overflow: hidden;

  //margin-bottom
  ${props => props.mb && css`
      margin-bottom: ${Helpers.css.mb[props.mb]};
  `}
  //end margin-bottom
`;

export const IconWrapStyle = styled.div`
  background: ${Colors.green};
  ${Helpers.css.flex};
  ${Helpers.css.flex_center};
  border-radius: 10px;
  margin-left: -1px;
  height: 29px;
  width: 29px;
`;

export const TextStyle = styled.div`
  //height: 29px;
  padding: 0 8px;
`;
