import {useState} from "react";




const useSlidePanel = (callbacks?) => {
    const [sheetAction, setSheetAction] = useState({
        afterOpen: callbacks?.afterOpen,
        afterClose: callbacks?.afterClose,
        beforeOpen: callbacks?.beforeOpen,
        beforeClose: callbacks?.beforeClose,
    })


    /*const [isOpen, setOpen] = useState(state)

    const onToggle = () => setOpen(!isOpen)
    const onOpen = () => setOpen(true)
    const onClose = () => setOpen(false)

    return {onToggle, isOpen, onOpen, onClose}*/



    const setActions = (actions) => {
        setSheetAction({
            ...sheetAction,
            ...actions
        })

        return sheetAction
    }


    return [sheetAction, setActions]
}

export default useSlidePanel
