import styled, {css} from 'styled-components';
import { Fonts, Colors } from 'common/Theme/index';
import Helpers from 'common/Theme/Helpers';

export const BtnStyle = styled.button`
  border: none;
  font: inherit;
  color: inherit;
  text-decoration: none;
  ${Helpers.center};
  ${Helpers.css.flex_inline};
  ${Helpers.css.cursor_pointer};
  /*${Helpers.css.flex_no_shrink};*/
  -moz-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  -webkit-transition: all 0.5s linear;
  
  cursor: pointer;
  
  //color
  ${props => props.variant === 'primary' && css`
    background: ${Colors.green};
    border-color: ${Colors.green};
    color: ${Colors.white};
    &:hover{
      opacity: 0.7;
    }
  `}
  ${props => props.variant === 'secondary' && css`
    background: ${Colors.grayButton};
    border-color: ${Colors.grayButton};
    color: ${Colors.black};
    &:hover{
      opacity: 0.5;
    }
  `}
  ${props => props.variant === 'tertiary' && css`
    background: ${Colors.yellow};
    border-color: ${Colors.yellow};
    color: ${Colors.white};
    &:hover{
      opacity: 0.7;
    }
  `}
  ${props => props.variant === 'transparent' && css`
    background: ${Colors.transparent};
    border-color: ${Colors.grayButton};
    color: ${Colors.black};
    &:hover{
      opacity: 0.7;
    }
  `}
  ${props => props.variant === 'light' && css`
    background: ${Colors.white};
    border-color: ${Colors.grayButton};
    color: ${Colors.black};
    &:hover{
      opacity: 0.7;
    }
  `}
  ${props => props.variant === 'danger' && css`
    background: ${Colors.red};
    border-color: ${Colors.red};
    color: ${Colors.white};
    &:hover{
      opacity: 0.7;
    }
  `}

  ${props => props.variant === 'more' && css`
    background: ${Colors.yellowLight};
    &:hover{
      opacity: 0.7;
    }
  `}
  //end color

  //text color
  ${props => props.textColor && css`
    color: ${props.textColor};
  `}
  //end text color
  
  //icon Position
  ${props => props.iconPosition === 'right' && css`
    ${Helpers.css.flex_reverse};
  `}
  //end icon Position
  
  //size
  ${props => props.size === 'normal' && `
    height: 32px;
    width: auto;
    border-radius: 15px;
    font-size: 14px;
    line-height: 17px;
    padding: 0 15px;
  `}
  ${props => props.size === 'big' && `
    height: 40px;
    width: auto;
    border-radius: 12px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    padding: 0 12px;
  `}
  ${props => props.size === 'small' && `
    height: 28px;
    width: auto;
    border-radius: 24px;
    font-size: 14px;
    line-height: 14px;
    padding: 0 12px;
  `}
  ${props => props.size === 'extraBig' && `
    height: 44px;
    border-radius: 8px;
  `}

  ${props => props.size === 'normal' && !props.isHasChildren && `
    height: 32px;
    width: 32px;
    padding: unset;
  `}
  
  ${props => props.size === 'big' && !props.isHasChildren && `
    height: 40px;
    width: 40px;
    border-radius: 24px;
  `}
  
  ${props => props.size === 'small' && !props.isHasChildren && `
    height: 26px;
    width: 26px;
    border-radius: 24px;
  `}
  
  ${props => props.size === 'extraBig' && !props.isHasChildren && `
    height: 44px;
    width: 44px;
    border-radius: 24px;
  `}

  ${props => props.fullSize && css`
    ${Helpers.fullWidth};
  `}
  //end size
  
  //isShadow
  ${props => props.isShadow && `
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  `}
  //end isShadow
  
  //isBorder
  ${props => props.isBorder && css`
    border-width: 1px;
    border-style: solid;
  `}
  //end isBorder

  //hover
  /*${props => !props.href && !props.onClick && css`
    cursor: unset;
    &:hover{
      opacity: unset;
    }
  `}*/
  //end hover

  //margin-bottom
  ${props => props.mb && css`
    margin-bottom: ${Helpers.css.mb[props.mb]};
    `}
  //end margin-bottom
  
  ${props => props.disabled && css`
    pointer-events: none;
    opacity: 0.6;
  `}

  ${props => props.isBold && css`
    font-weight: bold;
  `}
`;

export const IconStyle = styled.div`
  ${Helpers.css.flex};
  ${Helpers.css.flex_ac};
  pointer-events: none;
  margin-right: 6px;
  //icon Position
  ${props => props.iconPosition === 'right' && css`
    margin-right: unset;
    margin-left: 6px;
  `}
  //end icon Position

  ${props => !props.isHasChildren && `
    margin: unset;
  `}
`;