import styled, {css} from 'styled-components';
import { Fonts, Colors } from 'common/Theme';
import Helpers from 'common/Theme/Helpers';

export const ItemStyle = styled.div`
  ${Helpers.css.flex_no_shrink};
  height: 180px;
  width: 180px;
  margin-right: 13px;

  @media (min-width: 768px) {
    height: 229px;
    width: 229px;
    margin-right: 25px;
  }
  
  //margin-bottom
  ${props => props.mb && css`
      margin-bottom: ${Helpers.css.mb[props.mb]};
  `}
  //end margin-bottom
  
  &:last-child{
    margin-right: unset;
  }

  ${props => props.variant === 'tertiary' && css`
    height: 132px;
    width: 102px;
    margin-right: 8px;
    
    &:last-child{
      margin-right: unset;
    }
  `}
`;

export const ItemBigStyle = styled.div`
  ${Helpers.css.flex_no_shrink};

  height: 179px;
  width: 356px;
  margin-right: 13px;

  @media (min-width: 768px) {
    width: 483px;
    height: 240px;
    margin-right: 25px;
  }

  &:last-child{
    margin-right: unset;
  }
`;

export const ItemWrapStyle = styled.div`
  ${Helpers.css.flex_no_shrink};
  border-radius: 15px;
  padding: 0 25px 0 10px;
  height: 110px;
  width: 280px;
  margin-right: 13px;
  background: ${Colors.white};
  
  &:last-child{
    margin-right: unset;
  }
`;

export const ImageWrap = styled.div`
  ${Helpers.fullSize};
  border-radius: 15px;
  position: relative;
  overflow: hidden;
  margin-right: 13px;
  width: 100%;
  img{
    border-radius: 15px;
    transition: all 0.3s ease 0s;
    &:hover {
      transform: scale(1.05);
    }

    &:active {
      transform: scale(1);
      transition: all 0.3s ease 0s;
    }
  }

`;

export const IconWrap = styled.div`
  background: ${Colors.green};
  ${Helpers.css.flex_no_shrink};
  ${Helpers.css.flex};
  ${Helpers.css.flex_center};
  height: 55px;
  width: 55px;
  border-radius: 50%;
  margin-right: 13px;
`;

export const OfferWrap = styled.div`
  ${Helpers.fullSize};
  ${Helpers.css.flex};
  ${Helpers.css.flex_vertical};
  ${Helpers.css.flex_hc};
  position: relative;
  overflow: hidden;
`;
