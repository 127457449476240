import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    align-items: center;
    overflow: auto;
    padding: 10px 16px;
    padding-left:0;
    z-index: 2;
    background: #fff;

  @media (min-width: 768px) {
    top: 70px;
  }
`;


export const ContainerStick = styled.div`
    position: sticky;
    top: 0;
    z-index: 2;

  @media (min-width: 768px) {
    top: 70px;
  }
`;

export const Wrap = styled.div`
    display: flex;
    align-items: center;
    padding-left: 15px;
    background: #fff;
`;

export const BurgerWrap = styled.div`
`;

