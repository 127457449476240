import { Colors, Helpers } from "common/Theme";
import styled from "styled-components";

export const ProductView = styled.span`
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  background: white;
  elevation: 4;
  display: flex;
  justify-content: space-between;
  margin-horizontal: 2%;
`;

export const ImageView = styled.div`
  align-items: center;
  img{
      border-radius: 15px;
    }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
`;

export const TitleView = styled.div`
  width: 100%;
  margin-top: 3%;
  left: 7%;
`;

export const Title = styled.span`
  font-size: 12px;
  color: #323232;
  text-align: center;
`;

export const Button = styled.span<{ hasInBasket: boolean }>`
  background-color: ${({ hasInBasket }) => hasInBasket ? Colors.success : '#EEEEEE'};
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  margin-horizontal: 3%;
  margin-vertical: 3%;
  ${Helpers.css.flex}
  ${Helpers.css.flex_center}
`;

export const ButtonTitle = styled.span<{ hasInBasket: boolean }>`
  font-size: 14px;
  line-height: 16px;
  color: ${({ hasInBasket }) => hasInBasket ? Colors.white : '#323232'};;
`;

export const CountView = styled.div`
  align-items: center;
`;

export const CountText = styled.span`
  font-size: 9px;
  color: #B7B7B7;
`;

export const CountRow = styled.div`
  ${Helpers.rowCross}
  justify-content: space-between;
  margin-top: 3%;
  margin-bottom: 3%;
  height: ${Helpers.screenHeight * 0.04}px;
`;

export const CountTitle = styled.span`
  font-size: 13px;
  line-height:13px;
`;
export const ActionBtn = styled.span`
  padding-horizontal: 7%;
`;

export const DescriptionView = styled.div`
  flex: 1;
  width: 100%;
  padding-horizontal: 7%;
`;

export const DescriptionText = styled.span`
  font-size: 10px;
  text-align: left;
  color:  #AAAAAA;
`;