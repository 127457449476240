import React from 'react';
import {ItemStyle,  ImageWrap, LabelStyle} from "./styles";
import Typography from "shared/Typography";
import Icon from "shared/Icon";
import Link from "shared/Link";
import {links} from "helper";

const PromotionLastItem = ({
                       data,
                       variant,
                       mb,
                        ...rest
                  }) => {



    return (
        <Link href={links.abs.discount} data-cy={'promotion-last-item'}>
            <a>
                <ItemStyle mb={mb} {...rest}>
                    <LabelStyle>
                        <Typography variant='textSmall'>Показать все</Typography>
                    </LabelStyle>
                    <ImageWrap><Icon name='ArrowRightLongIcon' /></ImageWrap>
                </ItemStyle>
            </a>
        </Link>
    );
};

export default PromotionLastItem;
