import React from 'react';
import ContentLoader from 'react-content-loader';
import {WrapStyle, ElementStyle} from "./styles";
import {useMobileContextDetect} from "shared/DeviceDetect";

const Element = () => {
    let itemW = 130
    const isMobile = useMobileContextDetect();
    const height = isMobile ? 21 : 48
    const radius = isMobile ? 10 : 20
    return (
        <ElementStyle>
            <ContentLoader
                data-cy={'tabs-skeleton-loader'}
                backgroundColor="#d9d9d9"
                animate
                speed={0.8}
                height={height}
                width={itemW + 6}
            >
                <rect x="0" y="0" rx={radius} ry={radius} width={itemW} height="100%" />
            </ContentLoader>
        </ElementStyle>
    );
};
const HeadSkeletonLoader = ({mb}) => {


    return (
        <>
            <WrapStyle mb={mb}>
                {
                  Array.from({length: 1}).map((val, index) => (
                      <Element key={index} />
                  ))
                }
            </WrapStyle>
        </>
    );
}


export default React.memo(HeadSkeletonLoader)
