import {FETCH_PARTNER_SUCCESS} from 'modules/Partner/store/partner/actions/types';
import {FETCH_PARTNERS_LIST_SUCCESS} from 'modules/Partner/store/partners/actions/types';
import {FETCH_PARTNERS_NEW_LIST_SUCCESS} from 'modules/Partner/store/partnersNew/actions/types';
import {FETCH_SPECIAL_PROMO_GIFT_SUCCESS} from 'modules/Promotion/store/specialPromoGifts/actions/types';
import {FETCH_CART_SUCCESS} from 'modules/Cart/store/cart/actions/types';
import {FETCH_MY_ORDERS_SUCCESS} from 'modules/Order/store/myOrders/actions/types';

export const initialState = null;
let currency = null

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case FETCH_PARTNERS_LIST_SUCCESS:
            if(state){
                return state
            }

            currency = action.payload?.list[0]?.currency
            return currency

        case FETCH_PARTNER_SUCCESS:
            if(state){
                return state
            }

            currency = action.payload?.currency
            return currency

        case FETCH_SPECIAL_PROMO_GIFT_SUCCESS:
            if(state){
                return state
            }

            currency = action.payload[0].partner?.currency
            return currency

        case FETCH_PARTNERS_NEW_LIST_SUCCESS:
            if(state){
                return state
            }

            currency = action.payload?.list[0]?.currency
            return currency

        case FETCH_CART_SUCCESS:
            if(state){
                return state
            }

            currency = action.payload?.partner.currency
            return currency
        case FETCH_MY_ORDERS_SUCCESS:
            if(state){
                return state
            }

            currency = action.payload[0].partner?.currency
            return currency
        default:
            return state;
    }
};
