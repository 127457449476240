import styled from 'styled-components';
import Colors from 'common/Theme/Colors';
import Helpers from 'common/Theme/Helpers';

export const ContainerStyle = styled.div`
  width: 150px;
  height: 70px;
  padding: 0 20px;
  background: ${props => props.isHasCart ? Colors.green : Colors.grayButton};
  ${Helpers.css.flex}
  ${Helpers.css.flex_center}
  margin-right: 16px;
  margin-left: 20px;
  cursor: pointer;
  -moz-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  -webkit-transition: all 0.5s linear;
  
  &:hover {
    opacity: 0.7;
  }
`;

export const CenterStyle = styled.div`
  width: 100%;
  ${Helpers.css.flex}
  ${Helpers.css.flex_ac}
  border-radius: 14px;
`;

export const LabelWrapStyle = styled.div`
  ${Helpers.css.flex}
  ${Helpers.css.flex_ac}
`;

export const CartIconWrapStyle = styled.div`
  margin-right: 7px;
`;

export const InfoStyle = styled.div`
  margin-left: auto;
  ${Helpers.css.flex}
  ${Helpers.css.flex_ab}
`;

export const IconWrapStyle = styled.div`
  transform: rotate(180deg);
`;

export const TotalPriceStyle = styled.div`
  margin-right: 7px;
`;