import styled, {css} from 'styled-components';
import { Fonts, Colors } from 'common/Theme';
import Helpers from 'common/Theme/Helpers';

export const ItemStyle = styled.div`
  ${Helpers.css.flex_no_shrink};
  height: 180px;
  width: 180px;
  ${props => props.mb && css`
      margin-bottom: ${Helpers.css.mb[props.mb]};
  `}
   background: #fff6e8;
   border-radius:15px;
   padding: 12px;
   ${Helpers.css.flex};
   ${Helpers.css.flex_vertical};
`;

export const ItemBigStyle = styled.div`
  ${Helpers.css.flex_no_shrink};

  height: 179px;
  width: 356px;
  margin-right: 13px;

  &:last-child{
    margin-right: unset;
  }
`;

export const ItemWrapStyle = styled.div`
  ${Helpers.css.flex_no_shrink};
  border-radius: 15px;
  padding: 0 25px 0 10px;
  height: 110px;
  width: 280px;
  margin-right: 13px;
  background: ${Colors.white};
  
  &:last-child{
    margin-right: unset;
  }
`;

export const ImageWrap = styled.div`
`;

export const IconWrap = styled.div`
`;

export const OfferWrap = styled.div`
  ${Helpers.fullSize};
  ${Helpers.css.flex};
  ${Helpers.css.flex_vertical};
  ${Helpers.css.flex_hc};
  position: relative;
  overflow: hidden;
`;

export const LabelStyle = styled.div`
margin-top: auto;
`;
