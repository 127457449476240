import  React, {useEffect, useCallback} from 'react';
import  useDirection from '../../hooks/useDirection';
import  СategoryList from 'shared/Category/components/СategoryList';
import  Card from 'shared/Card';
import {useMobileContextDetect} from 'shared/DeviceDetect';
import { scroller } from 'react-scroll'
import { useRouter } from 'next/router'
import FilterPartners from "modules/Partner/components/FilterPartners";
import {Container} from "./styles";
import TabsSkeletonLoader from "shared/skeleton/TabsSkeletonLoader";


const DirectionsPartnersList = ({variant = 'secondary', isHideFilter = false, mb = 1.5}) => {
    const {directions, getActive, onFetchDerections, isLoadingDirections} = useDirection()
    const isMobile = useMobileContextDetect();

    const router = useRouter()
    useEffect(() => {
        //console.log('directions', directions)
        if(!directions?.length){
            onFetchDerections();
        }

    }, [router.asPath]);

    /*useEffect(() => {
        //onScrollOnPartners()

    }, [router.query.categoryAlias || router.query.alias]);*/


    useEffect(() => {
        //onScrollOnPartners()

    }, []);


    const onScrollOnPartners = useCallback(() => {
        setTimeout(() => {
            scroller?.scrollTo('partner-head', {
                duration: 500,
                delay: 0,
                smooth: true,
                offset: isMobile ? 50 : -100, // Scrolls to element + 50 pixels down the page
            })
        }, 500)
    }, [])

    //console.log('isLoadingDirections', isLoadingDirections)

    if(isLoadingDirections){
        return <TabsSkeletonLoader/>
    }

    return (
        <Card fullWidth variant={variant === 'secondary' ? 'primary' : 'secondary'}>
            <Container>
                {
                    !isHideFilter && (
                        <FilterPartners  size="big" variant={variant}/>
                    )
                }
                <СategoryList
                    data-cy={'direction-partner-list'}
                    mb={mb}
                    size="big"
                    data={directions}
                    onClick={onScrollOnPartners}
                    getActive={getActive}
                    variant={variant}
                />
            </Container>
        </Card>
    );
}


export default DirectionsPartnersList
