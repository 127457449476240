import {useCallback} from "react";
import {useSelector} from "react-redux";
import { RootState } from "store";
import {useAppDispatch} from "store";
import Promotion from "modules/Promotion";
import groupBy from 'lodash/groupBy'
import map from 'lodash/map'


const usePromotion = () => {
    const specialPromoGifts = useSelector((state: RootState) => state.specialPromoGifts);
    const promoGifts = useSelector((state: RootState) => state.promoGifts);
    const dispatch = useAppDispatch();

    const onSpecialPromoGifts = useCallback(async () => {
        dispatch(Promotion.store.specialPromoGifts.actions.specialPromoGiftsSync())
    }, [])

    const onPromoGifts = useCallback(async () => {
        dispatch(Promotion.store.promoGifts.actions.promoGiftsSync())
    }, [])

    const getGroupData = useCallback(() => {
        const group = groupBy(promoGifts.data,"partner.id")
        const data = map(group, (items, name) => ({
            name: items[0]?.partner.name,
            image: items[0]?.partner.image,
            partner: items[0]?.partner,
            id: items[0]?.id,
            items,
        }))

        return data
    }, [promoGifts]);


    return {
        specialPromoGifts,
        promoGifts,
        onSpecialPromoGifts,
        onPromoGifts,
        getGroupData,
    }
}

export default usePromotion