import  React from 'react';
import * as S from './styles';


const DirectionElement = ({children, item, onClick, isActive}) => {



    return (
        <S.MenuElement
            data-cy={'direction-element'}
            className='directionElement'
            onClick={() => onClick(item)}
            disabled={false}
            isActive={isActive}
            isSettings={false}
            itemTheme={false}
        >
            {children || <S.DirectionTitle isActive={false}>{item?.name}</S.DirectionTitle>}
        </S.MenuElement>
    );

}


export default DirectionElement