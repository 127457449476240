import styled, {css} from 'styled-components';
import { Fonts, Colors } from 'common/Theme/index';
import Helpers from 'common/Theme/Helpers';

export const WrapperStyle = styled.div`
    padding: 20px 16px;
    margin: -20px 0;
    background: ${Colors.bg};
    

  ${props => props.variant === 'primary' && css`
    padding: 0 16px;
  `}

  ${props => props.variant === 'secondary' && css`
    ${Helpers.css.flex};
    ${Helpers.css.flex_at};
    overflow: auto;

    @media (min-width: 768px) {
      gap: 25px;
      padding: 25px 0;
    }
  `}
  

  //margin-bottom
  ${props => props.mb && css`
      margin-bottom: ${Helpers.css.mb[props.mb]};
  `}
  //end margin-bottom
`;

export const RowStyle = styled.div`
  width: 100%;
  ${Helpers.css.flex};
  ${Helpers.css.flex_ac};
  ${Helpers.css.flex_sb};
`


export const WrapperSkeletonStyle = styled.div`
    background: ${Colors.bg};
    

  ${props => props.variant === 'primary' && css`
    padding: 0 16px;
  `}

  ${props => props.variant === 'secondary' && css`
    ${Helpers.css.flex};
    ${Helpers.css.flex_at};
    overflow: auto;

    @media (min-width: 768px) {
      gap: 25px;
      padding: 25px 0;
    }
  `}
  

  //margin-bottom
  ${props => props.mb && css`
      margin-bottom: ${Helpers.css.mb[props.mb]};
  `}
  //end margin-bottom
`;