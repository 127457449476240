import {useCallback} from "react";
import {useSelector} from "react-redux";
import { RootState } from "store";
import {useAppDispatch} from "store";
import User from "modules/User";
import moment from "moment";




const useProfile = () => {
    const profile = useSelector((state: RootState) => state.profile);
    const userBonus = profile.data.bonuses.rub;
    const dispatch = useAppDispatch();

    const isHasProfile = Boolean(profile?.data?.id);

    const onSubmit = useCallback(async ({Profile}) => {
        dispatch(User.store.profile.actions.updateProfile({
            ...Profile,
            birthday: moment(Profile.birthday, "DD.MM.YYYY").format("YYYY-MM-DD")
        }))

    }, [])

    const profileSync = useCallback(async () => {
        dispatch(User.store.profile.actions.getProfile())
    }, [])

    return {
        isHasProfile,
        onSubmit,
        profileSync,
        userBonus,
        profile
    }
}

export default useProfile