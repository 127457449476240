import React, {useCallback, useEffect, useState} from 'react';
import useMessage from '../../hooks/useMessage';
import usePartnerOpen from '../../hooks/usePartnerOpen';
import Menu from 'modules/Partner/components/Menu';
import PartnerHeader from 'modules/Partner/components/PartnerHeader';
import Card from 'shared/Card';
import {useRouter} from "next/router";
import { getWorkingDayRelativelyToday } from 'helper/workingDay';
import navRoute from "helper/navRoute";
import {links, combineLink} from "helper";
import {useSlidePanel} from "shared/SlidePanel";
import SlidePanel from "shared/SlidePanel/SlidePanel";
import BottomSlideContent from "shared/BottomSlideContent";
import List from "shared/List";
import PointPrefix from "shared/PointPrefix";
import {DeliveryMethod} from "modules/Partner/enums/deliveryMethodEnum";
import DiscountPartnerShort from "modules/Partner/components/DiscountPartnerShort";
import Alert from "shared/Alert";
import useMap from "modules/Map/hooks/useMap";
import {ContentStyle} from "shared/StaticTextSlide/styles";
import ContentMediaView from "shared/ContentMediaView";
import {partnerService} from "modules/Partner/services";
import {useMiniSiteDetect} from "common/context/MiniSiteDetect";


const PartnerOpen = ({}) => {
    const {partnerSync, partner, openWorkDays, openWorkHours, checkBlockedPartner} = usePartnerOpen()
    const [actions, setActions] = useSlidePanel()
    const {map} = useMap()
    const [typeBottomSlide, setTypeBottomSlide] = useState()
    const router = useRouter()
    const {
        name = '',
        workTime = [],
        rating = '',
        reviewCount = '',
        minSum = '',
        freeDeliverySum = '',
        deliveryDuration = '',
        workDays = '',
        useKassa,
        useBonus,
        isHalal,
        deliveryCost = '',
        isNew,
        deliveryMethod,
        minSumFoodPoints,
        physicalAddress,
        specializations,
        legalInfo,
        htmlExtra
    } = partner.data;
    const isNewPartner = isNew === 1;
    const [startWorkingDay, endWorkingDay] = workTime;
    const navigation = navRoute()
    const {addressLabel} = useMap()
    const {showDeliveryNotAvailableMessage, showNotCanDelivered} = useMessage()
    const isMiniSite = useMiniSiteDetect()

    const onGoReview = useCallback(() => {

        navigation.navigate(combineLink(links.abs.partners, router.query.partnerId, 'review'))
    }, [])



    /* useEffect(() => {
         const el = `item${navigator.query.item}`
         //const el = `item275446`

         console.log('el', el)

         setTimeout(() => {
             scroller.scrollTo(el, {
                 duration: 1500,
                 delay: 0,
                 //smooth: true,
                 offset: 50, // Scrolls to element + 50 pixels down the page
             })
         }, 5000)

     }, [])*/


    useEffect( () => {
        if(partner?.error){
            Alert.alert(
                partner.error?.message,
                '',
                [
                    {
                        text: "Указать адрес",
                        onPress: async () => {
                            navigation.navigate(links.abs.map);
                        }
                    },
                    {
                        text: "Перейти на главную",
                        onPress: async () => {
                            navigation.navigate(links.abs.home);
                        },
                        style: "cancel",
                    },
                ],
                { cancelable: true }
            )
        }


        if(!partner.data?.id){
            //partnerSync(router.query.partnerId)
        }



        return () => {
            //partnerClear()
        }
    }, [router.query])

    const onShowNotAvailableMessageIsClosed = async (partnerId) => {
        const response = await partnerService.fetchPartner(partnerId);
        showDeliveryNotAvailableMessage(addressLabel, response);
    }

    useEffect(() => {
        if (isMiniSite) {
            if (partner.data.id) {
                showDeliveryNotAvailableMessage(addressLabel, partner.data);
            }
        } else {
            if(partner.data.alias === router.query.partnerId){
                onShowNotAvailableMessageIsClosed(router.query.partnerId)
            }
        }

    }, [partner.data]);

    useEffect( () => {
        if(partner.data.alias === router.query.partnerId) {
            (async (partnerId) => {
                const response = await partnerService.fetchPartnerMiniSite(partnerId);
                showDeliveryNotAvailableMessage(addressLabel, response);

                if (!checkBlockedPartner(partner.data) && (response?.id && !response?.canDelivered)) {
                    showNotCanDelivered(
                        response,
                        map.data.address,
                        () => {
                            //window.location.href = links.abs.partners;
                        },
                        () => {
                            navigation.navigate(links.abs.map, {
                                return_url: combineLink(
                                    links.abs.partners,
                                    response.alias,
                                ),
                            });
                        },
                    );
                }

            })(partner.data.id);
        }
    }, [partner.data.id])

    useEffect(() => {
        if(partner.error){
            Alert.alert(
                partner.error?.message,
                '',
                [
                    {
                        text: "Указать адрес",
                        onPress: async () => {
                            navigation.navigate(links.abs.map);
                        }
                    },
                    {
                        text: "Перейти на главную",
                        onPress: async () => {
                            navigation.navigate(links.abs.home);
                        },
                        style: "cancel",
                    },
                ],
                { cancelable: true }
            )
        }
    }, [partner.error])


    const renderDeliveryItem = () => {
        switch (deliveryMethod?.key) {
            case DeliveryMethod.Edostav:
                return {
                    icon: 'CourierIcon',
                    name: deliveryDuration,
                    textColor: 'green',
                    onClick: () => openBottomSlideByNumber(3),
                }
            case DeliveryMethod.Taxi:
                return {
                    icon: 'TaxiIcon',
                    name: deliveryDuration,
                    textColor: 'green',
                    onClick: () => openBottomSlideByNumber(3),
                }
            default:
                return {
                    name: deliveryDuration,
                    textColor: 'green',
                    onClick: () => openBottomSlideByNumber(3),
                }
        }
    };

    const openBottomSlideByNumber = (number) => {
        actions.onOpen();
        setTypeBottomSlide(number)
    }

    return (
        <>
            <Card variant='secondary' data-cy={'partner-open'}>
                <ContentMediaView>
                    <PartnerHeader
                        onClickInfo={() => openBottomSlideByNumber(4)}
                        isLoading={partner.isLoading}
                        data={{
                            day: getWorkingDayRelativelyToday(workDays, workTime),
                            name: name,
                            stickers: [
                                ...(!isMiniSite ? ((isNewPartner || reviewCount < 10) ? [
                                    {
                                        icon: 'StarGoldIcon',
                                        name: `Новый`,
                                        onClick: onGoReview
                                    }
                                ] : [
                                    {
                                        icon: 'StarGoldIcon',
                                        name: `Отзывы ${rating} (${reviewCount})`,
                                        onClick: onGoReview
                                    }
                                ]) : []),
                                renderDeliveryItem(),
                                /*...(!!minSum ? [{name: `Заказ от ${minSum}`}] : []),*/
                                ...(!!useKassa ? [
                                        {
                                            icon: "MastercardIcon",
                                            name: "Оплата картой",
                                            onClick: () => openBottomSlideByNumber(2),
                                        },
                                    ]
                                    : []),
                                ...((!!useBonus && !isMiniSite) ? [
                                        {
                                            icon: "BonusFillIcon",
                                            name: "Оплата баллами",
                                            onClick: () => openBottomSlideByNumber(1),
                                        },
                                    ]
                                    : []),
                                ...(!!isHalal ? [
                                        {
                                            icon: "HalalIcon",
                                            name: "Халяль",
                                            onClick: () => openBottomSlideByNumber(5),
                                        },
                                    ]
                                    : [])
                            ],
                            time: `${startWorkingDay}-${endWorkingDay}`
                        }}
                        mb=""
                    />

                    <DiscountPartnerShort
                        discount={partner.data.promoGifts}
                    />
                </ContentMediaView>
            </Card>
            <ContentMediaView>


                <Menu onOpenBonusSlide={() => openBottomSlideByNumber(1)} />
                <Card>
                    <ContentStyle>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: htmlExtra?.seo.text
                            }}></div>
                    </ContentStyle>
                </Card>
            </ContentMediaView>


            <SlidePanel
                onDismiss={actions.onClose}
                initSheet={setActions}
                isPadding={true}
            >
                {
                    typeBottomSlide === 1 && (
                        <BottomSlideContent
                            icon='BonusFillIcon'
                            head='Оплата баллами'
                            note={`Выберите одно из предложенных блюд за баллы при оформлении заказа от ${minSumFoodPoints} ₽`}
                            buttonText='Понятно'
                            buttonClick={actions.onClose}
                            isBorder
                        />
                    )
                }

                {
                    typeBottomSlide === 2 && (
                        <BottomSlideContent
                            icon='MastercardIcon'
                            head='Оплата картой'
                            note={`Заказ можно оплатить картой онлайн`}
                            buttonText='Понятно'
                            buttonClick={actions.onClose}
                            isBorder
                        />
                    )
                }

                {
                    typeBottomSlide === 3 && (
                        <BottomSlideContent
                            buttonText='Понятно'
                            buttonClick={actions.onClose}
                        >
                            <List
                                head={`Заказ доставит ${name}`}
                                isItemBorder
                                items={[
                                    ...(freeDeliverySum > 0 ?
                                        [
                                            {
                                                label: <>Заказ до  <PointPrefix>{freeDeliverySum - 1}</PointPrefix></>,
                                                value: <>
                                                    {deliveryMethod?.key === DeliveryMethod.Taxi ? 'от' : ""}
                                                    <PointPrefix>{deliveryCost}</PointPrefix>
                                                </>,
                                                valueVariant: 'text',
                                            },
                                            {
                                                label: <>Заказ от <PointPrefix>{freeDeliverySum}</PointPrefix></>,
                                                value: 'Бесплатно',
                                                valueVariant: 'text',

                                            }
                                        ] : [
                                            {
                                                label: <>Заказ от <PointPrefix>{minSum}</PointPrefix></>,
                                                value: <>
                                                    {deliveryMethod?.key === DeliveryMethod.Taxi ? 'от' : ""}
                                                    <PointPrefix>{deliveryCost}</PointPrefix>
                                                </>,
                                                valueVariant: 'text',
                                            },

                                        ]),
                                    {
                                        label: 'Минимальная сумма заказа',
                                        value: <PointPrefix>{minSum}</PointPrefix>,
                                        valueVariant: 'text',
                                        colorLabel: 'gray',
                                        colorValue: 'gray'
                                    },
                                    ...(deliveryMethod?.key === DeliveryMethod.Taxi ? [
                                            {
                                                label: 'Доставка до подъезда, возможна доплата за подъем на этаж. Итоговую стоимость доставки сообщит водитель.',
                                                valueVariant: 'text',
                                                colorLabel: 'gray',
                                            },
                                        ]
                                        : []),

                                ]

                                }
                            ></List>
                        </BottomSlideContent>
                    )
                }


                {
                    typeBottomSlide === 4 && (
                        <BottomSlideContent
                            head={name}
                            buttonText='Понятно'
                            buttonClick={actions.onClose}
                        >
                            <List
                                isItemBorder
                                variant={'secondary'}
                                items={[
                                    {
                                        label: <>{physicalAddress} <br/> {openWorkDays}, {openWorkHours}</>,
                                        valueVariant: 'text',
                                    },
                                    ...(specializations ? [{
                                        label: specializations,  valueVariant: 'text',
                                    }] : []),
                                    ...(legalInfo ? [{
                                        label: legalInfo,  valueVariant: 'text', colorLabel: 'gray'
                                    }] : [])

                                ]}
                            ></List>
                        </BottomSlideContent>
                    )
                }
                {
                    typeBottomSlide === 5 && (
                        <BottomSlideContent
                            icon='HalalIcon'
                            head='Халяль'
                            note={`"${name}" заявляет, что использует мясную продукцию халяль`}
                            buttonText='Понятно'
                            buttonClick={actions.onClose}
                            isBorder
                        />
                    )
                }

            </SlidePanel>
        </>
    )

}


export default PartnerOpen
