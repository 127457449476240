import React, {useCallback, useEffect, useState} from 'react';
import * as S from './styles';
import Checkbox from 'shared/Checkbox';
import Typography from 'shared/Typography';

const OptionsItemComponent = ({modifier, onChange, optionGroup, value}) => {
    const {id, name, price} = modifier;
    const [checked, setChecked] = useState(value && value.includes(id));
    const handleChange = (e) => {
        const status = e.currentTarget.checked
        onChange(id, optionGroup, status);
    };

    useEffect(() => {
        setChecked(value && value.includes(id));
    }, [value]);

    //const label = price === 0 ? `${name}` : `${name} + ${price} руб.`;

    const renderLabel = useCallback(() => {


        return <S.Row>{name} {price !== 0 && <Typography variant="text" color='gray' >+ {price} руб.</Typography>}</S.Row>
    }, [price])


    return (
        <S.Container data-cy={'options-item'}>
            <Checkbox
                type="checkbox"
                rowlabel={renderLabel()}
                id={id}
                input={{
                    onChange: handleChange,
                    value: checked,
                }}
                stylesCheckbox={{
                    marginLeft: 'auto',
                }}
            />
        </S.Container>
    );
};

export default OptionsItemComponent;
