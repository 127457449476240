import React, {useEffect} from 'react';
import {usePromotion} from 'modules/Promotion/hooks'
import PromotionListCarusel from 'shared/Promotion/components/PromotionList' //TODO перекинуть в модуль
import PromotionLastItem from 'shared/Promotion/components/PromotionLastItem' //TODO перекинуть в модуль
import EmptyContent from "shared/EmptyContent";
import Link from "shared/Link";
import {links} from "helper";
import Typography from "shared/Typography";
import Card from "shared/Card";
import Button from "shared/Button";
import ContentMediaView from "shared/ContentMediaView";
import DeviceDetect, {checkMobile, useMobileContextDetect} from "shared/DeviceDetect";
import {RowStyle} from "./styles";
import PromotionPrimarySkeletonLoader from "shared/skeleton/PromotionPrimarySkeletonLoader";
import HeadSkeletonLoader from "shared/skeleton/HeadSkeletonLoader";

let PromotionList = () => {
    const isMobile = useMobileContextDetect();
    const {
        specialPromoGifts,
        onSpecialPromoGifts
    } = usePromotion()

    useEffect(() => {
        if(!specialPromoGifts?.data?.length){
            onSpecialPromoGifts()
        }
    }, []);


    if(specialPromoGifts.isLoading) {
        return (
            <Card>
                <ContentMediaView>
                    <HeadSkeletonLoader/>
                    <PromotionPrimarySkeletonLoader />
                </ContentMediaView>
            </Card>
        );
    }

    return (
        <EmptyContent isEmpty={!specialPromoGifts.data.length}>
            <Card>
                <ContentMediaView>
                    <Link href={links.abs.discount} passHref>
                        <a>
                            <RowStyle>
                                <Typography
                                    color="black"
                                    variant={isMobile ? "h2" : "h1"}
                                    mb={isMobile ? 0 : 2}
                                >
                                    Акции
                                </Typography>
                                <DeviceDetect device={'desktop'}>
                                    <Button
                                        variant={'more'}
                                        icon={"ArrowRightLongIcon"}
                                        iconPosition={"right"}
                                    >
                                        <Typography variant='textSmall' >Показать все</Typography>
                                    </Button>
                                </DeviceDetect>
                            </RowStyle>
                        </a>
                    </Link>
                </ContentMediaView>
            </Card>
            <PromotionListCarusel
                data={[...specialPromoGifts.data?.slice(0, 9)]}
                mb={2}
                variant="primary"
                data-cy={'promotion-list'}
            >
                <DeviceDetect>
                    <PromotionLastItem />
                </DeviceDetect>
            </PromotionListCarusel>
        </EmptyContent>
    )
}



export default PromotionList
