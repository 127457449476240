import {useCallback, useState} from 'react'
import {useAppDispatch} from 'store'
import Map from "modules/Map";
import {useSelector} from "react-redux";
import Alert from "shared/Alert";
import {useCity, useMap} from 'modules/Map/hooks';
import {links} from "helper";
import navRoute from "helper/navRoute";
import {useRouter} from "next/router";



const useAddress = () => {
    const navigation = navRoute()
    const address = useSelector((state) => state.address);
    const addressFormValues = useSelector((state) => state.form?.fullAddress?.values);
    const dispatch = useAppDispatch();
    const {cities} = useCity();
    const {addMapInfo, onFetchMainData} = useMap();
    const [selectedAddressId, setSelectedAddressId] = useState(null);
    const router = useRouter()
    const query: any = router.query;
    const updateAddressId: any = query.addressId ? parseInt(query.addressId) : null;

    const onSelectAddress = useCallback((id) => {
        setSelectedAddressId(id)
    }, [setSelectedAddressId])


    const onSyncAddress = useCallback(() => {
        dispatch(Map.store.address.actions.addressSync())
    }, [])

    const generateAddress = useCallback((item, withCity) => {
        if(!item) return

        return `
        ${withCity ? getCityName(parseInt(item.cityId)) + ', ' : ''}
        ${item.street}, ${item.house}`
    }, [cities])

    const onCreateAddress = useCallback((data) => {
        dispatch(Map.store.address.actions.createAddress(data))
        onSyncAddress()
    }, [])

    const onUpdateAddress = useCallback((data, addressId) => {
        dispatch(Map.store.address.actions.updateAddress(data, addressId))
        onSyncAddress()
    }, [])

    const onRemoveAddress = useCallback(async (id) => {
        await dispatch(Map.store.address.actions.deleteAddress(id))
    }, [])

    const handleRemoveAddress = useCallback((id) => {
        Alert.alert(
            'Вы уверены, что хотите удалить адрес?',
            '',
            [
                {
                    text: "Да",
                    onPress: async () => {
                        await onRemoveAddress(id)
                        onSyncAddress()
                    }
                },
                {
                    text: "Отмена",
                    style: "cancel",
                },
            ],
            { cancelable: false }
        );
    }, [])

    const getAddressData = useCallback(() => {

        return address?.data?.map(item => ({address: generateAddress(item, true), onClick: () => handleRemoveAddress(item.id)}))
    }, [address])

    const generateFullAddress = useCallback((selectedAddress: any) => {
        const address = `${selectedAddress?.street}${selectedAddress?.house &&
        `, ${selectedAddress?.house}`}`;
        const cityName = cities.find(({ id }) => id === selectedAddress.cityId)?.name;
        return {
            id: selectedAddress.id,
            latitude: selectedAddress.coordinates?.latitude,
            longitude: selectedAddress.coordinates?.longitude,
            address: address,
            addressObj: {
                city: cityName && cityName,
                street: selectedAddress?.street,
                house: selectedAddress?.house,
                cityId: selectedAddress?.cityId,
                number: selectedAddress?.number,
                podezd: selectedAddress?.podezd,
                storey: selectedAddress?.storey,
                domofon: selectedAddress?.domofon,
            },
        };
    }, [cities])

    const onSelectMyAddress = (notRedirect = false) => {

        const selectedAddress = address.data.find((address) => address.id === selectedAddressId);

        if (selectedAddress) {
            const fullAddress = generateFullAddress(selectedAddress);
            addMapInfo(fullAddress)
            if(!notRedirect){
                window.location.href = links.abs.partners
            }
        }
    };

    const goCreateAddress = useCallback(() => {
        navigation.navigate(links.abs.map);
    }, []);

    const goUpdateAddress = useCallback((addressId) => {
        navigation.navigate(links.abs.map, {addressId});
    }, []);


    const onSubmitMyAddress = useCallback(async (values) => {

        /*const data = {
            ...values,
            coordinates: {
                latitude: Number(latitude),
                longitude: Number(longitude),
            }
        }*/

        if (!updateAddressId) {
            onCreateAddress(values)
        } else {
            onUpdateAddress(values, updateAddressId)
        }
        if(query.return_url) {
            window.location.href = query.return_url
        } else {
            window.location.href = links.abs.partners
        }
        //await navigation.navigate(links.abs.partners);
        onFetchMainData()
    }, [updateAddressId, query])


    const onRemoteSubmitAddressForm = useCallback(() => {
        onSubmitMyAddress(addressFormValues)
    }, [addressFormValues])

    const getCityName = useCallback((cityId) => {


        return cities.find(({id}) => id === cityId)?.name;
    }, [cities])

    const getUserAddressById = useCallback((addressId) => {
        const addressUser = address.data.find(({id}) => id === addressId)
        //console.log('addressUser', addressUser)

        return addressUser;
    }, [address])

    return {
        onSyncAddress,
        onRemoveAddress,
        onCreateAddress,
        address,
        getAddressData,
        generateAddress,
        onSelectMyAddress,
        onSelectAddress,
        goCreateAddress,
        onSubmitMyAddress,
        onRemoteSubmitAddressForm,
        getCityName,
        goUpdateAddress,
        updateAddressId,
        getUserAddressById
    }
}

export default useAddress
