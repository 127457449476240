import React,{useState} from 'react';
import * as S from './styles'
import OptionsGroup from '../OptionsGroup'


const Options = ({options, input}) => {
    const [isVisible, setIsViseble] = useState(true)
    const handleChange = async (id, optionGroup, status) => {

        setIsViseble(false)
        let options = Object.assign([], input.value || [])

        if(typeof status ===  'boolean' && status){
            options.push(id)
        }else if(typeof status ===  'boolean' && !status){
            options = options.filter(item => item !== id)
        }

        const groupHasModifiers = optionGroup.modifiers.filter(item => options.includes(item.id))

        if(optionGroup.max_amount < groupHasModifiers.length && optionGroup.max_amount !== 0){
            let tempOptionGroup = options.filter(item => optionGroup.modifiers.find(mod => mod.id === item))


            options = options.filter(item => !(optionGroup.modifiers.find(mod => mod.id === item)))
            tempOptionGroup.shift()


            options = [...options, ...tempOptionGroup]
        }

        //reactlog('status', status)


        await input.onChange(options)

        setTimeout(() => {
            setIsViseble(true)
        })
    }

    useState(() => {
        if(options.length){
            const optionsDefault = [];
            options.forEach((optionsGroup) =>
                optionsGroup.modifiers?.forEach((option) =>
                    option.default_amount
                        ? optionsDefault.push(option.id)
                        : false,
                ),
            );

            if(optionsDefault.length){
                input.onChange(optionsDefault)
            }

        }
    }, [options]);

    return (
        <>
            <S.Container data-cy={'options'}>
                {
                    options.map(item =>
                        <OptionsGroup
                            isVisible={isVisible} value={input.value} onChange={handleChange} key={item.id} optionGroup={item}/>)
                }
            </S.Container>
        </>

    )
}

Options.defaultProps = {
    options: [],
    input: {},
}

export default Options;
