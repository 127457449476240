import styled, {css} from 'styled-components';
import { Fonts, Colors } from 'common/Theme';
import Helpers from 'common/Theme/Helpers';

export const WrapperStyle = styled.div`
  padding: 16px 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  ${Helpers.css.flex};
  ${Helpers.css.flex_ac};
  //margin-bottom
  ${props => props.mb && css`
      margin-bottom: ${Helpers.css.mb[props.mb]};
  `}
  //end margin-bottom
`;

export const TopStyle = styled.div`
  ${Helpers.css.flex};
  ${Helpers.css.flex_sb};
  ${Helpers.css.flex_ab};
  margin-bottom: 10px;
`;

export const IconWrapStyle = styled.div`
  ${Helpers.css.flex_inline};
  ${Helpers.css.flex_center};
  margin-right: 18px;
`;

export const DateStyle = styled.div`
  
`;

export const IconStyle = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  height: 40px;
  width: 40px;
  border-radius: 50%;
  ${Helpers.css.flex};
  ${Helpers.css.flex_center};
`;

export const ContentStyle = styled.div`
  ${Helpers.css.flex};
  ${Helpers.css.flex_vertical};
  ${Helpers.fullWidth};
`;

export const TitleStyle = styled.div`

`;

export const BonusStyle = styled.div`
  
`;

export const OrderStyle = styled.div`
`;

export const ReviewStyle = styled.div`
  ${Helpers.css.flex_inline};
  ${Helpers.css.flex_ac};
  ${Helpers.css.cursor_pointer};
`;

export const BottomStyle = styled.div`
  ${Helpers.css.flex};
  ${Helpers.css.flex_ab};
  ${Helpers.css.flex_sb};
`;
