import React, {lazy, Suspense, memo} from "react";
import {WrapperStyle} from "./styles";

const Icon: React.FC = ({name, color, size, rotate, ...rest}) => {
    const IconSvg = lazy(() => import(`common/Icons/${name}`));


    return (
        <WrapperStyle rotate={rotate} color={color} data-cy={'icon'}>
            {
                typeof window !== "undefined" && IconSvg && <Suspense  fallback={<div></div>}><IconSvg
                    {...(size ? {fontSize: size} : {})}
                    {...rest} /></Suspense>
            }
        </WrapperStyle>
    );
};

//export default Icon;
export default memo(Icon);