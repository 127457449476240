import React, {useEffect} from "react";
import Cart from "modules/Cart";
import Partner from "modules/Partner";
import HeaderPartner from "shared/HeaderPartner";
import Typography from "shared/Typography";
import Button from "shared/Button";
import Card from "shared/Card";
import EmptyContent from "shared/EmptyContent";
import ContentMediaView from "shared/ContentMediaView";
import DeviceDetect, {useMobileContextDetect} from "shared/DeviceDetect";
import CartProgressBar from "modules/Cart/components/CartProgressBar";


const CartContent = ({onCloseCartPop, isHideHeader}) => {
    const {cart, onClearCart, onGoOrder} = Cart.hooks.useCart()
    const {partnerSync, partner} = Partner.hooks.usePartnerOpen()
    const {CartList, CartItogComponent, CartDrinksList} = Cart.components

    const handleGoToOrder = () => {
        onCloseCartPop && onCloseCartPop()
        onGoOrder()
    }

    useEffect(() => {
        if(cart?.data?.partner?.id && !partner?.data?.id){
            partnerSync(cart?.data?.partner?.id)

        }

        return () => {
            //partnerClear()
        }
    }, [cart])

    return (
        <>
            {
                !isHideHeader && (
                    <DeviceDetect device={'desktop'}>
                        <HeaderPartner
                            head={'Корзина'}
                            headColor={'green'}
                            items={[
                                {
                                    icon: 'DeleteIcon',
                                    onClick: onClearCart
                                },
                            ]}
                            mb={1}
                        />
                    </DeviceDetect>
                )
            }
            <EmptyContent headVariant={'h2'} head={"Корзина пуста"} isEmpty={!cart.data.cartItems?.length}>
                <ContentMediaView view={'tablet'}>
                    <Card>
                        <Typography
                            variant="h2"
                            mb={2}
                        >
                            Содержание заказа
                        </Typography>
                        <CartList />

                        <CartItogComponent cart={cart.data} />

                        <CartProgressBar/>

                        {
                            Boolean(cart.data?.drinks?.length) && (
                                <>
                                    <Typography
                                        variant="h2"
                                        mb={2}
                                    >
                                        Добавить напитки
                                    </Typography>
                                    <CartDrinksList data={cart.data?.drinks}/>
                                </>
                            )
                        }
                        <br/>
                        <br/>

                        <Button
                            component="button"
                            fullSize
                            size="big"
                            variant="primary"
                            onClick={handleGoToOrder}
                        >
                            Далее
                        </Button>
                    </Card>
                </ContentMediaView>

            </EmptyContent>
        </>
    );
};


export default CartContent
