import styled, {css} from 'styled-components';
import {Fonts, Colors} from 'common/Theme';
import Helpers from 'common/Theme/Helpers';

export const WrapperStyle = styled.div`
  overflow: hidden;
  padding-bottom: 50px;
`;


export const ContentStyle = styled.div`
  max-height: 137px;
  overflow: hidden;

  ${props => props.isOpen && css`
    max-height: unset;
  `}

  @media (min-width: 768px) {
    column-count: 2;
    gap: 90px;
    padding: 0 50px;
  }
  
  h1 {
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #000000;


    @media (min-width: 768px) and (max-width: 1550px) {
      font-size: 38px;
      line-height: 42px;
    }
    
    @media (min-width: 1550px) {
      font-size: 42px;
      line-height: 48px;
    }

    
  }

  h2 {
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #000000;

    @media (min-width: 768px) and (max-width: 1550px) {
      font-size: 28px;
      line-height: 32px;
    }

    @media (min-width: 1550px) {
      font-size: 32px;
      line-height: 38px;
    }
  }

  h3 {
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    @media (min-width: 768px) and (max-width: 1550px) {
      font-size: 28px;
      line-height: 32px;
    }

    @media (min-width: 1550px) {
      font-size: 32px;
      line-height: 38px;
    }

    color: #000000;
  }

  p {
    font-size: 14px;
    line-height: 17px;
    margin: 8px 0 8px 0;
    color: #000000;
    margin-bottom: 25px;

    @media (min-width: 768px) {
      font-size: 17px;
      line-height: 28px;
    }
  }


  ul {
    margin: 0 0 7px;
    padding: 0 0 0 20px;
    position: relative;
  }

  li {
    list-style: none;
    margin-top: 3px;
    padding: 0 0 0 20px;
    position: relative;
    font-size: 14px;
    line-height: 17px;
    margin: 8px 0 8px 0;
    color: #000000;

    @media (min-width: 768px) {
      font-size: 17px;
      line-height: 28px;
    }

    &:before {
      background: #6C9F39;
      border: 1px solid #6C9F39;
      content: '';
      display: block;
      height: 5px;
      width: 5px;
      position: absolute;
      left: 0;
      top: 11px;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      border-radius: 3px;
    }
  }
  
  strong {
    font-weight: bold;
  }

`;

export const BtnStyle = styled.div`
  padding: 5px 16px
`;


