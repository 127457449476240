import  React from 'react';
import ImageUrl from 'shared/ImageUrl';
import Typography from 'shared/Typography';
import useMenu from "../../hooks/useMenu";
import AddCartForm from 'modules/Cart/components/AddCartForm';
import {
    WrapStyle,
    ImgWrapStyle,
    TitleWrapStyle,
    TitleLeftStyle,
    TitleRightStyle,
    ContentStyle
} from './styles';


const ProductFullContent = ({slideClose, page}) => {
    const {productOpen, getProductPrice} = useMenu()
    const priceProduct = getProductPrice(productOpen)


    return (
        <WrapStyle>
            <ImgWrapStyle>
                <ImageUrl src={productOpen.image}/>
            </ImgWrapStyle>
            <ContentStyle>
                <TitleWrapStyle>
                    <TitleLeftStyle>
                        <Typography variant="h3">{productOpen.name}</Typography>
                    </TitleLeftStyle>
                    <TitleRightStyle>
                        <Typography variant="small">{priceProduct}</Typography>
                    </TitleRightStyle>
                </TitleWrapStyle>
                <Typography variant="textSmall" color='gray' mb={2}>{productOpen.description}</Typography>
                <AddCartForm page={page} slideClose={slideClose} />
            </ContentStyle>

        </WrapStyle>
    )
}


export default ProductFullContent