import { createSelector } from 'reselect';
import { NAME } from '../constans';
import { partnerState } from '../reducer/partner/reducer';

export const partnerStateSelector = (store: { partner: { partner: partnerState }}) => store[NAME];

export const partnerSelector = createSelector(
    partnerStateSelector,
    (state) => state.partner
);

export const partnerDataSelector = createSelector(
    partnerStateSelector,
    (state) => state.partner.data
);

export const currentPartnerIdSelector = createSelector(
    partnerStateSelector,
    (state) => state.partner.data?.id
);

