/**
 * This file contains the application's colors.
 *
 * Define color here instead of duplicating them throughout the components.
 * That allows to change them more easily later on.
 */

export default {
    transparent: 'rgba(0,0,0,0)',
    // Example colors:
    white: '#ffffff',
    black: '#000000',
    gray: '#767676',
    grayButton: '#EBEBEB',
    grayMid: '#d7d6d6',
    grayLight: '#FAFAFA',
    primary: 'rgba(0, 0, 0, 0.08)',
    green: '#6C9F39',
    yellow: '#F8CA53',
    red: '#F04343',
    bg: '#F5F5F5',
    yellowLight: '#fff6e8',
}
