import React from 'react';
import {WrapperStyle} from "./styles";
import BonusItem from "shared/Bonus/components/BonusItem";

const OrderList = ({
                       data,
                       mb,
                        ...rest
                  }) => {


    return (
        <>
            <WrapperStyle
                data-cy={'bonus-list'}
                mb={mb}
                {...rest}
            >
                {data.map((dat, i) => (
                    <BonusItem key={i} data={dat}></BonusItem>
                ))}
            </WrapperStyle>
        </>
    );
};

export default OrderList;
