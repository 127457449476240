import styled, {css} from 'styled-components';



export const WrapStyle = styled.div`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 4;
`;