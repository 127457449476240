import moment from "moment";
import React from "react";
import {connect} from "react-redux";
import {Field, reduxForm} from "redux-form";
import Input from "shared/Input";
import {birthday, email, required} from "helper/validation";
import { useProfile } from "../../hooks";
import * as S from "./style";
import Button from "shared/Button";
import InputSelect from "shared/InputSelect";
import IMask from "imask";



const dateMask = IMask.createMask({
    mask: Date,
});


const ProfileForm = ({handleSubmit, pristine}: any) => {
    const {onSubmit} = useProfile()

    return (
            <form data-cy={'profile-form'} onSubmit={handleSubmit(onSubmit)}>
                <S.FormView>
                    {/* <Field
          name="Profile[avatar]"
          component={ImageInput}
          type="file"
        /> */}
                    <Field
                        name="Profile[name]"
                        component={Input}
                        type="text"
                        label="Имя"
                        mb="3"
                    />
                    <Field
                        name="Profile[email]"
                        component={Input}
                        type="text"
                        validate={email}
                        label="Email"
                        mb="3"
                    />
                    <Field
                        name="Profile[phone]"
                        component={Input}
                        type="number"
                        validate={required}
                        required
                        label="Телефон"
                        editable={false}
                        mb="3"
                        readOnly={true}
                    />
                    {/*<Field
          name="Profile[sex]"
          component={UDSelectPicker}
          placeholder="Пол"
          items={GENDER_OPTIONS}
        />*/}
                    <Field
                        name="Profile[sex]"
                        component={InputSelect}
                        label="Пол"
                        options={[{value: 'Муж', label: 'Муж'}, {value: 'Жен', label: 'Жен'}]}
                        mb="3"
                    />
                    <Field
                        name="Profile[birthday]"
                        component={Input}
                        //type="number"
                        validate={birthday}
                        //normalize={dateMask}
                        //normalize={(data) => normalizeDate(data)}
                        format={(data) => data && dateMask.resolve(data)}
                        //format={(data) => data && dateParse(data)}
                        theme={'birth'}
                        label="Дата рождения"
                        mb="4"
                    />
                    {/*<S.PromoView>
          <S.PromoLabel>Реклама и акции</S.PromoLabel>
          <Field
            name="Profile[isPushEnabled]"
            component={Button}
            placeholder="Push-уведомления"
            rowlabel="Push-уведомления"
          />
          <Field
            name="Profile[isEmailEnabled]"
            component={Button}
            rowlabel="Email рассылка"
          />
        </S.PromoView>*/}
                    <Button fullSize>Сохранить</Button>
                    {/*<button*/}
                    {/*    //onClick={handleSubmit}*/}
                    {/*    //disabled={pristine}*/}
                    {/*    component={Button}*/}
                    {/*    // disableTheme={"#B7B7B7"}*/}
                    {/*    fullSize*/}
                    {/*    type='submit'>*/}
                    {/*    Сохранить*/}
                    {/*</button>*/}
                </S.FormView>
            </form>
        )
}

const mapState = (state: any) => {
    //const profile = profileSelector(state);
    const profile = state.profile.data;
    const {
        name = '',
        email = '',
        phone = '',
        sex = '',
        birthday = '',
        isPushEnabled,
        isEmailEnabled,
    } = profile;
    const formattedBirth = !!birthday ? moment(birthday, "YYYY-MM-DD").format("DD.MM.YYYY") : '';

    return {
        initialValues: {
            Profile: {
                name,
                phone: '' + phone,
                email,
                sex,
                birthday: formattedBirth,
                isPushEnabled,
                isEmailEnabled
            },
        },
    };
};

export default connect(mapState)(
    reduxForm({
        form: 'profile',
        enableReinitialize: true,
    })(ProfileForm)
);


