import styled, {css} from 'styled-components';

export const WrapperStyle = styled.div`


  @media screen and (min-width: 1500px){
    max-width: ${({view}) => view === 'desktop' ? '1500px' : view === 'tablet' ? '1000px' : view === 'mobile' ? '768px' : null};
    margin: auto;
  }

  @media screen and (max-width: 1550px) and (min-width: 1350px){
    max-width: ${({view}) => view === 'desktop' ? '1300px' : view === 'tablet' ? '1000px' : view === 'mobile' ? '768px' : null};
    margin: auto;
  }

  @media screen and (max-width: 1350px) and (min-width: 768px){
    max-width: ${({view}) => view === 'desktop' ? '1150px' : view === 'tablet' ? '1000px' : view === 'mobile' ? '768px' : null};
    margin: auto;
  }
  
  
  
 /* @media (min-width: ${props => props.view === 'desktop' ? '768px' : null}) {
    max-width: 1500px;
    margin: auto;
  }

  @media (min-width: ${props => props.view === 'tablet' ? '768px' : null}) {
    max-width: 1000px;
    margin: auto;
  }

  @media (min-width: ${props => props.view === 'mobile' ? '768px' : null}) {
    max-width: 500px;
    margin: auto;
  }*/
`;
