import styled, {css} from 'styled-components';
import { Fonts, Colors } from 'common/Theme';
import Helpers from 'common/Theme/Helpers';

export const WrapStyle = styled.div`
  margin: auto;
  max-width: 400px
`;

export const ImgWrapStyle = styled.div`
  width: 100%;
  height: 300px;
  width: 300px;
  position: relative;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
`;

export const BtnWrapStyle = styled.div`
   ${Helpers.css.flex};
   ${Helpers.css.flex_ac};
`;
export const BtnLeftStyle = styled.div`
  
  
`;
export const BtnRightStyle = styled.div`
  ${Helpers.css.flex_grow};
`;

export const TitleWrapStyle = styled.div`
   ${Helpers.css.flex};
   ${Helpers.css.flex_ac};
   margin-bottom: 16px;
`;

export const TitleLeftStyle = styled.div`
`;

export const TitleRightStyle = styled.div`
  margin-left: auto;
`;

export const ContentStyle = styled.div`
  padding: 16px;
`;