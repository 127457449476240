import React from 'react';
import {InfoStyle, IconStyle, WrapperStyle} from "./styles";
import Icon from "shared/Icon";

const iconVariant = {
    info: "InfoFillIcon",
    danger: "InfoFillIcon",
    success: "CheckIcon",
    note: "InfoFillIcon",
};

const AlertBlock = ({
                        children,
                        variant = 'info',
                        iconName,
                        mb,
                        color,
                        ...rest
                  }) => {

    return (
        <>
            <WrapperStyle
                data-cy={'alert-block'}
                variant={variant}
                mb={mb}
                {...rest}
            >
                <IconStyle>
                    <Icon name={iconName || iconVariant[variant]}/>
                </IconStyle>
                <InfoStyle variant={variant} color={color}>
                    {children}
                </InfoStyle>

            </WrapperStyle>
        </>
    );
};

export default AlertBlock;
