import React, { useEffect, useRef, useState, useCallback } from "react";
import { YMaps, Map, withYMaps } from "react-yandex-maps";
import { useMap, useAddress } from "../../hooks";
import {
    ContainerStyle,
    ButtonContainerStyle,
    EmptyDeliveryContainerStyle,
    MapButtonContainerStyle,
    MapAddressContainerStyle,
    MapCloseContainerStyle,
    SuggestContainerStyle,
    MapCenterWrapStyle,
    MapButtonRowStyle,
} from "./styles";
import Button from "shared/Button";
import Typography from "shared/Typography";
import MapAddress from "shared/MapAddress";
import Icon from "shared/Icon";
import SlidePanel, { useSlidePanel } from "shared/SlidePanel";
import { links } from "helper";
import navRoute from "helper/navRoute";
import { FullAddressForm } from "modules/Map/components";
import BottomSlideContent from "shared/BottomSlideContent";
import AddressSearchForm from "shared/AddressSearchForm";
import { useAuth } from "modules/User/hooks";
import CitiesListSlide from "modules/Map/components/CitiesListSlide";
import AlertBlock from "shared/AlertBlock";
import { AlertWrapStyle } from "shared/AddressSearchForm/styles";
import { useRouter } from "next/router";
import { useMobileContextDetect } from "shared/DeviceDetect";
import {
    API_KEY_GEO_WEB_DESKTOP,
    API_KEY_GEO_WEB_MOBILE,
    API_KEY_SUGGEST_WEB_DESKTOP,
    API_KEY_SUGGEST_WEB_MOBILE,
} from "modules/Map/constans";

//const SUGGEST_BASE_URL = 'http://suggest-maps.yandex.ru/suggest-geo?';

/*
const requestSuggest = async (part: string) => {
    if (typeof window === "undefined") {
        return
    }


    const query = {
        part,
        format: 'json',
        v: 5,
        search_type: 'tp',
        lang: 'ru_RU',
        n: '5',
        origin: 'jsapi2Geocoder',
    };

    const url = SUGGEST_BASE_URL + qs.stringify(query);
    return new Promise((resolve, reject) => {
        jsonp(url, null, (err, data) => {
            if (err) {
                console.error(err.message);
                reject(err.message)
            } else {
                resolve(data[1])
            }
        });

    })

    //console.log('githubResponse', githubResponse)

    //const dataTemp = await githubResponse?.text();
    //const [, searchingResults] = JSON.parse(dataTemp.substring(14, dataTemp.length - 1));

};
*/

function MapSuggestComponent(props) {
    const { ymaps, onSelectAddress } = props;

    React.useEffect(() => {
        const suggestView = new ymaps.SuggestView("suggest");
        suggestView.events.add(["select"], function (event) {
            //console.log('event', event.originalEvent.item.displayName)
            onSelectAddress(event.originalEvent.item.displayName);
        });
    }, [ymaps.SuggestView]);

    return <AddressSearchForm placeholder="Поиск" id="suggest" />;
}

const MapComponent = ({ showCloseButton = true }) => {
    const [ymaps, setYmaps] = useState(null);
    const {
        getPos,
        centerArr,
        setCurrentMapLocation,
        handleSelectAddress,
        mapData,
        onSelectAddress,
        hasDelivery,
        cities,
        onUpZoom,
        onDownZoom,
        map,
        setMapLocation,
    } = useMap(ymaps, true);
    const { street, house } = mapData?.addressObj || {};
    const { onRemoteSubmitAddressForm, updateAddressId, getUserAddressById } =
        useAddress();
    const { isAuth } = useAuth();
    const userAddressUpdating = getUserAddressById(updateAddressId);

    const navigation = navRoute();
    const onGoHome = useCallback(() => {
        navigation.navigate(links.abs.home);
    }, []);

    const [actions, setActions] = useSlidePanel();
    const [actionsAddress, setActionsAddress] = useSlidePanel();
    const actionsCityState = useSlidePanel();
    const [actionsCity] = actionsCityState;
    const router = useRouter();
    const query: any = router.query;

    const SuggestComponent = React.useMemo(() => {
        return withYMaps(MapSuggestComponent, true, [
            "SuggestView",
            "geocode",
            "coordSystem.geo",
        ]);
    }, []);

    const handleGetPos = useCallback((...args) => {
        getPos(...args);
    }, []);

    /* useEffect(() => {
        if(ymaps){
            ymaps.suggest('мыт').then(function (items) {
                console.log('items', items)
            });
        }
    }, [ymaps])*/

    const isInitialMount = useRef(true);
    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            if (map?.data?.latitude) {
                if (isAuth) {
                    actionsAddress.onOpen();
                    actions.onClose();
                } else {
                    if (query.return_url) {
                        window.location.href = query.return_url;
                    } else {
                        window.location.href = links.abs.partners;
                    }
                }
            }
        }
    }, [map.data.latitude, map.data.longitude]);

    useEffect(() => {
        if (userAddressUpdating) {
            setMapLocation(
                userAddressUpdating.coordinates.latitude,
                userAddressUpdating.coordinates.longitude,
            );
        }
    }, [userAddressUpdating, ymaps]);

    if (typeof window === undefined) {
        return <></>;
    }

    const isMobile = useMobileContextDetect();
    const API_KEY_GEO = isMobile
        ? API_KEY_GEO_WEB_MOBILE
        : API_KEY_GEO_WEB_DESKTOP;

    const API_KEY_SUGGEST = isMobile
        ? API_KEY_SUGGEST_WEB_MOBILE
        : API_KEY_SUGGEST_WEB_DESKTOP;

    return (
        <ContainerStyle data-cy={"map-page"}>
            {showCloseButton && (
                <MapCloseContainerStyle>
                    <Button
                        icon="CloseIcon"
                        href={links.abs.home}
                        size="normal"
                        variant="transparent"
                        onClick={onGoHome}
                    />
                </MapCloseContainerStyle>
            )}

            <MapAddressContainerStyle>
                <MapAddress onClick={actions.onOpen}>
                    {mapData.address}
                </MapAddress>
            </MapAddressContainerStyle>

            <YMaps
                query={{ apikey: API_KEY_GEO, suggest_apikey: API_KEY_SUGGEST }}
            >
                <MapButtonContainerStyle data-cy={"map-address-buttons"}>
                    <MapButtonRowStyle>
                        <Button
                            component="button"
                            icon="NavIcon"
                            isBorder
                            isShadow
                            onClick={setCurrentMapLocation}
                            size="normal"
                            variant="light"
                        />
                    </MapButtonRowStyle>

                    <MapButtonRowStyle>
                        <Button
                            component="button"
                            icon="PlusClearIcon"
                            isBorder
                            isShadow
                            onClick={onUpZoom}
                            size="normal"
                            variant="light"
                        />
                    </MapButtonRowStyle>

                    <MapButtonRowStyle>
                        <Button
                            component="button"
                            icon="MinusClearIcon"
                            isBorder
                            isShadow
                            onClick={onDownZoom}
                            size="normal"
                            variant="light"
                        />
                    </MapButtonRowStyle>
                </MapButtonContainerStyle>
                <MapCenterWrapStyle>
                    <Icon width={30} height={30} name="LocationIcon" />
                </MapCenterWrapStyle>
                {/*<div
                        style=
                            {{
                                position: 'absolute',
                                width: '8px',
                                height: '8px',
                                background: 'rgb(255 32 32)',
                                left: '50%',
                                top: '50%',
                                zIndex: 2,
                                borderRadius: '100%',
                                border: '1px solid #fff'
                            }}></div>*/}

                <Map
                    instanceRef={(map) => setYmaps(map)}
                    width={"100%"}
                    height={"100%"}
                    onBoundsChange={handleGetPos}
                    defaultState={{
                        center: centerArr,
                        zoom: 7,
                    }}
                />
                {mapData.address}

                <ButtonContainerStyle>
                    {hasDelivery ? (
                        <Button
                            component="button"
                            fullSize
                            size="normal"
                            variant="primary"
                            onClick={
                                street && house
                                    ? handleSelectAddress
                                    : actions.onOpen
                            }
                        >
                            {street && house ? "Я здесь" : "Указать адрес"}
                        </Button>
                    ) : (
                        Boolean(ymaps && mapData.address) && (
                            <EmptyDeliveryContainerStyle>
                                <Typography
                                    align="center"
                                    color="black"
                                    mb={2}
                                    variant="h2"
                                >
                                    Сюда еще не доставляют
                                </Typography>
                                <Button
                                    component="button"
                                    fullSize
                                    size="big"
                                    onClick={actionsCity.onOpen}
                                    variant="transparent"
                                    isBorder
                                >
                                    Выбрать другой город
                                </Button>

                                {/*<select>
                                    {
                                        cities?.map((item) => (
                                            <option value="">{item.name}</option>
                                        ))
                                    }
                                </select>*/}
                            </EmptyDeliveryContainerStyle>
                        )
                    )}
                </ButtonContainerStyle>

                <SlidePanel onDismiss={actions.onClose} initSheet={setActions}>
                    <SuggestContainerStyle>
                        <AlertWrapStyle>
                            <AlertBlock
                                color={"#6C9F39"}
                                iconName={"InfoFillGreenIcon"}
                            >
                                Введите город, улицу и номер дома
                            </AlertBlock>
                        </AlertWrapStyle>
                        <SuggestComponent onSelectAddress={onSelectAddress} />
                    </SuggestContainerStyle>
                </SlidePanel>

                <CitiesListSlide actions={actionsCityState} />

                <SlidePanel
                    initSheet={setActionsAddress}
                    blocking={true}
                    state={updateAddressId ? true : false}
                    onDismiss={actionsAddress.onClose}
                >
                    <BottomSlideContent
                        buttonText="Готово"
                        buttonClick={onRemoteSubmitAddressForm}
                        buttonVariant={"primary"}
                        is={"div"}
                    >
                        <FullAddressForm initialAddress={userAddressUpdating} />
                    </BottomSlideContent>
                </SlidePanel>
            </YMaps>
        </ContainerStyle>
    );
};

export default MapComponent;
