import Colors from 'common/Theme/Colors';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

export default function useProgressBar() {
  const [isBarLoading, setBarLoading] = useState(true);

  const cartData = useSelector(state => state.cart.data) as any;

  const haveMinSum = useMemo(
    () =>
      cartData.progressBar &&
      cartData.progressBar[0] &&
      cartData.progressBar[0].state === 'completed',
    [cartData.progressBar],
  );

  const numSteps = useMemo(() => cartData.progressBar.length, [
    cartData.progressBar.length,
  ]);

  const maxValue = useMemo(() => {
    const num = numSteps - 1;
    return cartData.progressBar[num]?.value;
  }, [cartData.progressBar, numSteps]);

  const progress = useMemo(() => {
    //реальный коэффициент прогресса
    const value = cartData.total / maxValue;
    const data = cartData.progressBar;
    const completedSteps = data.filter(i => i.state === 'completed');
    //имитация приближенных значений для визуала
    if (completedSteps) {
      if (completedSteps.length === 0) {
        return value > 0.2 ? 0.2 : value;
      }
      if (completedSteps.length === 1) {
        return value < 0.3 ? 0.3 : value;
      }
      if (completedSteps.length === 2) {
        return value < 0.55 ? 0.6 : value;
      }
      return value;
    }
    return value;
  }, [cartData.progressBar, cartData.total, maxValue]);

  const barColor = useMemo(
    () => (haveMinSum ? Colors.green : Colors.red),
    [haveMinSum],
  );

  useEffect(() => {
    setTimeout(() => setBarLoading(false), 1000);
  }, []);

  return {
    onlyOneItem: numSteps === 1,
    progress,
    isBarLoading,
    barColor,
    data: cartData.progressBar,
  };
}
