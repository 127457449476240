import React from 'react';
import ContentLoader from 'react-content-loader';
import {WrapperStyle, ElementStyle} from './styles'
import {useMobileContextDetect} from 'shared/DeviceDetect'


const Element = ({isNewsPartner}) => {
    const isMobile = useMobileContextDetect();
    return (
        <ContentLoader
            backgroundColor="#d9d9d9"
            animate
            speed={0.8}
            height={280}
            width={'100%'}
        >
            <rect x="0" y="3" rx="16" ry="16" width={'100%'} height="190" />
            <rect x="0" y="200" rx="10" ry="10" width={'45%'} height="20" />
            <rect x="0" y="225" rx="10" ry="10" width={'65%'} height="15" />
            <rect x="0" y="247" rx="10" ry="10" width={'80%'} height="20" />
        </ContentLoader>
    );
};

const PartnersSkeletonLoader = ({isNewsPartner}) => {
    const isMobile = useMobileContextDetect();

    return (
        <WrapperStyle data-cy={'partners-skeleton-loader'}>
            {
                Array.from({length: isMobile ? 4 : isNewsPartner ? 5 : 8}).map((val, index) => (
                    <ElementStyle key={index} isNewsPartner={isNewsPartner} isMobile={isMobile}>
                        <Element isNewsPartner={isNewsPartner} key={index} />
                    </ElementStyle>

                ))
            }
        </WrapperStyle>
    );
}


export default PartnersSkeletonLoader
