import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  padding-horizontal: 16px;
  padding-top: 40px;
`;

export const ItemContainer = styled.div`
margin-bottom: 16px;
display: block;
`;

export const Label = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: black;
`;

export const Line = styled.div`
  height: 1px;
  background-color: #eaeaea;
  margin-top: 16px;
`;

export const SubLabel = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: gray;
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const SubLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  padding-top: 12px;
`;

export const SubMenuNumber = styled.div`
  color: gray;
  font-size: 14px;
  padding-left: 6px;
`;
