import React, {  useEffect } from 'react';
import CartItem from '../CartItem';
import { CartItemIN } from '../../interfaces/CartItemIN';
import Cart from 'modules/Cart';
import Partner from 'modules/Partner';
import { useSlidePanel } from 'shared/SlidePanel';
import {WrapperStyle} from "./styles";

type Props = {
    cartItems: CartItemIN[],
    onRemoveCartItem: (id: number) => void,
    onOpenProduct: (product: any, isProductForPoint: any, cartItemId: any) => void
}

const CartListComponent = (props: Props) => {
    const {cart, checkIfGiftAddToCart} = Cart.hooks.useCart()
    const {CartSlide} = Cart.components
    const {productSync} = Partner.hooks.useMenu()
    const [actions, setActions] = useSlidePanel()

    //const { cartItems, onRemoveCartItem, onOpenProduct } = props;
    const cartItems = cart.data.cartItems
    const data = cartItems
        ? cartItems
            .filter((item) => item.type !== 2)
            .map((item) => {
                return { ...item, key: item.id };
            })
        : [];

    const handleOpenProduct = (isProductForPoint, product, cartItemId, isGiftItems) => {
        //console.log('isProductForPoint, product, cartItemId', isProductForPoint, product, cartItemId)

        productSync(product, isProductForPoint, cartItemId, isGiftItems)
        actions.onOpen()
    }

    useEffect(() => {
        checkIfGiftAddToCart(cart.data.cartItems, cart.data.gift);
    }, [cart.data])


    return (
        <WrapperStyle>
            {
                data.map(item => (
                    <CartItem
                        key={item.id}
                        cartItemId={item.id}
                        quantity={item.quantity}
                        product={item.product}
                        price={item.price}
                        onOpenProduct={handleOpenProduct}
                        cartProductOptions={item.modifiers}
                        isGiftItems={item.type === 3}
                        isProductForPoint={item.type === 1}
                        data-cy={'cart-item'}
                        data-cy-id={item.product.id}
                    />
                ))
            }

            {
                Boolean(cart.data?.boxCost) && (
                    <CartItem
                        product={{name: 'Упаковка'}}
                        price={cart.data.boxCost}
                        onOpenProduct={() => {}}
                        data-cy={'cart-item'}
                    />
                )
            }


            <CartSlide
                setActions={setActions}
                form={'cart2'}
                page={'cart'}
                onDismiss={actions.onClose}
                slideClose={actions.onClose}
            />
        </WrapperStyle>
    );
};

export default CartListComponent;
