import React from 'react';
import MenuProductsList from '../MenuProductsList';
import ProductsList from '../ProductsList';
import { Partner } from 'modules/Partner/interfaces/Partner';
import { Menu } from '../../interfaces/Menu';
import { Product } from 'modules/Partner/interfaces/Product';
import Typography from 'shared/Typography';
import Button from 'shared/Button';

import {TitleWrap, Wrap} from './styles';

type Props = {
    menu: Menu,
    onOpenProduct: (product: Product, isProductForPoint: boolean) => void,
    isProductForPoint: boolean,
    zoneId: number
    partner: Partner,
    navigation: any,
    onProductsForPointsInfoPress: () => void
}

const MenuGroupComponent = (props: Props) => {
    const {
        menu,
        onProductsForPointsInfoPress,
        partner,
        ...otherProps
    } = props;



    return (
        <Wrap data-cy={'menu-list-group'}>
            <TitleWrap>
                <Typography variant='h2'>
                    {menu.name}
                </Typography >
                {menu.name === 'Еда за баллы' && (
                    <Button
                        component="button"
                        icon="InfoCircleIcon"
                        size="normal"
                        variant="transparent"
                        onClick={onProductsForPointsInfoPress}
                    />
                )}
            </TitleWrap>
            {!!menu.subMenu?.length && <MenuProductsList
                products={menu.subMenu}
                partner={partner}
                data-cy={'menu-product-list'}
                {...otherProps}
            />}
            {!!menu.products?.length && <ProductsList
                products={menu.products}
                partner={partner}
                {...otherProps}
            />}
        </Wrap>
    )
}

export default React.memo(MenuGroupComponent);
