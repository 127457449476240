import styled, {css} from 'styled-components';
import { Fonts, Colors } from 'common/Theme';
import Helpers from 'common/Theme/Helpers';

export const CustomListStyle = styled.div`
  ${Helpers.fullWidth};
  ${Helpers.css.flex};
  ${Helpers.css.flex_vertical};
  //isPadding
  ${props => props.isPadding && css`
    padding: 16px;
    `}
  //end isPadding
  //isBorder
  ${props => props.isBorder && css`
    border-top: 1px solid rgba(0, 0, 0, 0.04);
    `}
  //end isBorder

  //margin-bottom
  ${props => props.mb && css`
      margin-bottom: ${Helpers.css.mb[props.mb]};
  `}
  //end margin-bottom
`;

export const HeadStyle = styled.div`
  margin-bottom: 13px;
`;

export const RowStyle = styled.div`
  margin-bottom: 13px;
  ${Helpers.css.flex};
  ${Helpers.css.flex_sb};
  ${Helpers.fullWidth};
  
  &:last-child{
    margin-bottom: unset;
  }
`;

export const RowLabelStyle = styled.div`
  ${Helpers.fullWidth};
  ${Helpers.css.flex};
  ${Helpers.css.flex_ab};
  padding-right: 10px;
  //white-space: nowrap;
  ${props => props.isItemBorder && css`
      &:after{
        content: '';
        height: 1px;
        margin-left: 4px;
        margin-right: 4px;
        border-bottom: 1px dashed rgba(0, 0, 0, 0.12);
        ${Helpers.css.flex_grow};
      }
  `}
  ${props => props.halfWidth && css`
      width: 50%;
  `}
  
  ${props => props.variant === 'secondary' && css`
     border-bottom: 1px solid rgba(0, 0, 0, 0.12);
     padding-bottom: 10px;
      &:after{
        display: none;
      }
      &:last-children {
      border: 0;
      }
      
  `}
`;

export const RowValueStyle = styled.div`
  //white-space: nowrap;
  padding-left: 10px;
  ${props => props.halfWidth && css`
      width: 50%;
  `}
`;
