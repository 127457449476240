import React from 'react';
import {WrapperStyle} from "./styles";
import CategoryItem from "shared/Category/components/СategorytItem";
import {combineLink, links} from "helper";

const CategoryList = ({
                       data,
                       variant = 'primary',
                       size = 'normal',
                          onClick,
                          getActive = () => {},
                       mb,
                        ...rest
                  }) => {




    return (
        <>

            <WrapperStyle
                data-cy={'category-list'}
                mb={mb}
                {...rest}
            >

                {data?.map((item, i) => (
                    <CategoryItem
                        key={i}
                        size={size}
                        variant={variant}
                        //onClick={() => onClick(item)}
                        onClick={onClick}
                        name={item.name}
                        icon={item.icon}
                        isActive={getActive(item)}
                        //href={'/'+combineLink(item.alias) + '#partner-head'}>
                        href={'/' + combineLink(item.alias)}>
                    </CategoryItem>
                ))}

            </WrapperStyle>
        </>
    );
};

export default React.memo(CategoryList);
