import React from 'react';
import ContentLoader from 'react-content-loader';
import {WrapStyle, ElementStyle} from "./styles";
import {useMobileContextDetect} from "shared/DeviceDetect";

const Element = () => {
    const isMobile = useMobileContextDetect();

    return (
        <ElementStyle isMobile={isMobile}>
            <ContentLoader
                data-cy={'product-primary-skeleton-loader'}
                backgroundColor="#d9d9d9"
                animate
                speed={0.8}
                //viewBox={`0 0 ${(width * 0.46) } 296`}
                height={296}
                width={'100%'}
            >
                <rect x="0" y="3" rx="16" ry="16" width={'100%'} height="284" />
            </ContentLoader>
        </ElementStyle>
    );
};
const ProductPrimarySkeletonLoader = () => {
    const isMobile = useMobileContextDetect();

    return (
        <WrapStyle >
            {
                Array.from({length: isMobile ? 4 : 8}).map((val, index) => (
                    <Element key={index} />
                ))
            }
        </WrapStyle>
    );
}

export default ProductPrimarySkeletonLoader
