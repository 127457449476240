import React from 'react';
import ContentLoader from 'react-content-loader';
import {WrapFlexStyle, WrapStyle, ElementStyle} from "./styles";
import {useMobileContextDetect} from "shared/DeviceDetect";

const Element = () => {

    return (
        <ElementStyle>
            <ContentLoader
                data-cy={'promotion-secondary-skeleton-loader'}
                backgroundColor="#d9d9d9"
                animate
                speed={0.8}
                width={'100%'}
                height={'100%'}
            >
                <rect x="0" y="0" rx="16" ry="16" width={'100%'} height={'100%'} />
            </ContentLoader>
        </ElementStyle>
    );
};

const PromotionSecondarySkeletonLoader = () => (
    <>
        <WrapStyle>
            <WrapFlexStyle>
                {
                    Array.from({length: 3}).map((val, index) => (
                        <Element key={index} />
                    ))
                }

            </WrapFlexStyle>
        </WrapStyle>

    </>
);

export default PromotionSecondarySkeletonLoader
