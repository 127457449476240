import styled from 'styled-components';
import { Helpers } from 'common/Theme';
import { Colors, Fonts } from 'common/Theme';

export const Container = styled.div`
  ${Helpers.rowCross};
  padding: 16px;
`;

export const InputStyle = styled.input`
  height: 40px;
  background: #ffffff;
  ${Helpers.scrollSpaceBetween};
  color: ${Colors.inputText};
  flex-shrink: 1;
`;

export const Right = styled.div`
  margin-left: auto;
  ${Helpers.rowCross}
`;

export const BtnClear = styled.div`
  margin-right: 10px;
  margin-left: 10px;
`;

export const BtnCancel = styled.span``;
