import {useCallback} from "react";




const useCarusel = (container, k = 200) => {

    const onLeftClick = useCallback(() =>  container.scrollLeft -= k, [container])
    const onRightClick = useCallback(() =>  container.scrollLeft += k, [container])

    const checkVisibleScroll = useCallback(
        () => {
            let children = container?.childNodes;
            let totalWidth = 0;

            if(!children) {
                return  false
            }

            for (let i = 0; i < children.length; i++) {
                totalWidth += children[i].offsetWidth;
            }

            return container.offsetWidth < totalWidth

        }, [container])
    return {
        onLeftClick,
        onRightClick,
        checkVisibleScroll
    }
}

export default useCarusel