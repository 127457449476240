import {useState, useEffect, useCallback} from "react";
import debounce from 'lodash/debounce';
import {DEFAULT_MAKHACHKALA_COORDS, ZOOM} from 'modules/Map/constans'
import {useAppDispatch} from 'store'
import {
    findСity,
    getAddress,
    getAddressFromGeoData,
    getAndroidСompatible,
    getCoords,
    getGeoObject,
    getMapData,
    getPartnerName,
    requestGeocoding,
} from 'modules/Map/helpers';
import Map from "modules/Map";
import {useSelector} from "react-redux";
import {links} from "helper";
import { useRouter } from 'next/router'
import {useDirection, usePartner, usePartnerNew} from 'modules/Partner/hooks'
import { useCity } from 'modules/Map/hooks'
import { RootState } from 'store'
import {useBanner, usePromotion} from "modules/Promotion/hooks";
import {useAuth} from "modules/User/hooks";
import {useMiniSiteDetect} from "common/context/MiniSiteDetect";
import { parseDomain, ParseResultType } from "parse-domain";
import navRoute from "helper/navRoute";


type AddressObj = {
    cityId: number | string,
    house: number | string,
    street: string,
};

type MapData = Partial<{
    addressObj: AddressObj,
    latitude: number,
    longitude: number,
    address: string
}>;

const useMap = (mapRef, doRequestMyCoordinates = false) => {
    const [coords, setCoords] = useState(DEFAULT_MAKHACHKALA_COORDS)
    const [centerArr, setCenterArr] = useState([coords.latitude, coords.longitude])
    const [currentCoords, setCurrentCoords] = useState<any>()
    const [mapData, setMapData] = useState<MapData>({});
    const [isAddressOpen, setAddressOpen] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [address, setAddress] = useState({
        country: "",
        city: '',
        street: '',
        house: '',
    });
    const [geoObject, setGeoObject] = useState([]);
    const map = useSelector((state: RootState) => state.map);
    const hasDelivery = useSelector((state: RootState) => state.map?.hasDelivery);
    const addressLabel = useSelector((state: RootState) => state.map?.data.address);

    const dispatch = useAppDispatch();
    const router = useRouter()
    const {partnersSync} = usePartner()
    const {partnersNewSync} = usePartnerNew()
    const {onFetchDerections} = useDirection()
    const {onSpecialPromoGifts} = usePromotion()
    const {onBannerSync} = useBanner()
    const {isAuth} = useAuth()

    const {cities} = useCity()
    const isMiniSite = useMiniSiteDetect()
    const query: any = router.query;
    const navigation = navRoute()


    const onFetchMainData = useCallback(() => {
        partnersNewSync()
        onFetchDerections()
        onSpecialPromoGifts()
        onBannerSync()
        partnersSync()
    }, [])

    const getPos = useCallback(debounce( async (e) => {
        const centerArr = e.get('target').getCenter()
        const [ latitude, longitude, latitudeDelta, longitudeDelta ] = centerArr
        setCoords({ latitude, longitude, latitudeDelta, longitudeDelta });
        const data: any = await handleRequestGeocoding(`${longitude},${latitude}`);
        setGeoObject(data);
    }, 500), []);


    const setHasDelivery = useCallback((data) => {
        dispatch(Map.store.map.actions.setHasDelivery(data))
    }, [])

    const addMapInfo = useCallback((data) => {
        dispatch(Map.store.map.actions.addMapInfo(data))

        let {latitude, longitude} = data;

        if(latitude && longitude){
            let mapData = {...data}
            delete mapData.cities;

            const parseResult = parseDomain(location.hostname);

            if(parseResult.labels.length === 3) {
                parseResult.labels.shift()
            }
            const domain = parseResult.labels.join('.')


            //Cookies.set('mapData', JSON.stringify(mapData), { domain })
        }
    }, [])



    useEffect(() => {
        const {latitude, longitude} = coords
        setCenterArr([latitude, longitude])
    }, [coords])


    useEffect(() => {
        const findCity = findСity(cities, mapData?.addressObj?.city);

        const cityId = findCity?.id || null;
        cities.length && mapData.latitude && setHasDelivery(!!cityId);
    }, [mapData])

    useEffect(() => {
        if(doRequestMyCoordinates){
            let options = {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0
            };

            function success(pos) {
                let crd = pos.coords;
                let {latitude, longitude}: any = crd
                setCurrentCoords({latitude, longitude})
            };

            function error(err) {
                console.warn(`ERROR(${err.code}): ${err.message}`);
            };

            navigator.geolocation.getCurrentPosition(success, error, options);
        }

    }, [doRequestMyCoordinates])

    useEffect(() => {
        if(currentCoords){

            setCurrentMapLocation()
        }
    }, [currentCoords, mapRef])


    const setCurrentMapLocation = useCallback(() => {
        if (mapRef && currentCoords && !router.query.addressId) {
            mapRef.setCenter([currentCoords.latitude, currentCoords.longitude], ZOOM);
        }
    },[mapRef, currentCoords, router.query.addressId])

    const setMapLocation = useCallback((latitude, longitude) => {
        if (mapRef) {
            setTimeout(() => {
                mapRef.setCenter([latitude, longitude], ZOOM);

            }, 100)
        }
    },[mapRef])

    const onUpZoom = useCallback(() => {
        if (mapRef) {
            let zoom = mapRef.getZoom() + 1;
            mapRef.setZoom(zoom, { duration: 300 });
        }
    },[mapRef])

    const onDownZoom = useCallback(() => {
        if (mapRef) {
            let zoom = mapRef.getZoom() ?  mapRef.getZoom() - 1 : 0;
            mapRef.setZoom(zoom, { duration: 300 });
        }
    },[mapRef])

    const handleRequestGeocoding = async (geocode: string) => {

        try {
            const data = await requestGeocoding(geocode);
            const address = getAddress(data, cities);
            setAddress(address);

            const GeoObject = getGeoObject(data);


            const androidCompatibleResult = GeoObject ? getAndroidСompatible(GeoObject) : undefined;

            if (GeoObject) {
                const mapData = getMapData(GeoObject, address, cities);
                const coords = geocode.split(',');
                const latitude = Number(coords[1]);
                const longitude = Number(coords[0]);
                setMapData({ ...mapData, latitude, longitude });

                await partnersSync();
            }
            return androidCompatibleResult;
        } catch (e) {
            console.warn('Yandex geocoding api error: ' + e);
        }
    };

    const onSelectAddress = async (geocode: string, isCityOpen, return_url?: string) => {
        const data = await requestGeocoding(geocode);
        const [longitude, latitude] = getCoords(data);
        const address = getAddressFromGeoData(data);
        const { street, house, city } = getAddress(data, cities);

        if ((street && house && city) || isCityOpen) {
            const findCity = findСity(cities, city);


            const cityId = findCity?.id || null;
            cities.length && setHasDelivery(!!cityId);


            if (cityId) {
                if(isCityOpen){
                    await addMapInfo({
                        address,
                        addressObj: {
                            city,
                            cityId,
                        },
                    });
                } else {
                    await addMapInfo({
                        latitude: Number(latitude),
                        longitude: Number(longitude),
                        address,
                        addressObj: {
                            city,
                            street,
                            house,
                            cityId,
                        },
                    });
                }

                if(!isAuth || isCityOpen) {
                    if(isMiniSite){
                            window.location.href = (return_url || query.return_url) || links.abs.home
                    }else{
                        if(query.return_url || return_url) {
                            window.location.href = return_url || query.return_url
                        } else {
                            window.location.href = links.abs.partners
                        }
                    }

                    //window.location.href = links.abs.home
                    /*await router.push({
                        pathname: links.abs.partners,
                    })

                    onFetchMainData()*/
                }
            }
            setAddressOpen(false);
            setLoading(false);
        } else {
            /*setInitialInputValue(geocode);
            flashMessageRef?.current?.showMessage({
                message: 'Пожалуйста введите точный адрес',
                type: 'danger',
            });*/
        }
    };



    const onSelectAddressPress = useCallback(async () => {
        await addMapInfo(mapData);

        /*await router.push({
            pathname: links.abs.partners,
        })*/
        //fetchPartnerPageData()


        /*if(query.return_url) {
            window.location.href = query.return_url
        } else {
            window.location.href = links.abs.partners
        }*/


    }, [mapData]);



    const handleSelectAddress = () => {
        onSelectAddressPress()
    }


    return {
        coords,
        getPos,
        centerArr,
        setCurrentMapLocation,
        handleSelectAddress,
        mapData,
        onSelectAddress,
        addressLabel,
        map,
        hasDelivery,
        cities,
        onUpZoom,
        onDownZoom,
        addMapInfo,
        onFetchMainData,
        setMapLocation
    }
}

export default useMap
