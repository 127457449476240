import styled from "styled-components";

export const FormView = styled.div`
  padding-vertical: 5%;
  padding-horizontal: 3%;
`;

export const PromoView = styled.div`
  padding-vertical: 5%;
`;

export const PromoLabel = styled.span`
  font-size: 12px;
  text-align: left;
  color: #B7B7B7;
  padding-vertical: 2%;
`;