import styled, {css} from 'styled-components';
import { Fonts, Colors } from 'common/Theme';
import Helpers from 'common/Theme/Helpers';

export const HeaderStyle = styled.div`
  ${Helpers.fullWidth};
  ${Helpers.css.flex};
  ${Helpers.css.flex_ac};
  ${Helpers.css.flex_sb};
  position: relative;
  height: ${props => props.size === 'normal' ? '40px' : props.size === 'big' ? '80px' : '40px'};
  @media (min-width: 768px) {
    height: ${props => props.size === 'normal' ? '80px' : props.size === 'big' ? '120px' : '80px'};
  }
  padding: 0 5px;
  //margin-bottom
  ${props => props.mb && css`
      margin-bottom: ${Helpers.css.mb[props.mb]};
  `}
  ${props => props.borderBottom && css`
      border-bottom: 5px solid ${Colors.bg};
  `}
  //end margin-bottom
  background:${props => props.variant === 'primary' ? Colors.white : props.variant === 'secondary' ? Colors.bg : Colors.white} 
`;

export const HeaderRightStyle = styled.div`
`;

export const HeaderLeftStyle = styled.div`
  ${Helpers.css.flex};
  ${Helpers.css.flex_ac};

`;

export const HeaderCenterStyle = styled.div`
  ${Helpers.css.flex};
  ${Helpers.css.flex_vertical};
  ${Helpers.css.flex_center};
`;

export const BtnStyle = styled.div`
  margin-left: 15px;
  position: relative;
  &:last-child{
    margin-right: unset;
  }
`;
