import {useCallback} from "react";
import {useSelector} from "react-redux";
import { RootState } from "store";
import {useAppDispatch} from "store";
import Promotion from "modules/Promotion";



const useBanner = () => {
    const banner = useSelector((state: RootState) => state.banner);
    const bannerContainer = useSelector((state: RootState) => state.bannerContainer);
    const dispatch = useAppDispatch();

    const onBannerSync = useCallback(async () => {
        dispatch(Promotion.store.banner.actions.bannersSync())
    }, [])


    const onBannerContainerSync = useCallback(async (id) => {

        dispatch(Promotion.store.bannerContainer.actions.bannerContainerSync("alias-" + id))
    }, [])


    return {
        banner,
        bannerContainer,
        onBannerSync,
        onBannerContainerSync
    }
}

export default useBanner