import styled from 'styled-components';
import Helpers from 'common/Theme/Helpers';

export const Container = styled.div`
   width: 100%;
   margin-bottom: 35px;
`;

export const TitleWrap = styled.div`
`;

export const OptionContainer = styled.div`
  @media (min-width: 768px) {
    width: 100%;
    gap:0 15px;
  }
`;
