import React from 'react';
import * as S from './styles';
import {useProductPrice} from "modules/Partner/hooks/useProductPrice";
import {useCurrecny} from "modules/Partner/hooks/useCurrency";
import Typography from "shared/Typography";

const PriceNewOld = ({product, isProductForPoint}) => {

    const point = useCurrecny('product', product)

    const options = {
        currencyUnit: point.sign
    }

    const { priceText, oldPriceText } = useProductPrice(product, isProductForPoint, options);


    return (
        <S.Container>
            {oldPriceText ? (
                <Typography>
                    {priceText}
                    <S.OldPrice>{oldPriceText}</S.OldPrice>
                </Typography>
            ) : (
                priceText
            )}
        </S.Container>
    );
};

export default React.memo(PriceNewOld);
