import styled, {css} from 'styled-components';
import {Fonts, Colors} from 'common/Theme';
import Helpers from 'common/Theme/Helpers';

export const ContentStyle = styled.div`
  background: ${Colors.white};
  padding: 16px;
  ${Helpers.css.flex};
  ${Helpers.css.flex_grow};
  ${Helpers.css.flex_vertical};
  height: calc(100vh - 100px);

  @media (min-width: 768px) {
    height: auto;
  }
`;

export const AuthHeadStyle = styled.div`
  width: 100%;
  ${Helpers.fillRow}
  ${Helpers.fillCenter}
`;

export const InputRowStyle = styled.div`
   width: 100%;
   height: 80px;
`;

export const RowStyle = styled.div`
   width: 100%;
   ${Helpers.css.flex};
   ${Helpers.css.flex_ac};
   ${Helpers.css.flex_sb};
`
export const ErrorStyle = styled.span`
   font-style: normal;
   font-weight: normal;
   font-size: 12px;
   line-height: 12px;
   color: #FF3B30;
   margin-bottom: 25px;
`;

export const IconWrapStyle = styled.div`
`

export const BottomLinkStyle = styled.div`

   margin-top: auto;
   padding-bottom: 20px;
  padding-top: 30px;
`

export const RepeatCntWithoutPressStyle = styled.div`
`

export const RepeatCntStyle = styled.div`
    cursor: pointer;
  text-align: center;
`
