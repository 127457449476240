import  React from 'react';
import  useMenu from '../../hooks/useMenu';
import ShopProductList from "shared/ShopProduct/components/ShopProductList";


const ShopCategoriesList = ({partner}) => {
    const { menu } = useMenu()



    return (
        <>
            <ShopProductList
                data-cy={'shop-categories-list'}
                partner={partner}
                data={menu?.data?.main}
            />
        </>

    )

}


export default ShopCategoriesList