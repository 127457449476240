import * as t from './types';
import { Dispatch } from 'redux';
import { promotionService } from "modules/Promotion/services";

export const specialPromoGiftsSync = (config) => async (dispatch: Dispatch) => {

    dispatch({ type: t.FETCH_SPECIAL_PROMO_GIFT_START });

    try {
        const data  = await promotionService.fetchSpecialPromoGifts(config);
        let payload = data;
        return dispatch({ type: t.FETCH_SPECIAL_PROMO_GIFT_SUCCESS, payload });
    } catch (error) {
        //console.log(error)
        dispatch({ type: t.FETCH_SPECIAL_PROMO_GIFT_FAILURE, payload: error?.response });
        return  error?.response
    }
};



