import moment from "moment";

/**
 * returns the near working day 
 * @example
 * workingDay('Пн Вт Ср Чт');
 * // returns Сегодня
 * @param {string} value - working days 
 * @return {string} - the day when restaurant is open 
 */
export const getWorkingDayRelativelyToday = (workDays: string, workTime: any) => {

  const workDaysLowerCase = workDays.toLocaleLowerCase();
  const dayInWeek = 7;
  const [startWorkingDay = '', endWorkingDay = ''] = workTime;
  const [startWorkingDayHours, startWorkingMinutes] = startWorkingDay.split(':');
  const [endWorkingDayHours, endWorkingMinutes] = endWorkingDay.split(':')
  const isBeforeStartWorking = moment().isBefore(moment({ hour: startWorkingDayHours, minutes: startWorkingMinutes }));
  const isBeforeEndWorking = moment().isBefore(moment({ hour: endWorkingDayHours, minutes: endWorkingMinutes }))


  for (let day = 0; day < dayInWeek; day++) {

    const newData = moment().add(day, 'days');
    const newDayOfWeek = moment(newData).format('dd');

    if (workDaysLowerCase.includes(newDayOfWeek)) {
      if (isBeforeEndWorking || isBeforeStartWorking)
        return newData.calendar().split(',')[0];
      else if (!isBeforeEndWorking)
        return moment().add(1, 'days').calendar().split(',')[0];
    }

  }
}