import styled, {css} from 'styled-components';
import { Fonts, Colors } from 'common/Theme';
import Helpers from 'common/Theme/Helpers';

export const WrapperStyle = styled.div`
  ${Helpers.css.flex};
  ${Helpers.css.flex_ac};
  ${Helpers.css.flex_grow};
  overflow: auto;
  padding: 0 16px;
  
  @media (min-width: 768px) {
    margin: 0;
  }
  

  //margin-bottom
  ${props => props.mb && css`
      margin-bottom: ${Helpers.css.mb[props.mb]};
  `}
  //end margin-bottom
  
  ${props => props.variant === 'wrap' && css`
      ${Helpers.css.flex_wrap};
  `}
`;

export const ContentStyle = styled.div`
  ${Helpers.css.flex};
`;