import React from 'react';
import Image from 'shared/Image';

const BASE_URL = process.env.NEXT_PUBLIC_IMAGE_URL;
const BASE_URL_DEV = 'https://555880.ru';

const ImageUrl = ({src, isDev, isWithoutBaseUrl, isHasPlaceholder = false, ...rest}) => {

    const baseUrl = isWithoutBaseUrl ? "" : isDev ? BASE_URL_DEV : BASE_URL;

    return (
        //<Image src={src ? baseUrl + src : defaultImg.src}
        <Image src={ baseUrl + src }
               layout='fill'
               objectFit='cover'
               isHasPlaceholder={isHasPlaceholder}
               {...rest}
               data-cy={'image-url'}
        />
    )


}

export default ImageUrl;
