import React, {createContext, useContext} from 'react';


const DeviceDetect = ({children, device = 'mobile' }) => {
    const isMobile = useMobileContextDetect();

    if(device === 'mobile' && isMobile) {
        return  children
    }

    if(device === 'desktop' && !isMobile) {
        return  children
    }

    return <></>
};

export const checkMobile = () => {
    //const router = navRoute();

    //console.log('routerrouterrouter', Router)

    if(typeof window !== "undefined" && window.isMobile){
        return true
    }

    if(typeof window === "undefined" && global.isMobile){
        return true
    }

    //return ((typeof window !== "undefined") && window?.isMobileDetect)
}

export const createGlobalVarDetectMobile = (isMobile = true) => {

    if (typeof window === 'undefined'){
        global.isMobile = isMobile
        global.isDesktop = !isMobile
    } else {
        window.isMobile = isMobile
        window.isDesktop = !isMobile
    }
}

export const DetectMobileContext = createContext(false);

export const useMobileContextDetect = () => {
    const isMobile = useContext(DetectMobileContext);

    return isMobile
}



export default DeviceDetect;
