import React from 'react';
import {HeaderStyle, HeaderRightStyle, HeaderLeftStyle, HeaderCenterStyle, BtnStyle} from "./styles";
import Typography from "shared/Typography";
import Button from "shared/Button";
import {useMobileContextDetect} from "shared/DeviceDetect";
import ContentMediaView from "shared/ContentMediaView";
import Card from "shared/Card";
import Link from "shared/Link";

const HeaderPartner = ({
    head,
    headColor,
    desc,
    items,
    variant = "primary",
    headVariant = "h3",
    backCkick,
    backLabel = "Назад",
    mb,
    size,
    borderBottom,
    isLoading,
    backHref,
    ...rest
}) => {
    const isMobile = useMobileContextDetect();


    return (
        <Card isPadding={false}  variant={variant}>
            <ContentMediaView data-cy={'header-partner'}>
                <HeaderStyle
                    data-cy={'header-partner'}
                    mb={mb}
                    variant={variant}
                    size={size}
                    borderBottom={borderBottom}
                    {...rest}
                >

                    <HeaderRightStyle>
                        {
                            Boolean(backCkick) && (
                                <Button
                                    onClick={backCkick}
                                    variant='transparent'
                                    size='normal'
                                    data-cy={'back-button'}
                                    icon='ArrowLeftIcon'>

                                    {
                                        !isMobile ? backLabel : null
                                    }
                                </Button>
                            )
                        }

                        {
                            Boolean(backHref) && (
                                <Link href={backHref} passHref scroll={false}>
                                    <Button
                                        as={'a'}
                                        variant='transparent'
                                        size='normal'
                                        data-cy={'back-button'}
                                        icon='ArrowLeftIcon'>

                                        {
                                            !isMobile ? backLabel : null
                                        }
                                    </Button>
                                </Link>

                            )
                        }


                    </HeaderRightStyle>
                    {
                        (head || desc) && (
                            <HeaderCenterStyle>
                                {
                                    head
                                    && <Typography
                                        variant={isMobile ? headVariant : 'h1'}
                                        color={headColor} align='center' mb={desc && '0.5'}>{head}</Typography>
                                }
                                {
                                    desc
                                    && <Typography variant="textSmall" color='gray' align='center'>{desc}</Typography>
                                }
                            </HeaderCenterStyle>
                        )
                    }

                    <HeaderLeftStyle>
                        {
                            items
                            &&
                            items.map((item, i) => (
                                <BtnStyle key={i}>
                                    <Button
                                        data-cy={`header-button-${item.icon}`}
                                        onClick={item.onClick}
                                        variant={
                                            item.variant
                                                ? item.variant
                                                : "transparent"
                                        }
                                        isShadow={item.isShadow}
                                        size="normal"
                                        id={item.id}
                                        ref={item.ref}
                                        icon={item.icon}
                                    >
                                        {item.label || ''}
                                    </Button>
                                </BtnStyle>
                            ))
                        }
                    </HeaderLeftStyle>

                </HeaderStyle>
            </ContentMediaView>
        </Card>
    );
};

export default HeaderPartner;
