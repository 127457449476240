import React from 'react';
import AddressElementComponent from '../AddressElementComponent';

type ICity = {
    id?: number,
    address?: string,
    region?: string
}

type Props = {
  addresses?: ICity[],
  selectAddress: (address: string) => void,
  lengthBold: number,
};

type Address = {
    id: number,
};

const AddressListComponent = (props: Props) => {
  const { addresses, selectAddress, lengthBold } = props;
  return (
    <div data-cy={'address-list-component'}
    >
      {addresses &&
        addresses.map((item:Address) => (
          <AddressElementComponent
            lengthBold={lengthBold}
            selectAddress={selectAddress}
            key={item.id}
            suggestedAddress={item}
          />
        ))}
    </div>
  );
};

export default AddressListComponent;
