import React from 'react';
import ContentLoader from 'react-content-loader';
import {WrapStyle} from "./styles";

const Element = () => {

    return (
        <ContentLoader
            backgroundColor="#d9d9d9"
            animate
            speed={0.8}
            //viewBox={`0 0 ${width} 145`}
            height={145}
            width={'30%'}
            data-cy={'partner-header-skeleton-loader'}
        >
            <rect x="0" y="3" rx="10" ry="10" width={'70%'} height="20" />
            <rect x="0" y="30" rx="10" ry="10" width={'40%'} height="14" />
            <rect x="0" y="55" rx="16" ry="16" width={'60%'} height="32" />
            <rect x="0" y="95" rx="16" ry="16" width={'50%'} height="32" />
        </ContentLoader>
    );
};
const PartnerHeaderSkeletonLoader = () => (
    <>
        <WrapStyle>
            {
              Array.from({length: 1}).map((val, index) => (
                  <Element key={index} />
              ))
            }
        </WrapStyle>
    </>
);

export default PartnerHeaderSkeletonLoader
