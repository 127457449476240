import { Partner } from 'modules/Partner/interfaces/Partner';
import { Product } from 'modules/Partner/interfaces/Product';
import React from 'react';
import ProductsItem from 'modules/Partner/components/ProductsItem';
import ContentMediaView from 'shared/ContentMediaView';
import { WrapperStyle } from "./styles";
import {useMiniSiteDetect} from "common/context/MiniSiteDetect";

type Props = {
    products: Product[],
    onOpenProduct: (product: Product, isProductForPoint: boolean) => void,
    isProductForPoint: boolean,
    zoneId: number,
    navigation: any
    partner: Partner
}

const ProductsList = ({ products, partner,variant='primary', ...otherProps }: Props) => {
    const isMiniSite = useMiniSiteDetect()

    return (
        <ContentMediaView >
            <WrapperStyle variant='primary' data-cy={'products-list'}>
                {products.map(item =>
                    <ProductsItem
                        imageWithoutBaseUrl={isMiniSite}
                        variant={variant}
                        key={item.id}
                        product={item}
                        partner={partner}
                        {...otherProps} />
                )}
            </WrapperStyle>
        </ContentMediaView>
    )
}



export default React.memo(ProductsList)
