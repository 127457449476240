import { createSelector } from 'reselect';

const personalSeletor = (state) => state.profile.data;
export const profileSelector = createSelector(
    personalSeletor,
    (profile) => profile
);

export const orderByIdSelector = (orderId: number) =>
    createSelector(
        personalSeletor,
        ({ order }) =>
            !!order.data && Object.values(order.data).find((order: any) => order.id === +orderId)
    );

export const ordersWithoutReviewSelector = createSelector(
    profileSelector,
    (profile) => profile.ordersWithoutReview
);

export const bonusesSelector = createSelector(
    profileSelector,
    (profile) => profile.bonuses
);

export const profileIdSelector = createSelector(
    profileSelector,
    (profile) => profile.id
);

export const loadingSelector = createSelector(
    profileSelector,
    (profile) => profile.isLoading
);

export const faqSelector = createSelector(
    personalSeletor,
    ({ faqReducer }) => faqReducer || {}
);

export const userPointsSelector = createSelector(
    profileSelector,
    ({ bonuses }) => bonuses.rub
);
