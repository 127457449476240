import React  from 'react';
import MenuGroup from 'modules/Partner/components/MenuGroup';

const MenuProductsList = ({ products, ...otherProps }: any) => {

  return (
      <>
          {products.map(item => <MenuGroup key={item.id.toString()} menu={item} {...otherProps} />,)}
      </>
  )
};
export default React.memo(MenuProductsList);
