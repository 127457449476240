import React from 'react';
import { connect, } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import ImageUrl from 'shared/ImageUrl';
import Counter from 'shared/Counter';
import Typography from 'shared/Typography';
import {Options} from 'modules/Partner/components';
import ProductOpenSkeleton from 'modules/Partner/components/ProductFullContent/ProductOpenSkeleton';
import {useCart} from 'modules/Cart/hooks';
import * as S from './styles';
import {
    WrapStyle,
    BtnLeftStyle,
    BtnRightStyle,
    BtnWrapStyle,
    BtnCenterStyle,
    ImgWrapStyle,
    TitleWrapStyle,
    TitleLeftStyle,
    TitleRightStyle,
    ContentStyle,
    OptionWrapStyle,
    PriceWrapStyle,
    BlockStyle
} from './styles';
import {useMenu} from "modules/Partner/hooks";
import  SlidePanel from 'shared/SlidePanel';
import  Button from 'shared/Button';
import Loader from 'shared/Loader';
import {useMobileContextDetect} from "shared/DeviceDetect";
import PriceNewOld from "modules/Partner/components/PriceNewOld";
import {useMiniSiteDetect} from "common/context/MiniSiteDetect";

let AddCartForm = ({
    modifiers,
    page,
    handleSubmit,
    product,
    slideClose,
    initialValues,
    formProps,
    cartItemId,
    setActions,
    dispatch,
    ...rest
}) => {
    const quantity = formProps && formProps.values && formProps.values.quantity;
    const { onSubmitCartForm, onRemoveCartItem, isLoading, isHasCart, cart } =
        useCart(product, product.partner || {}, null, page);
    const {productOpen, getProductPrice} = useMenu()
    const priceProduct = getProductPrice(productOpen)
    const isMobile = useMobileContextDetect();
    const isMiniSite = useMiniSiteDetect()
    const onSubmit = async (val) => {
        await onSubmitCartForm(val, slideClose)
    }

    const handleClickButton = () => {
        onSubmit(formProps.values)
    }

    const handleRemove = () => {
        //console.log('handleRemove')
        onRemoveCartItem(cartItemId, () => slideClose())
    }





    const isProductForPoint = initialValues.type === 1;
    const isGiftItem = initialValues.isGiftItem;


        /*useEffect(() => {
            if(isOpen && isHasCart){

                setTimeout(() => {
                    const footer = document.querySelector("[data-rsbs-footer]")
                    console.log('footer', footer)
                    if(footer){
                        footer.style.top = '-90px'
                    }
                },50)
            }
        }, [isOpen])*/

    // @ts-ignore
    const renderFooter = () => (
        <BtnCenterStyle>

            {
                !isGiftItem && (
                    <BtnWrapStyle>
                        {
                            !isProductForPoint &&
                            <BtnLeftStyle>
                                <Field
                                    name={`quantity`}
                                    component={Counter}
                                    //type={'number'}
                                    //value={initialValues.quantity}
                                    disabled={isLoading}
                                />
                            </BtnLeftStyle>
                        }

                        <BtnRightStyle>
                            {
                                isProductForPoint && page == 'cart' ?
                                    <Button disabled={isLoading} variant={'danger'} onClick={handleRemove} component={'div'} fullSize>Удалить</Button> :
                                    quantity > 0 || page !== 'cart' ?
                                        <Button fullSize disabled={quantity === 0 || isLoading} {...(isMobile ? { onClick: handleClickButton} : {})} >
                                            {page !== 'cart' ? isLoading ? <Loader color={'#ffffff'}/> : 'Добавить' : 'Сохранить'}
                                        </Button> :
                                        <Button fullSize disabled={isLoading} variant={'danger'} onClick={handleRemove} type='submit' component={'div'}>Удалить</Button>
                            }
                        </BtnRightStyle>


                    </BtnWrapStyle>
                )
            }



            <Button
                disabled={isLoading}
                onClick={slideClose}
                size="normal"
                fullSize
                variant="transparent"
                isBorder
                component={'div'}
            >
                Отменить
            </Button>

        </BtnCenterStyle>
    )






    return (
        <div data-cy={'add-cart-form'}>

            <SlidePanel
                closeOnDocumentClick={false}
                onDismiss={slideClose}
                initSheet={setActions}
                footer={renderFooter()}
            >


                <form onSubmit={handleSubmit(onSubmit)}>
                    {/*{
                        renderFooter()
                    }*/}




                    <WrapStyle isHasCart={isHasCart}>
                        {
                            !productOpen?.id ? <ProductOpenSkeleton/> : (<>
                                {
                                    Boolean(productOpen.image) && (
                                        <ImgWrapStyle>
                                            <ImageUrl
                                                isWithoutBaseUrl={isMiniSite}
                                                src={productOpen.image}/>
                                        </ImgWrapStyle>
                                    )
                                }
                                <BlockStyle>
                                    <ContentStyle>
                                        <TitleWrapStyle>
                                            <TitleLeftStyle>
                                                <Typography variant="h3">{productOpen.name}</Typography>
                                            </TitleLeftStyle>
                                            <TitleRightStyle>
                                                <Typography variant="small">
                                                    <PriceWrapStyle>
                                                        <PriceNewOld product={productOpen} isProductForPoint={productOpen.isProductForPoint} pointUnit={{ pointUnit: 'Б' }}/>
                                                    </PriceWrapStyle>
                                                </Typography>
                                            </TitleRightStyle>
                                        </TitleWrapStyle>
                                        <Typography variant="textSmall" color='gray' mb={2}>{productOpen.description}</Typography>
                                    </ContentStyle>
                                    <OptionWrapStyle>
                                        {page === 'cart' ?
                                            initialValues.modifiers?.map((item, i) =>
                                                <S.OptionWrap key={i}>
                                                    <div>{item.name} +{item.price} руб.</div>
                                                </S.OptionWrap>
                                            ) :
                                            <Field
                                                name={`modifiers`}
                                                component={Options}
                                                options={modifiers}
                                            />
                                        }
                                    </OptionWrapStyle>

                                    {
                                        !isMobile && (
                                            renderFooter()
                                        )
                                    }
                                </BlockStyle>
                            </>)
                        }
                    </WrapStyle>
                </form>
        </SlidePanel>
        </div>
    )

 }


AddCartForm = reduxForm({
    //form: 'cart',
    enableReinitialize: true,
    destroyOnUnmount: true,
    //forceUnregisterOnUnmount: false,

})(AddCartForm);

AddCartForm = connect((state, props) => {
    //console.log('props', props)

    const productId = state.productOpen.id;
    const productType = state.productOpen?.isProductForPoint ? 1 : 0;
    const isGiftItem = state.productOpen?.isGiftItems;

    const similarProduct = state.productOpen.cartItemId ?
        state.cart.data.cartItems?.find(item =>
            item.id === state.productOpen.cartItemId) :
        state.cart.data.cartItems?.find(item =>
            item.productId === productId && item.type === productType) || null

    const quantity = similarProduct ? similarProduct.quantity : 2;

    const productModifiers = state.productOpen.modifiers[0]?.modifiers.filter(item =>
        similarProduct?.modifiers.includes(item.id)
    )

    let initialValues = {
        productId: productId,
        type: productType,
        isGiftItem,
        zoneId: state.partner.data.zoneId,
        quantity: props.page === 'cart' ? quantity : 1,
    }
    if (props.page === 'cart') initialValues = { ...initialValues, modifiers: productModifiers }

    //console.log('initialValues', initialValues)

    return ({
        initialValues,
        formProps: state.form[props.form],
        cartItemId: similarProduct && similarProduct.id,
        modifiers: state.productOpen.modifiers,
        product: state.productOpen,
    })
}

)(AddCartForm)

export default AddCartForm;
