import styled, {css} from 'styled-components';
import { Fonts, Colors } from 'common/Theme';
import Helpers from 'common/Theme/Helpers';

export const WrapStyle = styled.div`
  ${Helpers.css.flex};  
  ${Helpers.css.flex_ac};  
  margin-bottom: 40px;

  @media (max-width: 768px) {
    margin-bottom: 16px;
  }

  ${props => props.mb && css`
    margin-bottom: ${Helpers.css.mb[props.mb]};
  `} //end margin-bottom
  
`;

export const ElementStyle = styled.div`
  ${Helpers.css.flex_no_shrink};  
`;