import {useCallback, useEffect, useState} from 'react'
import {useSelector} from "react-redux";
import { RootState } from "store";
import { useAppDispatch } from "store";
import IMask from "imask";
import { useRouter } from 'next/router'
import {links} from "helper";
import User from "modules/User";
import navRoute from "helper/navRoute";
import combineLink from "../../../../helper/combineLink";
import Cart from "modules/Cart";
import Alert from "shared/Alert";


const phoneMask = IMask.createMask({
    mask: '+{7} (000)000-00-00',
});


const useAuth = () => {
    const auth = useSelector((state: RootState) => state.auth);
    const isAuth: boolean = Boolean(useSelector((state: RootState) => state.auth?.data?.token));
    const dispatch = useAppDispatch();
    const router = useRouter()
    const query: any = router.query;
    const navigation = navRoute()

    const [phone, setPhone] = useState<string>(query.phone || '');
    const [error, setError] = useState("");
    const [isCheck, setChecking] = useState(false);
    const [code, setCode] = useState("");
    const [seconds, setSeconds] = useState(60);
    const currentPartnerId = 1
    const isLoading = auth?.isLoading

    useEffect(() => {
        setPhone(query.phone || '');
    }, [query.phone]);


    const getCode = useCallback(async () => {
        setChecking(true);

        console.log('phone', phone)
        if (phone.length === 16) {
            setError("");

            return dispatch(User.store.auth.actions.requestCode(phone)).then((data) => {
                console.log('datadata', data)

                    if (data && data.code !== 403 && data.code !== 400 ) {
                        router.push({
                            pathname: links.abs.authCode,
                            query: { phone, ...query },
                        });
                    } else {
                        setError(
                            "Что-то пошло не так. Пожалуйста, попробуйте еще раз.",
                        );
                    }
                },
            );
        } else {
            phone.length == 0 ? setError('Введите номер телефона') :
                setError('В номере телефона не может быть меньше 11 цифр')
        }
    }, [phone, error]);

    const onChangeText = (phone) => {
        phone = phone.replace(/[^\d]/g, '');
        if (phone.length === 1 && ( phone[0] === '8' )) phone = "7"
        setPhone(phoneMask.resolve(phone));
        if (isCheck) {
            phone.length === 11 ? setError('') :
                phone.length == 0 ? setError('Введите номер телефона') :
                    setError('В номере телефона не может быть меньше 11 цифр')
        }
    };



    /*useEffect(() => {
        const timer =
            seconds > 0 && setInterval(() => setSeconds(seconds - 1), 1000);
        return () => clearInterval(timer);
    }, [seconds]);*/



    const onAuth = () => {
        return dispatch(User.store.auth.actions.requestAuth(phone, code)).then(async (data) => {
            auth.error && setError(data.message ? data.message : 'Что-то пошло не так. Пожалуйста, попробуйте еще раз.');
            if (data?.token) {
                dispatch(Cart.store.cart.actions.sync())
                const place = query.place;
                const returnUrl = query.return_url;

                if(returnUrl){
                    navigation.navigate(returnUrl);
                }

                if(!returnUrl){
                    switch (place) {
                        case 'cart':
                            navigation.navigate(links.abs.order);
                            break;
                        case 'partner':
                            navigation.navigate(combineLink(links.abs.partners, query.partner_id));
                            break;
                        default: navigation.navigate(links.abs.partners);
                    }
                }
            }
            /*else {
                setError('Неверный код. Повторите ещё раз');
            }*/
        });
    };

    const onLogOut = useCallback(() => {
        Alert.alert(
            'Вы уверены, что хотите выйти?',
            '',
            [
                {
                    text: 'Да',
                    onPress: async () => {
                        await dispatch(User.store.auth.actions.logOutAction());
                        navigation.navigate(links.abs.home);
                    }},
                { text: 'Отмена', onPress: () => { }, style: 'cancel' },
            ],
            { cancelable: false }
        );

    }, [auth]);


    return {
        phone,
        error,
        isCheck,
        getCode,
        onChangeText,
        onAuth,
        seconds,
        setCode,
        code,
        isAuth,
        isLoading,
        onLogOut
    }
}

export default useAuth
