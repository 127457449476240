import React from 'react';
import {WrapStyle} from "./styles";
import ButtonLeft from "shared/Carusel/components/ButtonLeft";
import ButtonRight from "shared/Carusel/components/ButtonRight";
import useCarusel from "shared/Carusel/hooks/useSlidePanel";
import {useMobileContextDetect} from "shared/DeviceDetect";

const CaruselWrap = ({children, wrapRef}) => {
    const actions = useCarusel(wrapRef?.current, 500)
    const isMobile = useMobileContextDetect();


    if(isMobile){
        return children
    }

    return (
        <WrapStyle data-cy={'carusel-wrap'}>
            {
                actions.checkVisibleScroll() && (
                    <>
                        <ButtonLeft onClick={actions.onLeftClick} />
                        <ButtonRight onClick={actions.onRightClick} />
                    </>
                )
            }
            {children}
        </WrapStyle>
    );
};

export default CaruselWrap;
