import React, {useEffect} from 'react';
import {useCity, useMap} from 'modules/Map/hooks';
import Input from "shared/Input";
import {CityContainerStyle, CityElementStyle} from "modules/Map/components/MapComponent/styles";
import Typography from "shared/Typography";
import SlidePanel from "shared/SlidePanel";


const CitiesListSlide = ({actions}) => {

    const { cities } = useCity();
    const { onSelectAddress } = useMap();
    const [ actionsCity, setActionsCity ] = actions;


    const onClick = (name) => {
        onSelectAddress(name, true)
        actionsCity.onClose()
    }


    useEffect(() => {
    }, [])

    return <SlidePanel
        data-cy={'cities-list-slide'}
        //open={citySlideAction.isOpen}
        initSheet={setActionsCity}
        onDismiss={actionsCity.onClose}
        header={<Input placeholder='Поиск'/>}
    >
        <CityContainerStyle  data-cy={'cities-list-slide'}>
            {
                cities?.map(item => (
                    <CityElementStyle key={item.id}>
                        <Typography
                            data-cy={'cities-item-slide'}
                            key={item.id}
                            color="black"
                            mb={2}
                            variant="textBig"
                            onClick={() => onClick(item.name)}
                        >
                            {
                                item.name
                            }
                        </Typography>
                    </CityElementStyle>
                ))
            }
        </CityContainerStyle>
    </SlidePanel>
};

export default CitiesListSlide;
