import styled, {css} from 'styled-components';
import { Fonts, Colors } from 'common/Theme';
import Helpers from 'common/Theme/Helpers';

export const WrapperStyle = styled.div`
  ${Helpers.fullWidth};
  //margin-bottom
  ${props => props.mb && css`
    margin-bottom: ${Helpers.css.mb[props.mb]};
  `}
  //end margin-bottom
`;

export const TopStyle = styled.div`
  ${Helpers.css.flex};
  ${Helpers.css.flex_at};
  ${Helpers.css.flex_sb};
  margin-bottom: 10px;
`;

export const TopLeftStyle = styled.div`
  
`;

export const FormWrapStyle = styled.div`
  ${Helpers.fullWidth};
`;

export const FormRowStyle = styled.div`
  ${Helpers.css.flex};
  ${Helpers.css.flex_ac};
  ${Helpers.fullWidth};
  margin-bottom: 10px;
`;

export const FormInputStyle = styled.div`
  margin-right: 15px;
  ${Helpers.fullWidth};

  &:last-child{
    margin-right: unset;
  }
`;
