import styled, {css} from 'styled-components';
import { Fonts, Colors } from 'common/Theme';
import Helpers from 'common/Theme/Helpers';

export const WrapStyle = styled.div`
  ${Helpers.fullWidth};
  ${Helpers.css.flex};
  ${Helpers.css.flex_ac};
  ${Helpers.css.flex_sb};

  //margin-bottom
  ${props => props.mb && css`
      margin-bottom: ${Helpers.css.mb[props.mb]};
  `}
  //end margin-bottom
`;

export const TitleStyle = styled.div`
margin-top: 10px;
`;
export const RightWrapStyle = styled.div`
  ${Helpers.css.flex};
  ${Helpers.css.flex_ab};
  ${Helpers.css.flex_sb};
`;
export const InputWrapStyle = styled.div`
  margin-right: 12px;
`;
export const IconWrapStyle = styled.div`
  
`;
