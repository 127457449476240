
const getFromPartner = (data) => {
  return data?.currency
}

const getFromPartners= (data) => {
  return data?.data.list[0]?.currency
}

const getFromCartItem = (data) => {
  return data?.currency
}

const getFromCart = (data) => {
  return data.partner?.currency
}

const getFromSearchList= (data) => {
  return data.data.list[0]?.currency
}

const getFromProduct= (data) => {
  return data?.currency
}

export const useCurrecny = (variant, data) => {

  if(variant === 'partner'){
    return getFromPartner(data)
  }

  if(variant === 'partners'){
    return getFromPartners(data)
  }

  if(variant === 'cart'){
    return getFromCart(data)
  }

  if(variant === 'cartItem'){
    return getFromCartItem(data)
  }

  if(variant === 'search'){
    return getFromSearchList(data)
  }

  if(variant === 'product'){
    return getFromProduct(data)
  }

};
