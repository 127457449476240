import React from 'react';
import {InputWrapStyle, InputStyle, LabelStyle, ErrorStyle} from "./styles";

const Input = ({
                   input,
                   meta = {},
                   label,
                   icon,
                   iconPosition,
                   placeholder,
                   variant,
                   size,
                   isBorder = true,
                   id,
                   mb,
                   forwardRef,
                   ...rest
                }) => {



    return (
        <>
            <InputWrapStyle
                variant={variant}
                mb={mb}
                {...rest}
                data-cy={'input'}
            >
                <InputStyle
                    ref={forwardRef}
                    name={id}
                    id={id}
                    // type={type}
                    isBorder={isBorder}
                    placeholder={placeholder}
                    {...input}
                    {...rest}
                />

                {
                    label &&
                    <LabelStyle
                        data-focus={meta.focus}
                        data-valuef={input.value}
                        data-value={Boolean(input.value)}
                        active={input.value}
                        for={id}>{label}
                    </LabelStyle>
                }

                {meta.error &&
                meta.touched &&
                <ErrorStyle>
                    {meta.error}
                </ErrorStyle>}
            </InputWrapStyle>
        </>
    );
};

export default Input;
