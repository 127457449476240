import { Colors } from "common/Theme";
import styled from 'styled-components';


export const Container = styled.div`
    border-bottom-color: ${Colors.gray};
    border-bottom-width: 1;
    padding: 9px 10px;
`;

export const Street = styled.div`
    margin-bottom: 4px;
`;

export const City = styled.div`
`;

export const SingleCity = styled.div`
    height: 40px;
    flex: 1;
    flex-direction: row;
    padding: 9px 10px;
`
export const RegulatSubTitle = styled.span`
    font-size: 16px;
    line-height: 19px;
`
export const BoldSubTitle = styled.span`
    font-weight: bold;
    font-size: 16px;
    font-weight: bold;
    line-height: 19px;
`