import { Product } from 'modules/Partner/interfaces/Product';
import React from 'react';
import ImageUrl from 'shared/ImageUrl';
import {WrapStyle, ButtonWrapStyle, ImageWrapStyle, TextWrapStyle, WrapMiniStyle, TextWrapMiniStyle, ImageWrapMiniStyle, ButtonMiniStyle} from "./style";
import Typography from "shared/Typography";
import Button from "shared/Button";
import Counter from "shared/Counter";
import PointPrefix from "shared/PointPrefix";
import Loader from "shared/Loader";
import {
    ButtonSecondWrapStyle,
    ImageSecondWrapStyle,
    TitleSecondWrapStyle,
    WrapSecondStyle
} from "shared/Product/components/ProductItem/styles";
import {useCurrecny} from "modules/Partner/hooks/useCurrency";

type Props = {
    product: Product
    onAddCartPressed: () => void,
    onReducePressed: () => void,
    isLoading: boolean,
    width: number,
    onOpenProduct: () => void,
    quantityInCart: number,
    priceProduct: string,
    isProductForPoint: boolean
}

const ProductsItemDefaultComponent = (props: Props) => {

    const { product, onAddCartPressed, onReducePressed, isLoading, width,
        onOpenProduct,
        quantityInCart,
        priceProduct,
        isProductForPoint,
        mb,
        variant = 'primary',
        isElementFound,
        imageWithoutBaseUrl,
        ...rest
    } = props;
    const { id, image, weight, name, description = '' } = product;
    const currency = useCurrecny('product', product);





    const primary = (
        <>
            <WrapStyle
                id={`item${id}`}
                mb={mb}
                {...rest}
                onClick={onOpenProduct}
                isElementFound={isElementFound}
            >
                <ImageWrapStyle><ImageUrl isWithoutBaseUrl={imageWithoutBaseUrl} isHasPlaceholder src={image}></ImageUrl></ImageWrapStyle>

                <TextWrapStyle>
                    <Typography variant="text" component="h4" maxLength={40}>{name}</Typography>
                    {
                        !!weight && (
                            <Typography variant="text" color="gray" align="center">
                                <PointPrefix prefix="г">{weight}</PointPrefix>
                            </Typography>
                        )
                    }

                    {!(quantityInCart === 0 || isProductForPoint) && (
                        <Typography variant="textBig" mb={0.5}>
                            {priceProduct}
                        </Typography>
                    )}

                </TextWrapStyle>
                <ButtonWrapStyle>
                    {
                        quantityInCart === 0 || isProductForPoint ? (
                            <Button size="extraBig" variant='secondary' fullSize onClick={onAddCartPressed}>
                                {
                                    isLoading ? <Loader /> : (
                                        <Typography variant="textBig" >
                                            {priceProduct}
                                        </Typography>
                                    )
                                }

                            </Button>
                        ) : (
                            <Counter
                                variant={'secondary'}
                                type={'secondary'}
                                value={isLoading ? <Loader /> : quantityInCart}
                                increaseFn={onReducePressed}
                                decreaseFn={onAddCartPressed} />
                        )
                    }
                </ButtonWrapStyle>
            </WrapStyle>
        </>
    )


    const secondary = (
        <>
            <WrapMiniStyle
                isElementFound={isElementFound}
                id={`item${id}`}
                {...rest}
            >
                <TextWrapMiniStyle>
                    <Typography variant="h4" component="h3" isItalic>{name}</Typography>
                    <Typography variant="textSmall" color="gray"><PointPrefix prefix="г">{weight}</PointPrefix></Typography>
                    <Typography variant="text"><PointPrefix currency={currency}>{priceProduct}</PointPrefix></Typography>
                </TextWrapMiniStyle>
                {
                    Boolean(image) && <ImageWrapMiniStyle><ImageUrl isWithoutBaseUrl={imageWithoutBaseUrl} src={image}></ImageUrl></ImageWrapMiniStyle>
                }

                <ButtonMiniStyle><Button size="small" variant='light' onClick="00" isShadow icon="CartIcon"></Button></ButtonMiniStyle>
            </WrapMiniStyle>
        </>
    )


    const tertiary = (
            <WrapSecondStyle
                mb={mb}
                {...rest}
                onClick={onOpenProduct}
                isElementFound={isElementFound}
                id={`item${id}`}
            >
                <ImageSecondWrapStyle><ImageUrl isWithoutBaseUrl={imageWithoutBaseUrl} src={image}></ImageUrl></ImageSecondWrapStyle>
                <TextWrapStyle>
                    <TitleSecondWrapStyle>
                        <Typography variant="textSmall" component="h3" isBold>{name}</Typography>
                    </TitleSecondWrapStyle>
                </TextWrapStyle>
                <ButtonSecondWrapStyle>
                    {
                        quantityInCart === 0 || isProductForPoint ? (
                            <Button size="normal" variant='secondary' fullSize onClick={onAddCartPressed}>
                                {
                                    isLoading ? <Loader /> : (
                                        <Typography variant="text" >
                                            {priceProduct}
                                        </Typography>
                                    )
                                }

                            </Button>
                        ) : (
                            <Counter
                                size={'small'}
                                variant={'secondary'}
                                type={'secondary'}
                                value={isLoading ? <Loader /> : quantityInCart}
                                increaseFn={onReducePressed}
                                decreaseFn={onAddCartPressed} />
                        )
                    }
                </ButtonSecondWrapStyle>
            </WrapSecondStyle>
    )

    return (
        <>
            { variant === 'primary' && primary }
            { variant === 'secondary' && secondary }
            { variant === 'tertiary' && tertiary }
        </>
    );


    /*return (
        <S.ProductView onClick={onOpenProduct} key={id} data-id={id} data-is-product-for-point={isProductForPoint}>
            {/!*{!!image &&
                <S.ImageView>
                    <ImageUrl
                        src={image}
                    />
                </S.ImageView>
            }*!/}
            {/!*<S.TitleView>
                <S.Title numberOfLines={2}>{name}</S.Title>
            </S.TitleView>*!/}
            {/!*{
                !image &&
                <S.DescriptionView>
                    <S.DescriptionText>{description}</S.DescriptionText>
                </S.DescriptionView>
            }*!/}
            {/!*{!!weight &&
                <S.CountView>
                    <S.CountText>{weight}</S.CountText>
                </S.CountView>
            }*!/}
            {
                quantityInCart === 0 || isProductForPoint ?
                    <S.Button hasInBasket={!!quantityInCart} onClick={onAddCartPressed}>
                        {
                            isLoading ? 'loader' :
                                <S.ButtonTitle hasInBasket={!!quantityInCart}>{priceProduct}</S.ButtonTitle>
                        }
                    </S.Button> :
                    <S.CountRow>
                        <S.ActionBtn onClick={onReducePressed}>
                            <button>-</button>
                            {/!*<Animated.View>
                                <Animated.Image
                                    style={{
                                        transform: [
                                            {
                                                scale: width,
                                            },
                                        ]
                                    }}
                                    source={Images.minus}
                                />
                            </Animated.View>*!/}

                        </S.ActionBtn>
                        {
                            isLoading ? 'Loader' : <S.CountTitle>{quantityInCart}</S.CountTitle>
                        }
                        <S.ActionBtn onClick={onAddCartPressed}>
                            <button>+</button>
                            {/!*<Animated.View>
                                <Animated.Image
                                    style={{
                                        transform: [
                                            {
                                                scale: width,
                                            },
                                        ]
                                    }}
                                    source={Images.plus}
                                />
                            </Animated.View>*!/}
                        </S.ActionBtn>
                    </S.CountRow>
            }

        </S.ProductView>
    )*/
}

export default React.memo(ProductsItemDefaultComponent)

/*
export default React.memo(ProductsItemDefaultComponent, (prevMovie, nextMovie) => {
    let check =
        (prevMovie.quantityInCart === nextMovie.quantityInCart) &&
        (prevMovie.isLoading === nextMovie.isLoading) &&
        (prevMovie.partner === nextMovie.partner)
    //let check = false

    return check
});*/
