import React from 'react';
import {InputWrapStyle, SelectStyle, OptionStyle, LabelStyle, ErrorStyle} from "./styles";

const InputSelect = ({
                   input,
                   meta = {},
                   label,
                   icon,
                   options,
                   iconPosition,
                   placeholder,
                   variant,
                   size,
                   id,
                   mb,
                   ...rest
                }) => {


    return (
        <>
            <InputWrapStyle
                variant={variant}
                mb={mb}
                {...rest}
                data-cy={'input-select'}
            >
                <SelectStyle
                    name={id}
                    id={id}
                    // type={type}
                    placeholder={placeholder}
                    {...input}
                    {...rest}
                >
                    {options.map((item, i) => (
                        <OptionStyle key={i} value={item.value}>{item.label}</OptionStyle>
                    ))}
                </SelectStyle>


                {
                    label
                    && <LabelStyle active={true} for={id}>{label}</LabelStyle>
                }

                {meta.error &&
                meta.touched &&
                <ErrorStyle>
                    {meta.error}
                </ErrorStyle>}
            </InputWrapStyle>
        </>
    );
};

export default InputSelect;
