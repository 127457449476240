import { Product } from "modules/Partner/interfaces/Product";
import { ProductPrice } from "./ProductPrice";

export type ProductPriceOptions = {
  pointUnit: string;
  currencyUnit: string;
};

const defaultOptions: ProductPriceOptions = {
  pointUnit: 'балл.',
  currencyUnit: '₽'
}

export const useProductPrice = (product: Product, isProductForPoint: boolean, o?: Partial<ProductPriceOptions>): ProductPrice => {
  const options = Object.assign({}, defaultOptions, o) as ProductPriceOptions;
  if (product) {
    const { pointUnit, currencyUnit } = options;
    const { price, pointsPrice } = product;
    const priceValue = isProductForPoint ? pointsPrice : price;
    const unitText = isProductForPoint ? pointUnit : currencyUnit === 'р' ? defaultOptions.currencyUnit : currencyUnit;
    const oldPriceValue = isProductForPoint ? null : product.oldPrice > 0 ? product.oldPrice : null;
    const oldPriceText = oldPriceValue != null ? `${oldPriceValue} ${currencyUnit}` : null;
    const priceText = `${priceValue} ${unitText}`;

    return {
      priceValue,
      oldPriceValue,
      unitText,
      priceText,
      oldPriceText
    };
  }
  return {
    priceValue: 0,
    oldPriceValue: 0,
    unitText: '',
    priceText: '',
    oldPriceText: ''
  };
};
