import React, {useCallback} from 'react';
import useProgressBar from "modules/Cart/components/CartProgressBar/hooks/useProgressBar";
import * as S from './styles';
import ProgressBar from "@ramonak/react-progress-bar";
import Colors from "common/Theme/Colors";



const CartProgressBar = ({}) => {
    const {
        onlyOneItem,
        progress,
        isBarLoading,
        data,
        barColor,
    } = useProgressBar();

    const renderText = useCallback(
        (d, index) => {
            const isLast = data.length === index + 1;
            const textColor = d.state === 'completed' ? barColor : 'gray';
            const textAlign = isLast ? 'right' : 'center';

            return (
                <S.LabelContainer>
                    <S.Label textColor={textColor} textAlign={textAlign}>
                        {d.name}
                    </S.Label>
                </S.LabelContainer>
            );
        },
        [barColor, data.length],
    );

    const renderValue = useCallback(
        (d, index) => {
            const fontSize = index * 2 + 14;
            const isLast = data.length === index + 1;
            const textColor = d.state === 'completed' ? barColor : 'gray';
            const textAlign = isLast ? 'right' : 'center';
            return (
                <S.LabelContainer>
                    <S.Label
                        textColor={textColor}
                        textAlign={textAlign}
                        fontSize={fontSize}>
                        от {d.value} ₽
                    </S.Label>
                </S.LabelContainer>
            );
        },
        [barColor, data.length],
    );

    const resolveSteps = useCallback((d, index) => {
        const multiplier = 31; //41
        const defaultLeftPadding = 24;
        const left = defaultLeftPadding + index * multiplier;
        const color = d.state === 'completed' ? 'white' : '#BDBDBD';
        return <S.Line left={left} color={color} />;
    }, []);


    if(!data?.length){
        return <></>
    }


    return (
        <S.Wrap>
            <S.Container>
                <S.PriceContainer onlyOneItem={onlyOneItem}>
                    {data.map(renderValue)}
                </S.PriceContainer>
            </S.Container>
            <S.WrapProgressBar>
                <ProgressBar
                    completed={progress * 100}
                    labelClassName="label"
                    bgColor={Colors.green}
                    baseBgColor={'#EFEFEF'}
                    customLabel={' '}
                    //borderRadius={'50px 0 0 50px'}
                    animateOnRender
                />
                {data.slice(0, -1).map(resolveSteps)}
            </S.WrapProgressBar>

            <S.Container>
                <S.TitleContainer onlyOneItem={onlyOneItem}>
                    {data.map(renderText)}
                </S.TitleContainer>
            </S.Container>
        </S.Wrap>
    );
}




export default CartProgressBar
