import styled, {css} from 'styled-components';



export const WrapStyle = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(50%, -50%);
  z-index: 4;
  
  button {
    transform: rotate(180deg);
  }
`;