import { getGeoObjectData } from './getGeoObjectData';

export const getAndroidСompatible = (GeoObject) => {
  const { lon, lat, title, subtitle, kind } = getGeoObjectData(GeoObject);

  return {
    displayName: [subtitle, title].filter(Boolean).join(', '),
    kind,
    title,
    subtitle,
    point: {
      latitude: lat,
      longitude: lon,
    },
  };
};
