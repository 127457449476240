import React from 'react';
import BottomSlideContent from "shared/BottomSlideContent";
import List from "./List";


const AddressListSlide = ({onSelectAddress}) => {



    return (
        <BottomSlideContent data-cy={'address-list-slide'}
            head='Мои адреса'
        >
            <List onSelectAddress={onSelectAddress}/>
    </BottomSlideContent>
    )
};

export default AddressListSlide;
