import React from 'react';
import {
    WrapperStyle,
    TopStyle,
    BottomStyle,
    IconWrapStyle,
    DateStyle,
    IconStyle,
    ContentStyle,
    TitleStyle,
    BonusStyle,
    OrderStyle,
} from "./styles";
import Typography from "shared/Typography";
import Icon from "shared/Icon";
import useDate from "shared/Date/hooks/useDate";

const BonusItem = ({
                       data,
                       mb,
                        ...rest
                  }) => {

    const {getTime} = useDate()
    const { date, sum, orderId, partnerName, description } = data;
    const pointDate = getTime(date);


    return (
        <>
            <WrapperStyle {...rest} mb={mb} data-cy={'bonus-item'}>
                <IconWrapStyle>
                    <IconStyle><Icon name="CapIcon" height="15px" width="14px"/></IconStyle>
                </IconWrapStyle>
                <ContentStyle>
                    <TopStyle>
                        <TitleStyle><Typography variant="textBig" isBold>{partnerName}</Typography></TitleStyle>
                        <DateStyle><Typography variant="textSmall" color="gray">{pointDate}</Typography></DateStyle>
                    </TopStyle>
                    <BottomStyle>
                        <OrderStyle>
                            <Typography variant="text" color="gray">
                                {
                                    orderId
                                        ? `Заказ №${orderId}`
                                        : description
                                }
                            </Typography>
                        </OrderStyle>
                        <BonusStyle>
                            <Typography variant="textBig">
                                {sum} {/*баллов*/}
                            </Typography>
                        </BonusStyle>
                    </BottomStyle>
                </ContentStyle>

            </WrapperStyle>
        </>
    );
};

export default BonusItem;
