import ImageUrl from 'shared/ImageUrl';
import { Product } from 'modules/Partner/interfaces/Product';
import React from 'react';
import Loader from "shared/Loader";

import * as S from "./style";

type Props = {
    product: Product
    onAddCartPressed: () => void,
    onReducePressed: () => void,
    isLoading: boolean,
    width: number,
    onOpenProduct: () => void,
    quantityInCart: number,
    priceProduct: string,
    isProductForPoint: boolean
}

const ProductsItemSmallComponent = (props: Props) => {

    const { product, onAddCartPressed, onReducePressed, isLoading, width,
        onOpenProduct, quantityInCart, priceProduct, isProductForPoint } = props;
    const { id, image, weight, name, description = '' } = product;




    return (
        <S.ProductView onClick={onOpenProduct} key={id} data-id={id} data-is-product-for-point={isProductForPoint} data-cy={'products-item'}>
            {!!image &&
                <S.ImageView>

                    <ImageUrl
                        src={image}
                    />
                </S.ImageView>
            }
            <S.TitleView>
                <S.Title numberOfLines={2}>{name}</S.Title>
            </S.TitleView>
            {
                !image &&
                <S.DescriptionView>
                    <S.DescriptionText>{description}</S.DescriptionText>
                </S.DescriptionView>
            }
            {!!weight &&
                <S.CountView>
                    <S.CountText>{weight}</S.CountText>
                </S.CountView>
            }
            {
                quantityInCart === 0 || isProductForPoint ?
                    <S.Button hasInBasket={!!quantityInCart} onClick={onAddCartPressed}>
                        {
                            isLoading ? <Loader /> :
                                <S.ButtonTitle hasInBasket={!!quantityInCart}>{priceProduct}</S.ButtonTitle>
                        }
                    </S.Button> :
                    <S.CountRow>
                        <S.ActionBtn onClick={onReducePressed}>
                            <button>-</button>
                            {/*<Animated.View>
                                <Animated.Image
                                    style={{
                                        transform: [
                                            {
                                                scale: width,
                                            },
                                        ]
                                    }}
                                    source={Images.minus}
                                />
                            </Animated.View>*/}

                        </S.ActionBtn>
                        {
                            isLoading ? <Loader/> : <S.CountTitle>{quantityInCart}</S.CountTitle>
                        }
                        <S.ActionBtn onClick={onAddCartPressed}>
                            <button>+</button>
                            {/*<Animated.View>
                                <Animated.Image
                                    style={{
                                        transform: [
                                            {
                                                scale: width,
                                            },
                                        ]
                                    }}
                                    source={Images.plus}
                                />
                            </Animated.View>*/}
                        </S.ActionBtn>
                    </S.CountRow>
            }

        </S.ProductView>
    )
}

export default React.memo(ProductsItemSmallComponent);

