import React, {useState, useEffect} from "react";
import {CounterStyle, BtnStyle, ValueStyle} from "./styles";

const Counter = ({
    type = "primary",
    variant = "primary",
    mb,
    value = 0,
    increaseFn,
    decreaseFn,
    input = {},
    size,
}) => {
    const [val, setValue] = useState(input?.value || value)
    const increase = () => {
        setValue(parseInt(val) + 1)
        input?.onChange(parseInt(val) + 1)
    }
    const decrease = () => {
        if(val > 0) {
            setValue(parseInt(val) - 1)
            input?.onChange(parseInt(val) - 1)
        }
    }

    useEffect(() => {
        setValue(value)
    }, [value])


    /*useEffect(() => {
        if(input?.onChange){
            input.onChange(val)
        }
    }, [val])*/

    useEffect(() => {
        if(input?.value){
            setValue(input.value)
        }
    }, [input])

    return (
        <>
            <input type="hidden" {...input}/>
            <CounterStyle data-cy={'counter'} type={type} mb={mb} variant={variant}>
                <BtnStyle size={size} variant={variant} type={type} onClick={increaseFn || decrease}>-</BtnStyle>
                <ValueStyle size={size}  variant={variant}>{val}</ValueStyle>
                <BtnStyle size={size} variant={variant} type={type} onClick={decreaseFn || increase}>+</BtnStyle>
            </CounterStyle>
        </>
    );
}

export default Counter;
