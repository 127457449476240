import styled from 'styled-components';
import { Colors } from 'common/Theme';

export const Container = styled.div`
  display: flex;
  flex: 1;
  background-color: white;
  position: relative;
`;


export const EmptyListContentContainer = styled.div`
  margin: 80px 30px;
`;

export const EmptyListText = styled.div`
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  color: ${Colors.gray};
  margin-bottom: 20px;
`;

export const EmptyListButton = styled.div`
  margin-top: 25px;
  display: flex;
  align-items: center;
`;
export const EmptyListButtonBG = styled.div`
  background: rgba(118, 118, 118, 0.1);
  border-radius: 10px;
  padding: 8px 19px;
  margin: auto;
`;
export const EmptyListButtonText = styled.div`
  text-align: center;
  color: ${Colors.gray};
`;
