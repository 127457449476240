import styled, {css} from 'styled-components';
import Helpers from 'common/Theme/Helpers';

export const WrapperStyle = styled.div`
  gap: 25px;
  overflow: auto;

  @media (min-width: 768px) {
    gap: 25px;
    ${Helpers.css.flex}
    ${Helpers.css.flex_wrap}
  }
`;

export const ElementStyle = styled.div`
  width: 100%;
  ${Helpers.css.flex_wrap}

  ${props => props.isNewsPartner && props.isNewsPartner && css`
      width:  calc(90%);
    `}

  @media (min-width: 768px) {
    width:  calc(25.4% - 25px);
    ${props => props.isNewsPartner && css`
      width:  calc(20% - 25px);
    `}
  }
`;