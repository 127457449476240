import request from "helper/request";
import combineLink from "helper/combineLink";
import api from "helper/api";

class BonusService {
    fetchBonus = async () => {
        let options = {
            setLoading: false,
            withLoader: false,
            //query: data
        }

        return request.get(combineLink(api.userBonus), options)
    };

}

const bonusService = new BonusService();
export default bonusService;
