import request from "helper/request";
import combineLink from "helper/combineLink";
import api from "helper/api";


class PromotionCodeService {
    fetchSpecialPromoGifts = async (config = {}) => {
        let options = {
            setLoading: false,
            withLoader: true,
            ...config
        }

        return request.get(combineLink(api.specialPromoGifts), options).then(data => data)
    };

    fetchPromoGifts = async (config = {}) => {
        let options = {
            setLoading: false,
            withLoader: true,
            ...config
        }

        return request.get(combineLink(api.promoGifts), options).then(data => data)
    };
}

const promoCodeService = new PromotionCodeService();
export default promoCodeService