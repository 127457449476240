import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {Field, formValueSelector, reduxForm} from 'redux-form';
import {WrapperStyle, TopStyle, TopLeftStyle, FormWrapStyle, FormRowStyle, FormInputStyle} from "./styles";
import Typography from "shared/Typography";
import Button from "shared/Button";
import Input from "shared/Input";
import {useAddress} from "modules/Map/hooks";



let FullAddressForm = (props) => {
    const {fullAddressFormValues, onAddressPressed, cities, onDonePressed, mb, ...rest} = props;
    const {onSubmitMyAddress, getCityName} = useAddress()
    const cityName = getCityName(fullAddressFormValues?.cityId);
    const {handleSubmit, destroy} = props;

    useEffect(() => {
        return () => {
            destroy()
        }
    }, [])


    return (
        <form data-cy={'full-address-form'} onSubmit={handleSubmit(onSubmitMyAddress)}>
            <WrapperStyle
                mb={mb}
                {...rest}
            >
                <TopStyle>
                    <TopLeftStyle>
                        <Typography variant="textBig" mb='0.5'>
                          {fullAddressFormValues?.street}{fullAddressFormValues?.house && `, ${fullAddressFormValues?.house}`}
                        </Typography>
                        <Typography variant="textSmall">{cityName}</Typography>
                    </TopLeftStyle>
                    <Button variant="transparent" size="small" icon="ChevronBlackIcon"></Button>
                </TopStyle>
                <FormWrapStyle>
                    <FormRowStyle>
                        <FormInputStyle>
                          <Field name="podezd" component={Input} type="text" label="Подъезд"/>
                        </FormInputStyle>
                        <FormInputStyle>
                            <Field name="domofon" component={Input} type="text" label="Домофон"/>
                        </FormInputStyle>
                    </FormRowStyle>
                    <FormRowStyle>
                        <FormInputStyle>
                            <Field name="number" component={Input} type="text" label="Квартира"/>
                        </FormInputStyle>
                        <FormInputStyle>
                            <Field name="storey" component={Input} type="text" label="Этаж"/>
                        </FormInputStyle>
                    </FormRowStyle>
                    {/*<FormRowStyle>
                        <FormInputStyle><TextArea meta='' label='Комментарий' id='5'></TextArea></FormInputStyle>
                    </FormRowStyle>*/}
                </FormWrapStyle>
            </WrapperStyle>
        </form>
    );
};

FullAddressForm = reduxForm({
    form: 'fullAddress',
    destroyOnUnmount: false,
    enableReinitialize: true
})(FullAddressForm);

const selector = formValueSelector('fullAddress');
const mapState = (state, props) => {
    const fullAddressFormValues = selector(state, 'street', 'house', 'cityId');
    const initialValues = props.initialAddress;
    const { latitude, longitude, addressObj: {cityId, house, street} } = state.map?.data;

    //console.log('initialValues', initialValues)

    //if (initialValues) {
        return {
            fullAddressFormValues,
            initialValues: {
                ...initialValues,
                ...(
                    initialValues?.coordinates ?
                        initialValues.coordinates :
                        {
                            coordinates: {latitude, longitude}
                        }
                ),
                ...(
                    !initialValues ?
                        {cityId, house, street} :
                        {}
                )
            }
        }
    //}
    /*return {
        fullAddressFormValues
    }*/

};

export default connect(mapState)(FullAddressForm);
