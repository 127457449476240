import React from 'react';
import {WrapStyle, TitleStyle, RightWrapStyle, InputWrapStyle} from "./styles";
import Typography from "shared/Typography";
import Input from "shared/Input";
import Button from "shared/Button";
import { reduxForm, Field } from "redux-form";
import { connect } from "react-redux";
import {usePromoCode} from "modules/Promotion/hooks";

let PromoCodeForm = ({ mb, query, handleSubmit, variant, ...rest }) => {
    const {onSubmit} = usePromoCode(variant, query)
    const handleKeyDown = e => {
        if (e.key === 'Enter') {
            handleSubmit(onSubmit)()
        }
    }

    return (
        <div data-cy={'promo-code-form'}>
            <WrapStyle mb={mb} {...rest}>
                <TitleStyle>
                    <Typography variant="h4">Промокод</Typography>
                </TitleStyle>
                <RightWrapStyle>
                    <InputWrapStyle>
                        <Field
                            name="code"
                            component={Input}
                            type="text"
                            label="Промокод"
                            onKeyDown={handleKeyDown}
                        />
                    </InputWrapStyle>
                <Button onClick={handleSubmit(onSubmit)} type='submit' size='small' icon='ChevronIcon'></Button>
                </RightWrapStyle>
            </WrapStyle>
        </div>
    );
};

PromoCodeForm = reduxForm({
    form: 'promoCode',
    enableReinitialize: true,
})(PromoCodeForm);

const mapState = (state, props) => {
    return {
        initialValues: {
            code: state.cart.data.promo ? state.cart.data.promo.name : '',
        },
    };
}

export default connect(mapState)(PromoCodeForm);
