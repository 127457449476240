import styled, {css} from 'styled-components';
import { Fonts, Colors } from 'common/Theme';
import Helpers from 'common/Theme/Helpers';

export const WrapFlexStyle = styled.div`
  ${Helpers.css.flex};  
  ${Helpers.css.flex_ac};
  overflow: auto;
  gap: 25px;

  @media (max-width: 768px) {
    gap: 13px;
    padding-left: 16px;
  }
`;

export const WrapStyle = styled.div`
  overflow: hidden;
  margin-bottom: 20px;
`;

export const ElementStyle = styled.div`
  width: 483px;
  height: 240px;
  ${Helpers.css.flex_no_shrink};

  @media (max-width: 768px) {
    width: 356px;
    height: 179px;
  }

`;
