import {memo} from 'react'
import Image from 'next/image'
import {ImgStyle} from './styles'
import placeholder from '/public/img/defaultImg.jpeg'
//import {useMobileContextDetect} from "shared/DeviceDetect";

/*const myLoader = ({ src, width, quality }) => {
    return `${src}?w=100&q=${quality || 15}`
}*/


const Img = (props) => {
    //const isMobile = useMobileContextDetect();

    //const Component = isMobile ? Image : ImgStyle
    const Component = Image

    if(process.env.NEXT_PUBLIC_API_URL !== 'https://www.edostav.ru/api/v3/'){
        return (
            <ImgStyle
                data-cy={'image'}
                {...(props.isHasPlaceholder ? {
                    style: {
                        background: `url(${ placeholder.src})`,
                        backgroundPosition: `center`,
                        backgroundRepeat: `no-repeat`,
                        backgroundColor: `#fff`,
                    }
                } : {})}
                {...props}
                src={props.src.src || props.src}
            />
        )

    }

    return (
        <Component
            quality={90}
            sizes="30vw"
            //placeholder="blur"
            //blurDataURL={placeholder.src}
            layout='fill'
            objectFit='cover'
            data-cy={'image'}
            {...(props.isHasPlaceholder ? {
                style: {
                    background: `url(${ placeholder.src})`,
                    backgroundPosition: `center`,
                    backgroundRepeat: `no-repeat`,
                    backgroundColor: `#fff`,
                }
            } : {})}
            {...props}
        />
    )

}

export default memo(Img)
