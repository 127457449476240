import styled, {css} from 'styled-components';
import { Fonts, Colors } from 'common/Theme';
import Helpers from 'common/Theme/Helpers';
import {WrapperStyle} from "shared/AlertText/styles";

export const PrefixStyle = styled.span`
  font-size: inherit;
  line-height: inherit;
  color: inherit;

  //margin-bottom
  ${props => props.mb && css`
      margin-bottom: ${Helpers.css.mb[props.mb]};
  `}
  //end margin-bottom
  white-space: nowrap;
  ${Helpers.css.flex_inline}
`;
